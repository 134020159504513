<template>
  <div class="w-full py-3 px-8 md:py-6">
    <section class="w-full max-w-7xl mx-auto">
      <div class="flex flex-col gap-4">
        <h1
          class="text-[24px] lg:text-[30px] text-[#18479E] font-bold text-left"
        >
          Career Advancement Services
        </h1>
        <p class="text-[14px] lg:text-[16px] text-left">
          The Career Advancement Services team works tirelessly to connect our
          students with the right job opportunities. Our years of experience in
          training, networking, and human resourcing skills help our students
          find exciting and challenging job opportunities. Our students get
          placements in reputed companies through a structured framework.
        </p>
        <!-- desktop -->

        <div class="hidden lg:block">
          <div class="flex gap-[80px]">
            <button
              v-for="career in careers"
              :key="career"
              @click="selectedcareer = career"
              :class="{
                ' border border-[#0CB1EF] text-blue-400 ':
                  selectedcareer === career,
                ' border bg-[#F6F8FB] shadow-2xl  ': selectedcareer !== career,
              }"
              class="text-center p-2 text-[12px] rounded-md  2xl:text-[18px] text-black font-semibold"
            >
              {{ career }}
            </button>
          </div>

          <div class="py-4" v-if="selectedcareer === 'Career Support'">
            <div class="flex justify-between">
              <div
                v-for="card in cards"
                :key="card"
                class="w-[230px] xl:w-[280px] h-[340px]"
              >
                <div
                  class="flex flex-col gap-2 bg-white shadow-2xl rounded-md h-full"
                >
                  <img
                    :src="card.image"
                    alt=""
                    srcset=""
                    class="w-[280px] h-[240px]"
                  />
                  <p class="p-3 text-[14px] text-left text-[#000000]">
                    {{ card.content }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="py-4" v-if="selectedcareer === 'Guidance for Further Education'">
            <div class="flex justify-between">
              <div v-for="card in cards1" :key="card" class="w-[230px] xl:w-[280px]">
                <div class="flex flex-col gap-2 bg-white shadow-2xl rounded-md h-full">
                  <img
                    :src="card.image"
                    alt=""
                    srcset=""
                    class="w-[280px] h-[240px]"
                  />
                  <p class="p-3 text-[14px] text-left text-[#000000]">
                    {{ card.content }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="py-4" v-if="selectedcareer === 'Master Classes'">
            <div class="flex justify-between">
              <div v-for="card in cards2" :key="card" class="w-[230px] xl:w-[280px]">
                <div class="flex flex-col gap-2 bg-white shadow-2xl rounded-md h-full">
                  <img
                    :src="card.image"
                    alt=""
                    srcset=""
                    class="w-[280px] h-[240px]"
                  />
                  <p class="p-3 text-[14px] text-left text-[#000000]">
                    {{ card.content }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="py-4" v-if="selectedcareer === 'Profile Building'">
            <div class="flex justify-between">
              <div v-for="card in cards3" :key="card" class="w-[230px] xl:w-[280px]">
                <div class="flex flex-col gap-2 bg-white shadow-2xl rounded-md h-full">
                  <img
                    :src="card.image"
                    alt=""
                    srcset=""
                    class="w-[280px] h-[240px]"
                  />
                  <p class="p-3 text-[14px] text-left text-[#000000]">
                    {{ card.content }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="py-4" v-if="selectedcareer === 'Placement Assistance'">
            <div class="flex justify-between">
              <div v-for="card in cards4" :key="card" class="w-[230px] xl:w-[280px]">
                <div class="flex flex-col gap-2 bg-white shadow-2xl rounded-md h-full">
                  <img
                    :src="card.image"
                    alt=""
                    srcset=""
                    class="w-[280px] h-[240px]"
                  />
                  <p class="p-3 text-[14px] text-left text-[#000000]">
                    {{ card.content }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- desktop -->
        <!-- mobile -->
        <div class="block lg:hidden">
          <div class="flex justify-between gap-4 overflow-x-scroll pb-3">
            <button
              v-for="career in careers"
              :key="career"
              @click="selectedcareer = career"
              :class="{
                ' border border-[#0CB1EF] text-blue-400':
                  selectedcareer === career,
                ' border bg-[#F6F8FB] shadow-2xl  ': selectedcareer !== career,
              }"
              class="w-full whitespace-nowrap px-3 text-center py-2 text-[12px] rounded-md w-[180px] text-black font-semibold"
            >
              {{ career }}
            </button>
          </div>
          <div class="py-2" v-if="selectedcareer === 'Career Support'">
            <div class="slick_career flex flex-col gap-2">
              <div v-for="card in cards" :key="card" class="w-full">
                <div
                  class="flex flex-col gap-2 border bg-white shadow-sm rounded-md"
                >
                  <img
                    :src="card.image"
                    alt=""
                    srcset=""
                    class="w-full h-[240px]"
                  />
                  <p
                    class="p-3 text-[14px] text-left text-[#000000] min-h-[100px]"
                  >
                    {{ card.content }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            class="py-2"
            v-if="selectedcareer === 'Guidance for Further Education'"
          >
            <div class="slick_career flex flex-col gap-2">
              <div v-for="card in cards1" :key="card">
                <div
                  class="flex flex-col gap-2 border bg-white shadow-sm rounded-md"
                >
                  <img
                    :src="card.image"
                    alt=""
                    srcset=""
                    class="w-full h-[240px]"
                  />
                  <p
                    class="p-3 text-[14px] text-left text-[#000000] min-h-[100px]"
                  >
                    {{ card.content }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="py-2" v-if="selectedcareer === 'Master Classes'">
            <div class="slick_career flex flex-col gap-2">
              <div v-for="card in cards2" :key="card">
                <div
                  class="flex flex-col gap-2 border bg-white shadow-sm rounded-md"
                >
                  <img
                    :src="card.image"
                    alt=""
                    srcset=""
                    class="w-full h-[240px]"
                  />
                  <p
                    class="p-3 text-[14px] text-left text-[#000000] min-h-[100px]"
                  >
                    {{ card.content }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="py-2" v-if="selectedcareer === 'Profile Building'">
            <div class="slick_career flex flex-col gap-2">
              <div v-for="card in cards3" :key="card">
                <div
                  class="flex flex-col gap-2 border bg-white shadow-sm rounded-md"
                >
                  <img
                    :src="card.image"
                    alt=""
                    srcset=""
                    class="w-full h-[240px]"
                  />
                  <p
                    class="p-3 text-[14px] text-left text-[#000000] min-h-[100px]"
                  >
                    {{ card.content }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="py-2" v-if="selectedcareer === 'Placement Assistance'">
            <div class="slick_career flex flex-col gap-2">
              <div v-for="card in cards4" :key="card">
                <div
                  class="flex flex-col gap-2 border bg-white shadow-sm rounded-md"
                >
                  <img
                    :src="card.image"
                    alt=""
                    srcset=""
                    class="w-full h-[240px]"
                  />
                  <p
                    class="p-3 text-[14px] text-left text-[#000000] min-h-[100px]"
                  >
                    {{ card.content }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import $ from "jquery";
import "slick-carousel";
export default {
  name: "CareerView",
  data() {
    return {
      careers: [
        "Career Support",
        "Guidance for Further Education",
        "Master Classes",
        "Profile Building",
        "Placement Assistance",
      ],
      selectedcareer: "Career Support",
      cards: [
        {
          id: 1,
          image: require("@/assets/electivepage/Career/Industry-Mentors.webp"),
          content:
            " Gain real-world insights from experienced mentors tailored to your goals.",
        },
        {
          id: 2,
          image: require("@/assets/electivepage/Career/Guidance-for-Further-Education.webp"),
          content:
            " Bridge academia and industry with accomplished professionals.",
        },
        {
          id: 3,
          image: require("@/assets/electivepage/Career/Master-Classes.webp"),
          content:
            " Build meaningful connections for personalized career support.",
        },
        {
          id: 4,
          image: require("@/assets/electivepage/Career/Placement-Assistance.webp"),
          content:
            " Propel your career forward with seasoned wisdom and guidance.",
        },
      ],
      cards1: [
        {
          id: 1,
          image: require("@/assets/electivepage/Career/Guidance-for-Further-Education.webp"),
          content:
            "Explore endless educational possibilities with our comprehensive guidance.",
        },
        {
          id: 2,
          image: require("@/assets/electivepage/Career/Industry-Mentors.webp"),
          content:
            " After Graduation, upskill with expert advisors steering you to the right path.",
        },
        {
          id: 3,
          image: require("@/assets/electivepage/Career/Master-Classes.webp"),
          content:
            "Receive personalized support in selecting programs and navigating applications.",
        },
        {
          id: 4,
          image: require("@/assets/electivepage/Career/Placement-Assistance.webp"),
          content:
            "Achieve your educational aspirations with our dedicated guidance for further education",
        },
      ],
      cards2: [
        {
          id: 1,
          image: require("@/assets/electivepage/Career/Placement-Assistance.webp"),
          content:
            " Embark on a transformative learning journey with our exclusive master classes led by industry experts.",
        },
        {
          id: 2,
          image: require("@/assets/electivepage/Career/Industry-Mentors.webp"),
          content: " Dive deep into specialized topics for skill refinement.",
        },
        {
          id: 3,
          image: require("@/assets/electivepage/Career/Guidance-for-Further-Education.webp"),
          content:
            " Gain a competitive edge with practical wisdom and insights.",
        },
        {
          id: 4,
          image: require("@/assets/electivepage/Career/Master-Classes.webp"),
          content:
            " Stay ahead in your pursuits with unparalleled expertise delivered to you.",
        },
      ],
      cards3: [
        {
          id: 1,
          image: require("@/assets/electivepage/Career/Master-Classes.webp"),
          content:
            " Learn effective self-presentation through our Profile Building resources.",
        },
        {
          id: 2,
          image: require("@/assets/electivepage/Career/Placement-Assistance.webp"),
          content:
            " Showcase your skills, achievements, and aspirations with our guidance.",
        },
        {
          id: 3,
          image: require("@/assets/electivepage/Career/Industry-Mentors.webp"),
          content:
            " Elevate your online presence and leave a lasting impression on recruiters.",
        },
        {
          id: 4,
          image: require("@/assets/electivepage/Career/Guidance-for-Further-Education.webp"),
          content:
            " Shape your narrative, stand out, and unlock new opportunities with our support.",
        },
      ],
      cards4: [
        {
          id: 1,
          image: require("@/assets/electivepage/Career/Guidance-for-Further-Education.webp"),
          content:
            " Navigate the professional landscape confidently with our Placement Assistance, empowering students with essential tools and support.",
        },
        {
          id: 2,
          image: require("@/assets/electivepage/Career/Placement-Assistance.webp"),
          content:
            " Our expert faculty ensures you're well-prepared for rewarding opportunities through resume building and interview preparation.",
        },
        {
          id: 3,
          image: require("@/assets/electivepage/Career/Industry-Mentors.webp"),
          content:
            " Secure the perfect job in your chosen field with our comprehensive support and guidance.",
        },
        {
          id: 4,
          image: require("@/assets/electivepage/Career/Master-Classes.webp"),
          content:
            " Elevate your career growth and educational journey with our commitment to fostering success.",
        },
      ],
    };
  },
  methods: {
    initializeCarousel() {
      $(".slick_career").slick({
        autoplay: true,
        prevArrow: false,
        nextArrow: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        dotsClass: "slick_learnings_dot",
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 6,
              slidesToScroll: 6,
            },
          },
          {
            breakpoint: 950,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 650,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 420,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
      this.carouselInitialized = true;
    },
    destroyCarousel() {
      if (this.carouselInitialized) {
        $(".slick_career").slick("unslick");
        this.carouselInitialized = false;
      }
    },
  },
  watch: {
    selectedcareer() {
      this.destroyCarousel();
      this.$nextTick(() => {
        this.initializeCarousel();
      });
    },
  },
  mounted() {
    this.initializeCarousel();
  },
};
</script>

<style>
.slick_learnings_dot {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 1rem 0;
  list-style-type: none;
}
.slick_learnings_dot li {
  margin: 0 0.25rem;
}
.slick_learnings_dot button {
  display: block;
  width: 0.75rem;
  height: 0.75rem;
  padding: 0;
  border: none;
  border-radius: 15px;
  background-color: silver;
  text-indent: -9999px;
}
.slick_learnings_dot li.slick-active button {
  background-color: #f8c300;
  width: 1.5rem;
  height: 0.5rem;
  border-radius: none;
}
</style>
