<template>
  <div>
    <div
      class="w-full bg-gradient-to-b from-[#174397] to-[#020f26] min-h-[350px] py-3 md:py-6"
    >
      <div class="max-w-7xl mx-auto w-full px-5 py-5">
        <div
          class="flex flex-col gap-2 md:gap-3 text-center justify-center text-white"
        >
          <h1
            class="lg:text-[34px] text-[24px] font-bold  text-left md:text-center"
          >
            Referral Process
          </h1>
        </div>
        <div
          class="flex flex-col md:flex-row md:justify-center md:items-center w-full gap-5 md:h-[250px] pt-5 md:pt-0"
        >
          <div
            class="w-full md:w-[240px] md:mx-auto flex items-center md:flex-col"
          >
            <div
              class="w-[92px] h-[92px] md:w-[130px] md:h-[130px] rounded-full mx-auto flex flex-col justify-center items-center py-3 bg-white"
            >
              <p class="uppercase">step</p>
              <h2 class="text-[#0CB1EF] md:text-5xl text-2xl font-bold">1</h2>
            </div>
            <div class="py-3 w-[190px] md:w-full text-white text-center">
              <h2 class="text-[14px] text-left md:text-center">
                Click here to visit the Sharda Online enrollment form page
              </h2>
            </div>
          </div>
          <div
            class="md:mx-auto w-full md:w-[240px] flex items-center md:flex-col"
          >
            <div
              class="w-[92px] h-[92px] md:w-[130px] md:h-[130px] rounded-full mx-auto flex flex-col justify-center items-center py-3 bg-white"
            >
              <p class="uppercase">step</p>
              <h2 class="text-[#0CB1EF] md:text-5xl text-2xl font-bold">2</h2>
            </div>
            <div class="py-3 w-[190px] md:w-full text-white text-center">
              <h2 class="text-[14px] text-left md:text-center">
                Register and follow the four necessary steps to enroll in your
                preferred program
              </h2>
            </div>
          </div>
          <div
            class="md:mx-auto w-full md:w-[240px] flex items-center md:flex-col"
          >
            <div
              class="w-[92px] h-[92px] md:w-[130px] md:h-[130px] rounded-full mx-auto flex flex-col justify-center items-center py-3 bg-white"
            >
              <p class="uppercase">step</p>
              <h2 class="text-[#0CB1EF] md:text-5xl text-2xl font-bold">3</h2>
            </div>
            <div class="py-3 w-[180px] md:w-full text-white text-center">
              <h2 class="text-[14px] text-left md:text-center">
                Submit your payment via Net banking/Debit card/Credit card or
                opt for a convenient 0% interest EMI option
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EnrollView",
};
</script>

<style></style>
