<template>
  <div class="w-full px-8 py-3 md:py-6">
    <section class="w-full max-w-7xl mx-auto">
      <div class="text-left flex flex-col gap-2">
        <h1 class="text-[24px] lg:text-[30px] text-[#18479e] font-bold">
          About {{ elective.sub_name }} {{ elective.name }}
        </h1>
        <p class="text-[14px] lg:text-[14px]" v-html="elective.description"></p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "AboutView",
  props: ["elective"],
};
</script>

<style></style>
