<template>
  <div class="w-full px-5 py-3 bg-[#18479e]">
    <section class="w-full max-w-7xl mx-auto">
      <h1
        class="text-[24px] lg:text-[30px] text-white font-bold text-center uppercase"
      >
        Program Highlights
      </h1>
      <div class="flex flex-col lg:flex-row justify-center items-center gap-2 lg:gap-[300px] pt-2 ">
        <div>
          <ul class="flex flex-col gap-3 md:gap-6 py-2 px-4 md:px-0">
            <li v-for="higlight in highlights" :key="higlight" class="md:list-disc text-[12px] md:text-[16px] text-white md:text-left font-bold">
              {{ higlight.content }}
            </li>
          </ul>
        </div>
        <div>
            <img src="@/assets/lp-program/3.webp" alt="" srcset="" class="h-[280px] lg:h-[450px]">
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "HighightsView",
  data() {
    return {
      highlights: [
        {
          id: 1,

          content:
            "Updated industry-relevant syllabus",
        },
        {
          id: 2,

          content:
            "Experienced & professional faculty",
        },
        {
          id: 3,

          content:
            "Flexible learning environment",
        },
        {
          id: 4,

          content:
            "Multiple electives to choose from",
        },
        {
          id: 5,

          content:
            "Live online sessions",
        },
         {
          id: 6,

          content:
            "Practical exposure",
        },
      ],
    };
  },
};
</script>

<style></style>
