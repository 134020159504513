<template>
  <div>
    <NavBar />

    <div class="mx-auto max-w-6xl h-full">
      <section class="w-full max-w-7xl mx-auto px-5 pt-[120px]">
        <!-- Academic Resources -->
        <div class="text-left flex flex-col gap-3">
          <h2 class="text-[24px] lg:text-[35px] text-[#18479E] font-bold px-3">
            Academic Resources
          </h2>
        </div>

        <div class="text-left flex flex-col gap-3 py-3 lg:py-6">
          <!-- <h3 class="text-[12px] lg:text-[15px] text-[#000000] font-bold px-3" >
            Compliance status of Regulations UGC (ODL and Online Programs) Regulations - Self-regulation through disclosures, declarations and reports
          </h3> -->
          <div class="flex flex-col gap-3 bg-[#F6F8FB] overflow-x-auto">
            <table class="border-separate border-spacing-3">
              <thead>
                <tr class="w-full text-[14px] lg:text-[18px] text-white">
                  <th class="bg-[#0CB1EF] px-5 py-2.5">S.No.</th>
                  <th class="bg-[#0CB1EF] px-5 py-2.5">Information</th>
                  <th class="bg-[#0CB1EF] px-5 py-2.5">Details</th>
                </tr>
              </thead>
              <tbody class="mt-2">
                <tr
                  class="w-full text-[12px] lg:text-[14px] text-black bg-white"
                >
                  <td class="text-left py-2 px-5">1.</td>
                  <td class="w-1/2 text-left py-2 px-5">
                    Important dates and events for the batch of July 2024
                  </td>
                  <td class="w-1/2 text-left py-2 px-5">
                    <a
                      href="https://api.shardaonline.ac.in/media/TENTATIVE-SCHEDULE_20035.pdf"
                      target="_blank"
                      class="text-[#18479E]"
                      >Click Here</a
                    >
                  </td>
                </tr>
                <tr
                  class="w-full text-[12px] lg:text-[14px] text-black bg-white"
                >
                  <td class="text-left py-2 px-5">2.</td>
                  <td class="w-1/2 text-left py-2 px-5">
                    Sample copy of e-Learning material
                  </td>
                  <td class="w-1/2 text-left py-2 px-5 text-[#18479E]">
                    <a
                      href="https://api.shardaonline.ac.in/media/Digital-Electronics-and-Computer-Organization-SLM_93770.pdf"
                      target="_blank"
                      >Click Here</a
                    >
                  </td>
                </tr>
                <tr
                  class="w-full text-[12px] lg:text-[14px] text-black bg-white"
                >
                  <td class="text-left py-2 px-5">3.</td>
                  <td class="w-1/2 text-left py-2 px-5">
                    Sample of Asynchronous Video
                  </td>
                  <td
                    class="w-1/2 text-left py-2 px-5 text-[#18479E] cursor-pointer"
                    @click="openmodal()"
                  >
                    Watch Now
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal" id="myModal">
          <div class="modal-content1">
            <div class="flex justify-center gap-2">
              <div class="flex justify-center items-center z-10 cursor-pointer">
                <font-awesome-icon
                  icon="fa-angle-left"
                  class="next-ranking h-[25px] md:h-[40px] text-white"
                />
              </div>
              <div class="slick-watch w-[270px] md:w-[420px]">
                <div v-for="(video, index) in videos" :key="index">
                  <div
                    class=""
                    v-if="video.showThumbnail"
                    @click="playVideo(index)"
                  >
                    <img
                      :src="video.image"
                      alt=""
                      srcset=""
                      class="w-full h-[220px] md:h-[300px]"
                    />
                  </div>
                  <iframe
                    v-else
                    @click="stopVideo(index)"
                    class="w-full md:w-[400px] h-[220px] md:h-[300px]"
                    allow="accelerometer;autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen=""
                    :src="video.link"
                    title="video player"
                    frameborder="0"
                  ></iframe>
                  
                </div>
              </div>
              <div class="flex justify-center items-center z-10 cursor-pointer">
                <font-awesome-icon
                  icon="fa-angle-right"
                  class="prev-ranking h-[25px] md:h-[40px] text-white"
                />
              </div>
              <span class="close text-white" @click="closeModal">&times;</span>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div>
      <FooterView />
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import "slick-carousel";
import NavBar from "@/components/NavBar.vue";
import FooterView from "@/components/FooterView.vue";

export default {
  name: "DisclosureView",
  components: {
    NavBar,
    FooterView,
  },

  data() {
    return {
      leaders: "",

      videos: [
        {
          showThumbnail: true,
          image: require("@/assets/resource/image.png"),
          link: "https://api.shardaonline.ac.in/media/Shubh_Arora_Principles_of_Management.mp4",
        },
        {
          showThumbnail: true,
          image: require("@/assets/resource/image1.png"),
          link: "https://api.shardaonline.ac.in/media/Madhu_Verma_Madhu_Verma_sample.mp4",
        },
      ],
    };
  },

  methods: {
    openmodal() {
      const modal = document.getElementById("myModal");
      modal.style.display = "block";
      this.intilialize();
    },

    closeModal() {
      const modal = document.getElementById("myModal");
      modal.style.display = "none";

      window.location.reload();
    },
    intilialize() {
      $(".slick-watch").slick({
        infinite: true,
        autoplay: false,
        speed: 200,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: $(".prev-ranking"),
        nextArrow: $(".next-ranking"),
        // autoplaySpeed: 3000,
      });
    },
    playVideo(index) {
      this.videos.forEach((video, i) => {
        if (i === index) {
          video.showThumbnail = false;
        } else {
          video.showThumbnail = true;
        }
      });
    },
    stopVideo(index) {
      this.videos[index].showThumbnail = true;
    },
  },
};
</script>

<style></style>
