<template>
  <div class="bg-[#F6F8FB]">
    <NavBar />
    <div>
      <!-- desktop banner -->
      <div class="hidden lg:block">
        <div class="bg-[#F6F8FB] h-full rounded-b-xl xl:rounded-b-2xl px-8">
          <div class="max-w-7xl mx-auto pt-[80px]">
            <div class="flex justify-between gap-x-4 pt-16">
              <div class="w-[882px]">
                <div class="">
                  <div v-if="validatingCondition">
                    <div class="flex flex-col gap-2 pr-4">
                      <div class="w-full h-[320px]">
                        <img
                          :src="blogDetail.image"
                          alt="desImage"
                          class="rounded-md h-full"
                        />
                      </div>
                      <h1
                        class="text-black text-[18px] lg:text-[24px] font-bold text-start"
                      >
                        {{ blogDetail.title }}
                      </h1>
                      <div class="flex gap-7 pt-3">
                        <p class="text-black text-xs text-start font-semibold">
                          {{ formatDate(blogDetail.publish_at) }}
                        </p>
                        <ul class="text-black text-xs text-start font-semibold">
                          <li class="list-disc">
                            {{ blogDetail.category.title }}
                          </li>
                        </ul>
                      </div>

                      <div>
                        <p
                          class="text-left text-[14px] md:text-[16px] p-8 bg-white rounded-lg shadow-lg"
                          v-html="blogDetail.content"
                        ></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--trending-->
              <div class="w-[320px]">
                <div>
                  <h1
                    class="text-[24px] lg:text-[34px] text-start font-bold text-[#18479E]"
                  >
                    Trending Now
                  </h1>
                </div>
                <div class="flex flex-col gap-8">
                  <div class="" v-for="blog in trendingData" :key="blog.id">
                    <div class="bg-white shadow-2xl rounded-lg">
                      <div class="p-4 text-start h-[160px]">
                        <h1
                          class="w-full lg:text-[14px] xl:text-[16px] font-semibold flex-wrap text-[#1F1F1F]"
                        >
                          {{ blog.title }}
                        </h1>
                        <div class="flex gap-7 pt-3">
                          <p
                            class="text-[#454545] text-xs text-start font-semibold"
                          >
                            {{ formatDate(blog.publish_at) }}
                          </p>
                          <ul
                            class="text-[#454545] text-xs text-start font-semibold"
                          >
                            <li class="list-disc">{{ blog.category.title }}</li>
                          </ul>
                        </div>
                        <a :href="`/blogs/${blog.slug}`">
                          <div
                            class="flex gap-2 pt-3 items-center cursor-pointer"
                          >
                            <p
                              class="text-[14px] lg:text-[14px] text-[#0CB1EF]"
                            >
                              Read More
                            </p>
                            <img
                              src="@/assets/BlogsLanding/right-up.png"
                              alt=""
                              srcset=""
                              class="w-5 h-5"
                            />
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--trending-->
            </div>
          </div>
        </div>
      </div>
      <!-- desktop banner-->
      <!-- mobile banner-->

      <div class="lg:hidden bg-[#F6F8FB] pt-[120px]">
        <div class="rounded-b-xl flex flex-col gap-7 px-5">
          <div v-if="validatingCondition">
            <div class="flex flex-col gap-3">
              <div class="">
                <img
                  :src="blogDetail.image"
                  alt="mobimage"
                  class="h-[280px] rounded-2xl w-full"
                />
              </div>
              <div class="flex gap-7 pt-3">
                <p class="text-black text-xs text-start">
                  {{ formatDate(blogDetail.publish_at) }}
                </p>
                <ul class="text-black text-xs text-start">
                  <li class="list-disc">{{ blogDetail.category.title }}</li>
                </ul>
              </div>
              <h1 class="text-black text-[18px] font-semibold text-start">
                {{ blogDetail.title }}
              </h1>
              <div>
                <p class="text-left list" v-html="blogDetail.content"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- mobile banner-->
    </div>
    <div class="bg-[#F6F8FB] px-5 lg:px-8" v-if="this.relatedBlogs.length > 0">
      <div class="w-full max-w-7xl mx-auto">
        <div class="py-5">
          <h1 class="text-black text-[18px] font-bold text-left">
            Related Article
          </h1>
          <!-- desktop -->
          <div class="py-5 hidden lg:block">
            <div class=" slick_related ">
              <div class="" v-for="blog in relatedBlogs" :key="blog.id">
                <div
                  class="rounded-xl flex flex-col gap-3 justify-center text-start bg-white p-5 max-w-[295px] mx-auto h-[160px] "
                >
                  <h1 class="text-[#1F1F1F] md:text-[16px] text-sm font-bold">
                    {{ blog.title }}
                  </h1>
                  <p class="text-[14px] text-[#454545] flex gap-3 items-center">
                    {{ formatDate(blog.publish_at) }}
                    <span class="h-[6px] w-[6px] rounded-full bg-black"></span>

                    {{ blog.category.title }}
                  </p>
                  <a :href="`/blogs/${blog.slug}`">
                    <div class="flex gap-2 py-3 items-center cursor-pointer">
                      <p class="text-[14px] lg:text-[14px] text-[#0CB1EF]">
                        Read More
                      </p>
                      <img
                        src="@/assets/BlogsLanding/right-up.png"
                        alt=""
                        srcset=""
                        class="w-5 h-5"
                      />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <!-- desktop -->

          <!-- mobile -->

          <div class="py-5 block lg:hidden">
            <div class="slick_related flex flex-col w-full">
              <div class="w-full" v-for="blog in relatedBlogs" :key="blog.id">
                <div
                  class="rounded-xl flex flex-col gap-2 justify-center text-start bg-white p-5 w-full h-[150px]"
                >
                  <h1 class="text-[#1F1F1F] md:text-[16px] text-sm font-bold">
                    {{ blog.title }}
                  </h1>
                  <p class="text-[14px] text-[#454545] flex gap-3 items-center">
                    {{ formatDate(blog.publish_at) }}
                    <span class="h-[6px] w-[6px] rounded-full bg-black"></span>
                    {{ blog.category.title }}
                  </p>
                  <a :href="`/blogs/${blog.slug}`">
                    <div class="flex gap-2 py-3 items-center cursor-pointer">
                      <p class="text-[14px] lg:text-[14px] text-[#0CB1EF]">
                        Read More
                      </p>
                      <img
                        src="@/assets/BlogsLanding/right-up.png"
                        alt=""
                        srcset=""
                        class="w-5 h-5"
                      />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <!-- mobile -->
        </div>
      </div>
    </div>
    <FooterView />
    <StickyView />
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import FooterView from "@/components/FooterView.vue";
import StickyView from "@/components/StickyView.vue";
import { useHead } from "@unhead/vue";

import axios from "axios";
import $ from "jquery";
import "slick-carousel";

export default {
  name: "BlogsDetailView",
  props: ["slug"],
  components: {
    NavBar,
    FooterView,
    StickyView,
  },
  data() {
    return {
      blogDetail: [],
      relatedBlogs: [],
      trendingData: [],
      validatingCondition: false,
    };
  },
  created() {
    this.blogsDetailInfo();
    this.fetchtrending();
  },
  methods: {
    async blogsDetailInfo() {
      await axios
        .get(`${process.env.VUE_APP_API}/blog/blog-list/?slug=${this.slug}`)
        .then((resp) => {
          if (resp.data.status == 200) {
            this.blogDetail = resp.data.data[0];
            this.validatingCondition = true;
            this.fetchFeaturedBlogs(this.blogDetail.category.title);
            console.log( this.blogDetail)
            setTimeout(()=>{
              useHead({
              title: this.blogDetail.meta_type ||'',
              meta: [
                {
                  name: 'description',
                  content: this.blogDetail.meta_description,
                },
              ],
            })
            },200)
        
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async fetchFeaturedBlogs(title) {
      await axios
        .get(
          `${process.env.VUE_APP_API}/blog/blog-list/?category__title=${title}`
        )
        .then((response) => {
          this.relatedBlogs = response.data.data;
          this.relatedBlogs = this.relatedBlogs.filter(
            (blog) => blog.id != this.blogDetail.id
          );
          setTimeout(() => {
            $(`.slick_related`).slick({
              autoplay: true,
              prevArrow: false,
              nextArrow: false,
              slidesToShow: 4,
              slidesToScroll: 1,
              dots: true,
              dotsClass: "slick_learnings_dot",

              responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  },
                },
                {
                  breakpoint: 950,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                  },
                },
                {
                  breakpoint: 650,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  },
                },
                {
                  breakpoint: 420,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  },
                },
              ],
            });
          }, 0);
        })
        .catch((error) => {
          console.error("Error fetching feature blogs:", error);
        });
    },
    async fetchtrending() {
      await axios
        .get(`${process.env.VUE_APP_API}/blog/blog-list/?trending=true`)
        .then((resp) => {
          if (resp.data.status == 200) {
            this.trendingData = resp.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const options = { month: "long", day: "2-digit", year: "numeric" };
      return date.toLocaleDateString("en-US", options);
    },
  },
};
</script>

<style>
.list li {
  list-style-position: inside;
}
</style>
