<template>
  <div class="bg-black">
    <div class="w-full max-w-7xl mx-auto">
      <div
        class="flex flex-col lg:flex-row p-3 gap-5"
      >
        <a :href="`tel:+91${program.phone_number}`">
          <div class="flex gap-2 items-center">
            <img
              src="@/assets/lp-program/Iconmaterial-call.svg"
              alt=""
              srcset=""
              class="w-[20px] h-[20px]"
            />
            <p class="text-white text-sm md:text-lg">Call us :+91 {{this.program.phone_number}}</p>
          </div>
        </a>
        <!-- <div class="flex gap-2 items-center">
          <img
            src="@/assets/lp-program/icons8-location-50.png"
            alt=""
            srcset=""
            class="w-[20px] h-[20px] text-white"
          />
          <p class="text-white text-sm md:text-lg text-left">
            Sharda University Plot No. 32-34, Knowledge Park III, Greater Noida,
            Uttar Pradesh - 201310
          </p>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterView",
   props: ["program"],
};
</script>

<style></style>
