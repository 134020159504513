<template>
  <div>
    <!-- ranking & Recognitions -->
    <section class="">
      <div
        class="w-full mx-auto justify-center items-center max-w-7xl pt-10 relative"
      >
        <div>
          <h2
            class="text-center font-bold pb-6 text-[24px] md:text-[30px] uppercase text-[#17479e]"
          >
          Rankings & Recognitions
          </h2>
        </div>
        <div class="w-full px-5">
          <!-- desktop -->
          <div class="hidden lg:block">
            <div class="flex justify-center items-center gap-7">
              <div class="relative h-[320px]">
                <div class="flex h-full items-center justify-center m-auto">
                  <div
                    class="border border-slate-200 rounded-lg w-[305px] md:w-[290px] lg:h-[160px] h-[220px] flex flex-col justify-end items-center py-6 px-4 bg-gray-200"
                  >
                    <p class="text-[13px]">&nbsp;Accredited by National Assessment <br>and Accreditation Council<br> (NAAC) with an<br> A+ Grade </p>
                    <div
                      class="w-[200px] h-[100px] p-7 bg-white shadow-2xl absolute lg:top-4 top-0 justify-center items-center rounded-md mb-8 flex"
                    >
                      <img
                        class=""
                        src="@/assets/lp-program/ranking/rr1.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="relative h-[320px]">
                <div class="flex h-full items-center justify-center m-auto">
                  <div
                    class="border border-slate-200 rounded-lg w-[305px] md:w-[290px] lg:h-[160px] h-[220px] flex flex-col justify-end items-center py-6 px-4 bg-gray-200"
                  >
                    <p class="text-[13px]"> Ranked 87th in India by the National Institutional Ranking Framework (NIRF), Ministry of Education, Government<br> of India </p>
                    <div
                      class="w-[200px] h-[100px] p-7 bg-white shadow-2xl absolute lg:top-4 top-0 justify-center items-center rounded-md mb-8 flex"
                    >
                      <img
                        class=""
                        src="@/assets/lp-program/ranking/rr2.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="relative h-[320px]">
                <div class="flex h-full items-center justify-center m-auto">
                  <div
                    class="border border-slate-200 rounded-lg w-[305px] md:w-[290px] lg:h-[160px] h-[220px] flex flex-col justify-end items-center py-6 px-4 bg-gray-200"
                  >
                    <p class="text-[13px]"> University Grants Commission (UGC) Entitled Online Degree Programs of Sharda online are equivalent to on-campus mode of learning </p>
                    <div
                      class="w-[200px] h-[100px] p-7 bg-white shadow-2xl absolute lg:top-4 top-0 justify-center items-center rounded-md mb-8 flex"
                    >
                      <img
                        class=""
                        src="@/assets/lp-program/ranking/rr3.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="relative h-[320px]">
                <div class="flex h-full items-center justify-center m-auto">
                  <div
                    class="border border-slate-200 rounded-lg w-[305px] md:w-[290px] lg:h-[160px] h-[220px] flex flex-col justify-end items-center py-6 px-4 bg-gray-200"
                  >
                    <p class="text-[13px]"> Sharda's Foundation for Science, Technology, and Research is Awarded ISO 9001:2015 <br>certification </p>
                    <div
                      class="w-[200px] h-[100px] p-7 bg-white shadow-2xl absolute lg:top-4 top-0 justify-center items-center rounded-md mb-8 flex"
                    >
                      <img
                        class=""
                        src="@/assets/lp-program/ranking/rr4.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>

          <!-- mobile -->
          <div class="block lg:hidden ">
            <div
              class="absolute bottom-0 right-0 top-[52%]  md:right-0 z-10 cursor-pointer"
            >
              <img
                src="@/assets/lp-program/icon-slider-next.svg"
                class="next-ranking"
                width="40px"
                height="40px"
                alt="slider-next"
              />
            </div>
            <div class="w-full mx-auto p-5">
              <div class="slick-ranking flex justify-center items-center">
                <div v-for="award in awards" :key="award" class="">
                  <div>
                    <div
                      class="w-[200px] h-[120px] mx-auto bg-white shadow flex justify-center rounded-md mb-6"
                    >
                      <img class="" :src="award.image" alt="" />
                    </div>
                    <p>{{ award.content }}</p>
                  </div>
                </div>

                <!-- <div class="relative h-[320px]">
                  <div class="flex h-full items-center justify-center px-5">
                    <div
                      class="border border-slate-200 rounded-lg w-[270px] md:w-[290px] lg:h-[180px] h-[220px] flex flex-col justify-end items-center py-6 px-4 bg-gray-200"
                    >
                      <p>Awards QS I gauge E-Lead Certificate</p>
                      <div
                        class="w-[200px] h-[120px] p-7 bg-white shadow-2xl absolute lg:top-4 top-0 flex justify-center rounded-md mb-8"
                      >
                        <img
                          class=""
                          src="@/assets/lp-program/QSI-Gauge.webp"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="relative h-[320px]">
                  <div class="flex h-full items-center justify-center m-auto">
                    <div
                      class="border border-slate-200 rounded-lg w-[270px] md:w-[290px] lg:h-[180px] h-[220px] flex flex-col justify-center items-center py-6 px-4 bg-gray-200"
                    >
                      <p class="pt-12">Ranked 87th By NIRF</p>
                      <div
                        class="w-[200px] h-[120px] p-7 bg-white shadow-2xl absolute lg:top-4 top-0 flex justify-center rounded-md mb-8"
                      >
                        <img
                          class=""
                          src="@/assets/lp-program/NIRF.webp"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="relative h-[320px]">
                  <div class="flex h-full items-center justify-center m-auto">
                    <div
                      class="border border-slate-200 rounded-lg w-[270px] md:w-[290px] lg:h-[180px] h-[220px] flex flex-col justify-end items-center py-6 px-4 bg-gray-200"
                    >
                      <p class="pb-6">NAAC A+ Accredited</p>

                      <div
                        class="w-[200px] h-[120px] p-7 bg-white shadow-2xl absolute lg:top-4 top-0 flex justify-center rounded-md mb-8"
                      >
                        <div class="absolute top-2 left-[50px]">
                          <img
                            class="mx-auto"
                            src="@/assets/lp-program/NAAC-A+.webp"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="relative h-[320px]">
                  <div class="flex h-full items-center justify-center m-auto">
                    <div
                      class="border border-slate-200 rounded-lg w-[270px] md:w-[290px] lg:h-[180px] h-[220px] flex flex-col justify-end items-center py-6 px-4 bg-gray-200"
                    >
                      <p class="pt-12">ARIIA Certificate of Recognitions</p>

                      <div
                        class="w-[200px] h-[120px] p-7 bg-white shadow-2xl absolute lg:top-4 top-0 flex justify-center rounded-md mb-8"
                      >
                        <div class="absolute top-2 left-[70px]">
                          <img
                            class="w-[60px]"
                            src="@/assets/lp-program/AIRRA.webp"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
            <div class="absolute bottom-0 left-0 top-[52%]  z-10 cursor-pointer">
              <img
                src="@/assets/lp-program/icon-slider-prev.svg"
                width="40px"
                height="40px"
                class="prev-ranking"
                alt="slider prev"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Skills that enhance your learning -->
  </div>
</template>

<script>
import $ from "jquery";
import "slick-carousel";
export default {
  name: "AwardsView",
  data() {
    return {
      awards: [
        {
          id: 1,
          image: require("@/assets/lp-program/ranking/rr1.jpg"),
          content: "Accredited by National Assessment and Accreditation Council (NAAC) with an A+ Grade",
        },
        {
          id: 2,
          image: require("@/assets/lp-program/ranking/rr2.jpg"),
          content: "Ranked 87th in India by the National Institutional Ranking Framework (NIRF), Ministry of Education, Government of India",
        },
        {
          id: 3,
          image: require("@/assets/lp-program/ranking/rr3.jpg"),
          content: "University Grants Commission (UGC) Entitled Online Degree Programs of Sharda online are equivalent to on-campus mode of learning",
        },
        {
          id: 4,
          image: require("@/assets/lp-program/ranking/rr4.jpg"),
          content: "Sharda's Foundation for Science, Technology, and Research is Awarded ISO 9001:2015 certification",
        },
      ],
    };
  },
  mounted() {
    $(".slick-ranking").slick({
      infinite: true,
      autoplay: true,
      speed: 200,
      slidesToShow: 4,
      slidesToScroll: 1,
      prevArrow: $(".prev-ranking"),
      nextArrow: $(".next-ranking"),
      autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
  },
};
</script>

<style>
/* .slick-slider .slick-track {
  display: flex;
  gap: 12px;
} */
</style>
