<template lang="">
  <div>
    <NavBar />

    <section class="pt-20 md:pt-20 px-5 md:px-8">
      <div class="max-w-7xl mx-auto py-3">
        <div class="">
          <ol class="flex">
            <li><a class="text-[#0CB1EF]" href="/">Home </a></li>
             &nbsp;<li> > Notifications</li>
          </ol>
        </div>
        <h1
          class="text-[#18479E] text-[24px] lg:text-[34px] text-left font-bold"
        >
        Latest Updates 
        </h1>
        <!-- <p class="text-left text-[14px] lg:text-[18px] text-[#000000]">
          Latest Updates 
        </p> -->
      </div>
      <div class="py-2 w-full flex flex-col gap-3 md:gap-[30px] md:flex-row md:justify-center lg:justify-start flex-wrap">
          <div
            v-for="(update, index) in updated"
            :key="index"
            class="text-left"
          >
            <a :href="update.link" target="blank"><div
              class="min-h-[100px] w-full md:w-[373px] lg:w-[385px] 2xl:w-[405px] border shadow-xl rounded-xl bg-white p-5 md:p-8 lg:p-10 flex flex-col gap-3 md:gap-2"
            >
              <p class="text-[18px] text-[#1f1f1f]">
                {{ update.content }}
              </p>
            </div></a>
          </div>
        </div>
    </section>
    
        <section class="pt-10">
            <div>
                <FooterView />
            </div>
        </section>
    <StickyView />
  </div>
</template>

<script>
import FooterView from "@/components/FooterView.vue";
import NavBar from "@/components/NavBar.vue";
import StickyView from "@/components/StickyView.vue";

export default {
    name: "NotificationView",
    components: {
        FooterView,
        StickyView,
        NavBar,
    },

    data() {
        return {
            updates: [
                {
                    content:
                        "Calendar of Events Postgraduate Degree Programs (Online Mode) Batch of July 2022 Even Semester – IV",
                    link: "https://api.onlinejain.com/media/alumni/Online_PG_Calendar_Jul_2022_SEM_IV_09.02_Students%20(1)_65506.pdf"
                },
                {
                    content:
                        "Calendar of Events Postgraduate Degree Programs (Online Mode) Batch of July 2023 Even Batch Semester – II",
                    link: "https://api.onlinejain.com/media/alumni/Online_PG_Calendar_Jul_2023_SEM_II_09.02_Students%20(2)_24003.pdf"
                },
                {
                    content:
                        "Calendar of Events Undergraduate Degree Programs (Online Mode) Batch of Jul 2021 Even Semester – VI",
                    link: "https://api.onlinejain.com/media/alumni/Online_UG_Calendar_Jul_2021_SEM_VI_01.03_Students_12591.pdf"
                },
                {
                    content:
                        "Calendar of Events Undergraduate Degree Programs (Online Mode) Batch of July 2022 Even Semester – IV",
                    link: "https://api.onlinejain.com/media/alumni/Online_UG_Calendar_Jul_2022_SEM_IV_09.02_Students%20(1).pdf_49188.pdf"
                },
                {
                    content:
                        "Calendar of Events Undergraduate Degree Programs (Online Mode) Batch of July 2023 Even Batch Semester – II",
                    link: "https://api.onlinejain.com/media/alumni/Online_UG_Calendar_Jul_2023_SEM_II_09.02_Students%20(2)_17949.pdf"
                },
            ],
        };
    },
    mounted() {
        this.displayedPolicy();
    },
    computed: {
        updated() {
            return this.updates.slice(0, this.faqToShow);
        },
        showMore() {
            return this.faqToShow < this.updates.length;
        },
    },



    methods: {
        displayedPolicy() {
            let filteredPolicy = this.updates;

            // sorting
            if ((this.recentClick = !this.recentClick)) {
                this.isClicked = !this.isClicked;
                filteredPolicy.sort(
                    (a, b) => new Date(b.arrived) - new Date(a.arrived)
                );
            } else {
                this.isClicked = !this.isClicked;

                filteredPolicy.sort(
                    (a, b) => new Date(a.arrived) - new Date(b.arrived)
                );
            }
        },
        viewMore() {
            this.faqToShow += 3;
        },
        viewLess() {
            this.faqToShow = 9;
        },
        isActive(index) {
            return index === this.b_value.length - 1;
        },
    },
};
</script>

<style></style>
