<template>
  <div class="w-full px-8 py-3 lg:py-6">
    <section class="w-full max-w-7xl mx-auto">
      <h1 class="text-[24px] lg:text-[30px] text-[#18479e] font-bold text-left">
        Elective Highlights
      </h1>
      <div class="flex flex-col gap-2 lg:gap-4 pt-2 lg:pt-3 px-5">
        <div>
          <div class="flex items-start gap-2 elctive">
            <span class="text-[14px] text-left" v-html="elective.elective_highlights"></span>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>  
export default {
  name: "HighightsView",
  props: ["elective"],
  data() {
    return {

    };
  },
};
</script>

<style>
.elctive ul {
  list-style-image: url("https://api.shardaonline.ac.in/media/Star_57347.webp");
}

.elctive li > span {
  position: relative;
  top: -5px;
}
</style>
