<template lang="">
  <div>
    <section class="w-full mt-2">
      <div class="w-full mx-auto max-w-7xl relative flex justify-center">
        <div
          class="absolute bottom-[-150px] lg:bottom-[-80px] w-full lg:max-w-4xl xl:max-w-5xl mx-auto"
        >
          <div class="w-full mx-auto rounded-md font-normal text-lg text-white">
            <div
              class="flex flex-col lg:flex-row justify-between items-center py-4 px-5"
            >
              <div class="flex items-center gap-4 pt-4">
                <img
                  src="../assets/icon-call.svg"
                  width="19px"
                  height="22px"
                  alt=""
                />
                <a href="tel:06361202000">
                  <p class="hover:text-[#FFD300]">+91-6361202000</p>
                </a>
              </div>
              <a
                href="https://api.whatsapp.com/send/?phone=06361202000"
                target="_blank"
              >
                <div class="flex items-center gap-4 pt-4">
                  <img
                    src="../assets/icon-whatsapp--sml1.svg"
                    width="21px"
                    height="21px"
                    alt=""
                  />
                  <p class="hover:text-[#FFD300]">+91-6361202000</p>
                </div>
              </a>
              <div class="flex items-center gap-4 pt-4">
                <img
                  src="../assets/icon-message.svg"
                  width="23px"
                  height="17px"
                  alt=""
                />
                <a href="mailto:info@sharda.ac.in">
                  <p class="hover:text-[#FFD300]">info@shardaonline.ac.in</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="w-full footer-section px-12">
      <div class="w-full mx-auto max-w-7xl pt-32 lg:pt-14">
        <div
          class="flex flex-col lg:flex-row justify-center gap-5 lg:gap-0 lg:justify-between py-10 md:py-12 lg:px-5"
        >
          <div class="flex flex-col gap-5 lg:border-r lg:border-[#596c77] pr-4">
            <div>
              <a href="/" aria-label="Re-direct to home page">
                <img
                  src="../assets/Sharda-Online-White-Logo.webp"
                  alt="Sharda online White Logo"
                  class="w-[280px] mx-auto"
                />
              </a>
            </div>
            <div class="flex justify-center items-center gap-8">
              <a
                href="https://www.instagram.com/sharda_online/"
                target="_blank"
                aria-label="instagram"
              >
                <font-awesome-icon
                  icon="fa-brands fa-instagram"
                  size="xl"
                  class="text-white hover:text-[#FFD300]"
              /></a>
              <!--<a
                href="https://www.youtube.com/@shardauniversity"
                target="_blank"
                aria-label="youtube"
              >
                <font-awesome-icon
                  icon="fa-brands fa-youtube"
                  size="xl"
                  class="text-white hover:text-[#FFD300]"
              /></a>-->
              <a
                href="https://www.facebook.com/profile.php?id=61558918626847"
                target="_blank"
                aria-label="facebook"
              >
                <font-awesome-icon
                  icon="fa-brands fa-square-facebook"
                  size="xl"
                  class="text-white hover:text-[#FFD300]"
              /></a>
              <a
                href="https://www.linkedin.com/company/sharda-online/"
                target="_blank"
                aria-label="linkedin"
              >
                <font-awesome-icon
                  icon="fa-brands fa-linkedin"
                  size="xl"
                  class="text-white hover:text-[#FFD300]"
              /></a>
              <a
                href="https://twitter.com/ShardaO58166"
                target="_blank"
                aria-label="twitter"
              >
                <font-awesome-icon
                  icon="fa-brands fa-x-twitter"
                  size="xl"
                  class="text-white hover:text-[#FFD300]"
              /></a>
            </div>
          </div>
          <!-- <div class="border-r border-[#596c77]"></div> -->
          <div class="flex flex-col gap-5 text-left hidden lg:block">
            <div class="text-white font-bold text-sm uppercase">
              Sharda ONLINE
            </div>
            <div class="text-[15px] leading-7 pt-3">
              <a href="/about-us">
                <p class="text-[#ffffff80] hover:text-white">About us</p>
              </a>
              <a href="/blogs">
                <p class="text-[#ffffff80] hover:text-white">Blogs</p>
              </a>
              <a href="/contact-us">
                <p class="text-[#ffffff80] hover:text-white text-[15px]">
                  Contact us
                </p>
              </a>
              <!-- <a href="/online-degree-programs" target=""
                ><p class="text-[#ffffff80] hover:text-white">Programs</p></a
              > -->
              <!--<a href="/blogs"
                ><p class="text-[#ffffff80] hover:text-white">Blogs</p></a
              >-->
              <!-- <a href="https://apply.shardaonline.ac.in/"
                ><p class="text-[#ffffff80] hover:text-white">Apply Now</p></a
              > -->
            </div>
          </div>
          <div class="border-r border-[#596c77]"></div>
          <div class="flex flex-col gap-5 text-left hidden lg:block">
            <div class="text-white font-bold text-sm uppercase">Programs</div>
            <div class="text-[15px] leading-7 pt-3">
              <a href="/masters-business-administration-online-degree">
                <p class="text-[#ffffff80] hover:text-white">MBA</p>
              </a>
              <a href="/master-of-commerce-online-degree">
                <p class="text-[#ffffff80] hover:text-white">M.Com</p>
              </a>
              <a href="/masters-computer-applications-online-degree">
                <p class="text-[#ffffff80] hover:text-white">MCA</p>
              </a>
              <a href="/bachelors-business-administration-online-degree">
                <p class="text-[#ffffff80] hover:text-white">BBA</p>
              </a>
              <a href="/bachelor-computer-application-online-degree">
                <p class="text-[#ffffff80] hover:text-white">BCA</p>
              </a>
              <a href="/online-ba/political-science">
                <p class="text-[#ffffff80] hover:text-white">BA (Hons.)</p>
              </a>
            </div>
          </div>
          <div class="border-r border-[#596c77]"></div>
          <div class="flex flex-col gap-5 text-left lg:block hidden">
            <div class="text-white font-bold text-sm uppercase">
              QUICK LINKS
            </div>
            <div class="text-[15px] leading-7 pt-3">
              <a href="/academic-resources">
                <p class="text-[#ffffff80] hover:text-white text-[15px]">
                  Academic Resources
                </p>
              </a>
              <a href="/disclosure-of-information">
                <p class="text-[#ffffff80] hover:text-white text-[15px]">
                  Mandatory Disclosure
                </p>
              </a>
              <a href="/refundpolicy">
                <p class="text-[#ffffff80] hover:text-white text-[15px]">
                  Refund policy
                </p>
              </a>
              <a href="/PrivacyPolicy"
                ><p class="text-[#ffffff80] hover:text-white text-[15px]">
                  Privacy policy
                </p></a
              >
              <a href="https://nad.digitallocker.gov.in/" target="_blank"
                ><p class="text-[#ffffff80] hover:text-white text-[15px]">
                  DigiLocker
                </p></a
              >

              <!--<p @click="toggledesktopdropdown" class="text-[#ffffff80] hover:text-white text-[15px]">Mandatory
                Disclosure <font-awesome-icon icon="fa-solid fa-angle-down" size="lg" /></p>

              <ul v-if="isdesktopdropdown"><a href="https://api.shardaonline.ac.in/media/Equivalence-Online-Mode.pdf"
                  target="_blank">
                  <li class="text-[#ffffff80] hover:text-white text-[15px]">
                    Equivalence of Online Mode Degree
                  </li>
                </a>
                <a href="https://api.shardaonline.ac.in/media/UGC-DEB-Public-Notice.pdf" target="_blank">
                  <li class="text-[#ffffff80] hover:text-white text-[15px]">
                    UGC-DEB Public Notice
                  </li>
                </a>
                <a href="https://api.shardaonline.ac.in/media/DEB_Aug_2023_Approval.pdf" target="_blank">
                  <li class="text-[#ffffff80] hover:text-white text-[15px]">
                    UGC-DEB Approval
                  </li>
                </a>
                <a href="/refundpolicy">
                  <li class="text-[#ffffff80] hover:text-white text-[15px]">
                    Refund Policy
                  </li>
                </a>
                <a href="/PrivacyPolicy" target="_blank">
                  <li class="text-[#ffffff80] hover:text-white text-[15px]">
                    Privacy Policy
                  </li>
                </a>
              </ul>-->

              <!-- <a href="https://api.shardaonline.ac.in/media/Equivalence-Online-Mode.pdf" target="_blank"><p class="text-[#ffffff80] hover:text-white text-[15px]">
                  Equivalence of Online Mode Degree
                </p></a
              >

              <a href="https://api.shardaonline.ac.in/media/UGC-DEB-Public-Notice.pdf" target="_blank">
                <p class="text-[#ffffff80] hover:text-white text-[15px]">
                  UGC-DEB Public Notice
                </p>
              </a>
              <a href="https://api.shardaonline.ac.in/media/DEB_Aug_2023_Approval.pdf" target="_blank">
                <p class="text-[#ffffff80] hover:text-white text-[15px]">
                  UGC-DEB Approval
                </p>
              </a>

               -->
            </div>
          </div>
          <div class="lg:hidden mb-14 pb-4">
            <div class="flex flex-col gap-4">
              <!-- First Dropdown -->
              <div class="dropdown flex flex-col gap-4">
                <button
                  @click="toggleDropdown(1)"
                  class="text-center text-white font-bold text-md w-full flex justify-between"
                >
                  SHARDA ONLINE

                  <font-awesome-icon
                    :id="'text-' + index + '-img'"
                    icon="fa-solid fa-chevron-down"
                    alt="down-arrow"
                    class="down-arrow text-white text-md"
                  />
                </button>
                <ul v-show="isDropdownOpen[1]">
                  <a href="/about-us">
                    <li class="text-[#ffffff80] text-[15px]">About Us</li>
                  </a>
                  <a href="/blogs">
                    <li class="text-[#ffffff80] text-[15px]">Blogs</li>
                  </a>
                  <a href="/contact-us">
                    <li class="text-[#ffffff80] text-[15px] hover:text-white">
                      Contact us
                    </li>
                  </a>
                  <!--<a href="/online-degree-programs"
                    ><li class="text-[#ffffff80] text-[15px]">Programs</li></a
                  >
                  <a href="https://apply.shardaonline.ac.in/"
                    ><li class="text-[#ffffff80] text-[15px]">Apply Now</li></a
                  >-->
                </ul>
              </div>

              <!-- Second Dropdown -->
              <div class="dropdown flex flex-col gap-4">
                <button
                  @click="toggleDropdown(2)"
                  class="text-center text-white font-bold text-md w-full flex justify-between"
                >
                  PROGRAMS
                  <font-awesome-icon
                    :id="'text-' + index + '-img'"
                    icon="fa-solid fa-chevron-down"
                    alt="down-arrow"
                    class="down-arrow text-white text-md"
                  />
                </button>
                <ul v-show="isDropdownOpen[2]">
                  <a href="/masters-business-administration-online-degree">
                    <li class="text-[#ffffff80] text-[15px]">MBA</li>
                  </a>
                  <a href="/master-of-commerce-online-degree">
                    <li class="text-[#ffffff80] text-[15px]">M.Com</li>
                  </a>
                  <a href="/masters-computer-applications-online-degree">
                    <li class="text-[#ffffff80] text-[15px]">MCA</li>
                  </a>
                  <a href="/bachelors-business-administration-online-degree">
                    <li class="text-[#ffffff80] text-[15px]">BBA</li>
                  </a>
                  <a href="/bachelor-computer-application-online-degree">
                    <li class="text-[#ffffff80] text-[15px]">BCA</li>
                  </a>
                  <a href="/online-ba/political-science">
                    <li class="text-[#ffffff80] text-[15px]">BA (Hons.)</li>
                  </a>
                </ul>
              </div>

              <div class="dropdown flex flex-col gap-4">
                <button
                  @click="toggleDropdown(3)"
                  class="text-center text-white font-bold text-md w-full flex justify-between"
                >
                  QUICK LINKS
                  <font-awesome-icon
                    :id="'text-' + index + '-img'"
                    icon="fa-solid fa-chevron-down"
                    alt="down-arrow"
                    class="down-arrow text-white text-md"
                  />
                </button>
                <ul v-show="isDropdownOpen[3]">
                  <a href="/academic-resources"
                    ><li class="text-[#ffffff80] text-[15px]">
                      Academic Resources
                    </li></a
                  >
                  <a href="/disclosure-of-information" target="_blank"
                    ><li class="text-[#ffffff80] text-[15px]">
                      Mandatory Disclosure
                    </li></a
                  >
                  <a href="/refundpolicy"
                    ><li class="text-[#ffffff80] text-[15px]">
                      Refund policy
                    </li></a
                  >
                  <a href="/PrivacyPolicy"
                    ><li class="text-[#ffffff80] text-[15px]">
                      Privacy policy
                    </li></a
                  >
                  <a href="https://nad.digitallocker.gov.in/" target="_blank"
                    ><li class="text-[#ffffff80] text-[15px]">DigiLocker</li></a
                  >
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--dynamic footer-->
      <div
        class="flex flex-col justify-between items-center py-5 relative bottom-24 lg:bottom-0"
        v-if="footerContent"
      >
        <div
          class="text-white font-bold cursor-pointer bg-[#0cb1ef] w-max px-4 py-2 rounded-full"
          @click="toggleFooter = !toggleFooter"
        >
          {{ toggleFooter ? "Show Less" : "Show More" }}
        </div>
        <div v-if="toggleFooter">
          <div
            class="!text-white content text-start mt-4 w-[20rem] md:w-[43rem] lg:w-[60rem] xl:w-[75rem]"
            v-html="footerContent.content"
          ></div>
        </div>
      </div>
      <div
        class="!text-white content text-start mt-4 w-[20rem] md:w-[43rem] lg:w-[60rem] xl:w-[75rem]"
        v-html="footerContent.content"
      ></div>
      <!--dynamic footer-->
    </section>
  </div>
  <!-- Modal -->
  <div class="Dmodal" v-if="isModalOpen">
    <div
      class="Dmodal-content rounded-md relative bottom-24 md:w-[35%] 2xl:w-[20%]"
    >
      <span class="close" @click="closeModal">&times;</span>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  components: {},
  data() {
    return {
      isDropdownOpen: { 1: false, 2: false, 3: false },
      isModalOpen: false,
      isdesktopdropdown: false,
      isdropdown: false,
      toggleFooter: false,
      footerContent: [],
    };
  },
  created() {
    this.footerData();
  },
  methods: {
    async footerData() {
      await axios
        .get(
          `${process.env.VUE_APP_API}/course/footer/?slug=${this.$route.path}`
        )
        .then((resp) => {
          this.footerContent = resp.data.data[0];
        });
    },

    toggleDropdown(dropdownNumber) {
      // Close all dropdowns first
      // for (const key in this.isDropdownOpen) {
      //   if (key !== dropdownNumber) {
      //     this.isDropdownOpen[key] = false;
      //   }
      // }

      // Toggle the selected dropdown
      this.isDropdownOpen[dropdownNumber] =
        !this.isDropdownOpen[dropdownNumber];
    },

    toggledropdown() {
      this.isdropdown = !this.isdropdown;
    },

    toggledesktopdropdown() {
      this.isdesktopdropdown = !this.isdesktopdropdown;
    },

    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
  },
};
</script>
<style scoped>
.footer-section {
  background: transparent linear-gradient(156deg, #184498 0%, #000919 100%) 0%
    0% no-repeat padding-box;
}

.content p span span span strong {
  text-align: start;
}

.Dmodal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.Dmodal-content {
  position: relative;
  background-color: white;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 750px;
}

@media (max-width: 760px) {
  .Dmodal-content {
    position: relative;
    background-color: white;
    margin: 55% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 100%;
  }
}

.close {
  position: relative;
  color: #aaa;
  float: right;
  top: -25px;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover {
  color: black;
}
</style>
