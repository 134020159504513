<template lang="">
  <div class="relative w-full bg-white z-50">
    <section class="shadow-md py-3 lg:py-2 shadow-[#00000029] hidden md:block">
      <div class="fixed top-0 bg-white w-full shadow-md">
        <div class="scroll-text">
          <a
            href="https://api.shardaonline.ac.in/media/UGC_20240319152222.pdf"
            target="blank"
            class="text-[#0CB1EF]"
          >
            UGC Important Notification - Precautions to be taken by the students
            before enrolling in programs offered under Open &amp; Distance
            Learning (ODL) and/or Online Learning mode
          </a>
        </div>
        <div class="px-8">
          <div class="w-full mx-auto max-w-7xl relative">
            <div class="flex justify-between items-center py-3">
              <div
                class="w-[20%] md:w-[30%] lg:w-[15%] flex justify-center items-center"
              >
                <router-link to="/">
                  <img
                    src="../assets/sharda-Online-Logo.webp"
                    class=""
                    width="339px"
                    height="70px"
                    alt="Sharda-Logo"
                    aria-label="Re-direct to home page"
                  />
                </router-link>
              </div>

              <div class="flex justify-center gap-2 md:gap-10 items-center">
                <div
                  v-if="validatingRoute"
                  class="font-semibold text-black text-[10px] md:text-[14px] lg:text-[14px]"
                >
                  <a href="/">HOME</a>
                </div>
                <div
                  class="cursor-pointer font-semibold text-white bg-[#18469d] rounded-xl p-2 text-[10px] md:text-[14px] lg:text-[14px]"
                >
                  <p @click="toggleDiv">
                    PROGRAMS
                    <font-awesome-icon
                      v-if="!clicked"
                      icon="fa-solid fa-angle-down"
                      size="lg"
                    />
                    <font-awesome-icon
                      v-else
                      icon="fa-solid fa-angle-up"
                      size="lg"
                    />
                  </p>
                  <div
                    v-if="showDiv"
                    class="w-full mx-auto max-w-5xl h-[565px] top-20 left-0 p-4 bg-white absolute"
                  >
                    <div
                      class="flex justify-start gap-12 w-full mx-auto max-w-5xl border-b-2 border-[#0CB1EF]"
                    >
                      <div
                        @click="setActiveTab('masters')"
                        :class="{
                          'active-tab border-b-4 border-b-[#0CB1EF]':
                            activeTab === 'masters',
                        }"
                        class="[w-[150px] shadow-md text-[15px] bg-[#0cb1ef] border px-8 py-4 rounded-t-md border-gray-300 hover:border-b-4 hover:border-b-[#0CB1EF]"
                      >
                        MASTERS
                      </div>
                      <div
                        @click="setActiveTab('bachelors')"
                        :class="{
                          'active-tab border-b-4 border-b-[#0CB1EF]':
                            activeTab === 'bachelors',
                        }"
                        class="[w-[150px] shadow-md text-[15px] bg-[#0cb1ef] border px-8 py-4 rounded-t-md border-gray-300 hover:border-b-4 hover:border-b-[#0CB1EF]"
                      >
                        BACHELORS
                      </div>

                      <div
                        class="flex justify-end items-center w-full"
                        @click="closeDiv"
                      >
                        <img src="@/assets/home/close-btn.png" />
                      </div>
                      revert back
                    </div>
                    <div class="w-full mx-auto max-w-5xl pt-1 flex">
                      <div class="w-1/2">
                        <div class="flex flex-col justify-start h-[400px]">
                          <div v-if="activeTab === 'bachelors'">
                            <!-- Content for BACHELORS tab -->
                            <div class="flex">
                              <div
                                class="font-medium px-10 py-2 hover:bg-[#E5EBF0] hover:text-[#0CB1EF]"
                                @click="selectedDegree = 'BBA'"
                                :class="[
                                  selectedDegree == 'BBA'
                                    ? 'bg-[#E5EBF0] text-[#0CB1EF]'
                                    : 'bg-white text-black',
                                ]"
                              >
                                BBA
                              </div>
                              <div
                                class="font-medium px-10 py-2 hover:bg-[#E5EBF0] hover:text-[#0CB1EF]"
                                @click="selectedDegree = 'BCA'"
                                :class="[
                                  selectedDegree == 'BCA'
                                    ? 'bg-[#E5EBF0] text-[#0CB1EF]'
                                    : 'bg-white text-black',
                                ]"
                              >
                                BCA
                              </div>
                              <div
                                class="font-medium px-10 py-2 hover:bg-[#E5EBF0] hover:text-[#0CB1EF]"
                                @click="selectedDegree = 'BA'"
                                :class="[
                                  selectedDegree == 'BA'
                                    ? 'bg-[#E5EBF0] text-[#0CB1EF]'
                                    : 'bg-white text-black',
                                ]"
                              >
                                BA (Hons.)
                              </div>
                            </div>
                            <div class="bg-[#0CB1EF] relative">
                              <img
                                src="../assets/bachelors.webp"
                                alt=""
                                class="w-[276px]"
                              />
                              <!-- <p class="text-white absolute right-2 top-56">
                              #DrivingYourFuture
                            </p> -->
                            </div>
                          </div>
                          <div v-else-if="activeTab === 'masters'">
                            <!-- Content for MASTERS tab -->
                            <div class="flex flex-col justify-start">
                              <div class="flex">
                                <div
                                  class="bg-[#E5EBF0] font-medium px-10 py-2 hover:bg-[#E5EBF0] hover:text-[#0CB1EF]"
                                  @click="selectedDegree = 'MBA'"
                                  :class="[
                                    selectedDegree == 'MBA'
                                      ? 'bg-[#E5EBF0] text-[#0CB1EF]'
                                      : 'bg-white text-black',
                                  ]"
                                >
                                  MBA
                                </div>
                                <div
                                  class="bg-[#E5EBF0] font-medium px-10 py-2 hover:bg-[#E5EBF0] hover:text-[#0CB1EF]"
                                  @click="selectedDegree = 'MCA'"
                                  :class="[
                                    selectedDegree == 'MCA'
                                      ? 'bg-[#E5EBF0] text-[#0CB1EF]'
                                      : 'bg-white text-black',
                                  ]"
                                >
                                  MCA
                                </div>
                                <div
                                  class="bg-[#E5EBF0] font-medium px-10 py-2 hover:bg-[#E5EBF0] hover:text-[#0CB1EF]"
                                  @click="selectedDegree = 'M.Com'"
                                  :class="[
                                    selectedDegree == 'M.Com'
                                      ? 'bg-[#E5EBF0] text-[#0CB1EF]'
                                      : 'bg-white text-black',
                                  ]"
                                >
                                  M.Com
                                </div>
                              </div>
                              <div class="bg-[#0CB1EF] relative">
                                <img
                                  src="../assets/Master-Programs.webp"
                                  alt="Masters Program"
                                  class="w-[335px]"
                                />
                                <!-- <p class="text-white absolute right-2 top-56">
                                #DrivingYourFuture
                              </p> -->
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="w-1/2">
                        <!-- Display the selected degree content -->
                        <div
                          class="flex flex-col"
                          v-if="
                            activeTab === 'bachelors' &&
                            selectedDegree === 'BBA'
                          "
                        >
                          <div class="text-left p-4 w-full">
                            <a
                              href="/bachelors-business-administration-online-degree"
                              class="text-[13px] w-full font-semibold text-black hover:bg-[#0CB1EF] hover:text-white hover:py-1 hover:px-2 block"
                              >Online BBA Degree</a
                            >
                          </div>
                          <div class="electives text-left p-2">
                            <ul>
                              <p class="text-[12px] font-normal">
                                Electives Offered
                              </p>
                              <a href="/online-bba-program">
                                <li
                                  class="ele hover:bg-[#0CB1EF] text-black hover:px-2 block capitalize text-xs font-medium"
                                >
                                  BBA Program
                                </li></a
                              >
                              <a href="/online-bba/international-finance">
                                <li
                                  class="ele hover:bg-[#0CB1EF] text-black hover:px-2 block capitalize text-xs font-medium"
                                >
                                  International Finance (Integrated by ACCA, UK)
                                </li></a
                              >
                            </ul>
                          </div>
                        </div>
                        <div
                          v-else-if="
                            activeTab === 'bachelors' &&
                            selectedDegree === 'BCA'
                          "
                        >
                          <div class="text-left p-4 w-full">
                            <a
                              href="/bachelor-computer-application-online-degree"
                              class="text-[13px] w-full font-semibold text-black hover:bg-[#0CB1EF] hover:text-white hover:py-1 hover:px-2 block"
                              >Online BCA Degree</a
                            >
                          </div>
                          <div class="electives text-left p-2">
                            <ul>
                              <p class="text-[12px] font-normal">
                                Electives Offered
                              </p>
                              <a href="/online-bca/online-bca-program">
                                <li
                                  class="ele hover:bg-[#0CB1EF] text-black hover:px-2 block capitalize text-xs font-medium"
                                >
                                  BCA Program
                                </li></a
                              >
                            </ul>
                          </div>
                        </div>
                        <div
                          v-else-if="
                            activeTab === 'bachelors' && selectedDegree === 'BA'
                          "
                        >
                          <div class="text-left p-4 w-full">
                            <a
                              href="/bachelor-of-arts-online-degree"
                              class="text-[13px] w-full font-semibold text-black hover:bg-[#0CB1EF] hover:text-white hover:py-1 hover:px-2 block"
                              >Online BA Degree</a
                            >
                          </div>
                          <div class="electives text-left p-2">
                            <ul>
                              <p class="text-[12px] font-normal">
                                Electives Offered
                              </p>
                              <a href="/online-ba/political-science">
                                <li
                                  class="ele hover:bg-[#0CB1EF] text-black hover:px-2 block capitalize text-xs font-medium"
                                >
                                  Political Science
                                </li></a
                              >
                            </ul>
                          </div>
                        </div>
                        <div
                          v-else-if="
                            activeTab === 'masters' && selectedDegree === 'MBA'
                          "
                        >
                          <div class="text-left p-4 w-full">
                            <a
                              href="/masters-business-administration-online-degree"
                              class="text-[13px] w-full font-semibold text-black hover:bg-[#0CB1EF] hover:text-white hover:py-1 hover:px-2 block"
                              >Online MBA Degree</a
                            >
                          </div>
                          <div class="electives text-left p-2">
                            <ul>
                              <p class="text-[12px] font-normal">
                                Electives Offered
                              </p>

                              <a href="/online-mba/marketing">
                                <li
                                  class="ele hover:bg-[#0CB1EF] text-black hover:px-2 block capitalize text-xs font-medium"
                                >
                                  Marketing
                                </li></a
                              >

                              <a href="/online-mba/finance">
                                <li
                                  class="ele hover:bg-[#0CB1EF] text-black hover:px-2 block capitalize text-xs font-medium"
                                >
                                  Finance
                                </li>
                              </a>

                              <a href="/online-mba/human-resource-management">
                                <li
                                  class="ele hover:bg-[#0CB1EF] text-black hover:px-2 block capitalize text-xs font-medium"
                                >
                                  Human Resource Management
                                </li>
                              </a>
                              <a href="/online-mba/data-science-and-analytics">
                                <li
                                  class="ele hover:bg-[#0CB1EF] text-black hover:px-2 block capitalize text-xs font-medium"
                                >
                                  Data Science and Analytics
                                </li>
                              </a>

                              <a href="/online-mba/operation-management">
                                <li
                                  class="ele hover:bg-[#0CB1EF] text-black hover:px-2 block capitalize text-xs font-medium"
                                >
                                  Operations Management
                                </li>
                              </a>
                              <a href="/online-mba/international-business">
                                <li
                                  class="ele hover:bg-[#0CB1EF] text-black hover:px-2 block capitalize text-xs font-medium"
                                >
                                  International Business
                                </li>
                              </a>

                              <a href="/online-mba/project-management">
                                <li
                                  class="ele hover:bg-[#0CB1EF] text-black hover:px-2 block capitalize text-xs font-medium"
                                >
                                  Project Management
                                </li></a
                              >
                              <a
                                href="/online-mba/logistics-and-supply-chain-management"
                              >
                                <li
                                  class="ele hover:bg-[#0CB1EF] text-black hover:px-2 block capitalize text-xs font-medium"
                                >
                                  Logistics and supply chain management
                                </li></a
                              >
                              <a
                                href="/online-mba/digital-marketing-and-ecommerce"
                              >
                                <li
                                  class="ele hover:bg-[#0CB1EF] text-black hover:px-2 block capitalize text-xs font-medium"
                                >
                                  Digital Marketing and E-commerce
                                </li></a
                              >
                              <a
                                href="/online-mba/healthcare-and-hospital-administration"
                              >
                                <li
                                  class="ele hover:bg-[#0CB1EF] text-black hover:px-2 block capitalize text-xs font-medium"
                                >
                                  Healthcare and Hospital Administration
                                </li></a
                              >
                              <a href="/online-mba/international-finance">
                                <li
                                  class="ele hover:bg-[#0CB1EF] text-black hover:px-2 block capitalize text-xs font-medium"
                                >
                                  International Finance (Integrated by ACCA, UK)
                                </li></a
                              >
                            </ul>
                          </div>
                        </div>
                        <div
                          v-else-if="
                            activeTab === 'masters' && selectedDegree === 'MCA'
                          "
                        >
                          <div class="text-left p-4 w-full">
                            <a
                              href="/masters-computer-applications-online-degree"
                              class="text-[13px] w-full font-semibold hover:bg-[#0CB1EF] text-black hover:py-1 hover:px-2 block"
                              >Online MCA Degree</a
                            >
                          </div>
                          <div class="electives text-left p-2">
                            <ul>
                              <p class="text-[12px] font-normal">
                                Electives Offered
                              </p>
                              <a href="/online-mca/cloud-computing">
                                <li
                                  class="ele hover:bg-[#0CB1EF] text-black hover:px-2 block capitalize text-xs font-medium"
                                >
                                  Cloud Computing
                                </li></a
                              >
                              <a href="/online-mca/cyber-security">
                                <li
                                  class="ele hover:bg-[#0CB1EF] text-black hover:px-2 block capitalize text-xs font-medium"
                                >
                                  Cyber Security
                                </li>
                              </a>
                              <a href="/online-mca/artificial-intelligence">
                                <li
                                  class="ele hover:bg-[#0CB1EF] text-black hover:px-2 block capitalize text-xs font-medium"
                                >
                                  Artificial Intelligence
                                </li>
                              </a>
                              <a href="/online-mca/data-science">
                                <li
                                  class="ele hover:bg-[#0CB1EF] text-black hover:px-2 block capitalize text-xs font-medium"
                                >
                                  Data Science
                                </li>
                              </a>
                              <a href="/online-mca/computer-science-and-it">
                                <li
                                  class="ele hover:bg-[#0CB1EF] text-black hover:px-2 block capitalize text-xs font-medium"
                                >
                                  Computer Science and IT
                                </li>
                              </a>
                            </ul>
                          </div>
                        </div>
                        <div
                          v-else-if="
                            activeTab === 'masters' &&
                            selectedDegree === 'M.Com'
                          "
                        >
                          <div class="text-left p-4 w-full">
                            <a
                              href="/master-of-commerce-online-degree"
                              class="text-[13px] w-full font-semibold hover:bg-[#0CB1EF] text-black hover:py-1 hover:px-2 block"
                              >Online M.COM Degree</a
                            >
                          </div>
                          <div class="electives text-left p-2">
                            <ul>
                              <p class="text-[12px] font-normal">
                                Electives Offered
                              </p>
                              <a href="/online-mcom/accounting-and-finance">
                                <li
                                  class="ele hover:bg-[#0CB1EF] text-black hover:px-2 block capitalize text-xs font-medium"
                                >
                                  Accounting and Finance
                                </li></a
                              >
                              <a href="/online-mcom/international-finance">
                                <li
                                  class="ele hover:bg-[#0CB1EF] text-black hover:px-2 block capitalize text-xs font-medium"
                                >
                                  International Finance (Integrated by ACCA, UK)
                                </li></a
                              >
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="font-semibold text-black text-[10px] md:text-[14px] lg:text-[14px]"
                >
                  <a href="/about-us">ABOUT US</a>
                </div>

                <div
                  @click="openModal"
                  class="font-semibold cursor-pointer text-white p-2 bg-[#0CB1EF] text-[10px] lg:text-[14px] hover:bg-white hover:text-[#0CB1EF] hover:border-2 hover:border-[#0CB1EF] rounded-xl"
                >
                  <p>ENQUIRE NOW</p>
                </div>
                <!-- <div
                class="font-semibold text-black cursor-pointer text-[8px] md:text-[13px] lg:text-[14px]"
              >
                <a href="#"><p>APPLY NOW</p></a>
              </div> -->
                <div class="hamburger-menu">
                  <button
                    @click="toggleMenu"
                    :class="{ active: isOpen }"
                    id="dhm"
                    aria-label="hamburger"
                  >
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                  </button>
                  <ul :class="{ show: isOpen }" class="formup">
                    <a href="/blogs"
                      ><li
                        class="hover:bg-[#0CB1EF] hover:text-white text-[13px] font-medium"
                      >
                        BLOGS
                      </li></a
                    >

                    <!-- <a href="https://learn.shardaonline.ac.in/" target="_blank"
                      ><li
                        class="hover:bg-[#0CB1EF] hover:text-white text-[13px] font-medium"
                      >
                        LMS
                      </li></a
                    > -->
                    <a href=" https://souls.shardaonline.info/" target="_blank"
                      ><li
                        class="hover:bg-[#0CB1EF] hover:text-white text-[13px] font-medium"
                      >
                        STUDENT SUPPORT
                      </li></a
                    >

                    <a href="/contact-us"
                      ><li
                        class="hover:bg-[#0CB1EF] hover:text-white text-[13px] font-medium"
                      >
                        CONTACT US
                      </li></a
                    >
                  </ul>
                </div>
                <!-- search -->
                <!-- <div>
                  <div class="relative">
                    <div v-if="ShowIcon" @click="toggleSearch" class="cursor-pointer">
                      <img
                        src="@/assets/search-icon.webp"
                        alt="searchBar"
                        class="ml-4 w-[21px] h-[21px] object-contain"
                      />
                    </div>
                    
                    <div
                      v-if="showSearch"
                      class="absolute bg-white shadow p-3 z-[1000px] rounded-full left-[-220px] top-[60px] flex gap-2 items-center"
                    >
                      <input
                        class="w-[250px] outline-none"
                        type="text"
                        placeholder="Search..."
                        v-model="searchitem"
                        @input="fetchResults"
                        @keyup.enter="goToSearchPage"
                      />
                    </div>
                    
                    <ul
                      v-if="showSuggestions"
                      class="search-suggestions absolute bg-white shadow-lg top-[110px] w-[290px] !-right-[15px] z-50"
                    >
                      <li
                        v-for="result in searchResults.slice(0, 10)"
                        :key="result.id"
                      >
                        <a
                          :href="`/${result.short_slug}`"
                          v-if="result"
                          class="flex gap-3 items-center"
                        >
                          <p
                            class="text-black text-[12px] text-start text-wrap font-bold"
                          >
                            {{ result.program.name }} in {{ result.name }}
                          </p>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div> -->
                <!-- search -->
                <!-- Notification -->
                <!-- <div
                class="font-semibold text-black text-[10px] md:text-[14px] lg:text-[14px]"
              >
                <a href="/notifications"><img
                  src="@/assets/notifications.webp"
                  alt="Notification"
                  class="ml-4 w-[21px] h-[21px] object-contain"
                /></a>
              </div> -->
                <!-- Notification -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- MobileView -->
    <section class="md:hidden fixed bg-white shadow-md">
      <div class="flex justify-between items-center p-5">
        <div class="w-[50%]">
          <router-link to="/">
            <img
              src="../assets/sharda-Online-Logo.webp"
              alt="Sharda online Logo"
              width="340px"
              height="70px"
              title="Sharda online Logo"
            />
          </router-link>
        </div>
        <!-- search -->
        <!-- <div>
          <div class="relative">
            <div v-if="ShowIcon" @click="toggleSearch" class="cursor-pointer">
              <img
                src="@/assets/search-icon.webp"
                alt="searchBar"
                class="ml-4 w-[21px] h-[24px] object-contain"
              />
            </div>
           
            <div
              v-if="showSearch"
              class="absolute bg-white shadow p-3 z-[1000px] rounded-full left-[-200px] top-[60px] flex gap-2 items-center"
            >
              <input
                class="w-[250px] outline-none"
                type="text"
                placeholder="Search..."
                v-model="searchitem"
                @input="fetchResults"
                @keyup.enter="goToSearchPage"
              />
            </div>
            
            <ul
              v-if="showSuggestions"
              class="search-suggestions absolute bg-white shadow-lg top-[110px] w-[260px] !-right-[30px] z-50"
            >
              <li
                v-for="result in searchResults.slice(0, 10)"
                :key="result.id"
                class=""
              >
                <a
                  :href="`/${result.short_slug}`"
                  v-if="result"
                  class="flex gap-3 items-center"
                >
                  <p
                    class="text-black text-[12px] text-start text-wrap font-bold"
                  >
                    {{ result.program.name }} in {{ result.name }}
                  </p>
                </a>
              </li>
            </ul>
          </div>
        </div> -->

        <!-- search -->

        <!-- Notification -->
        <!-- <div
          class="font-semibold text-black text-[10px] md:text-[14px] lg:text-[14px]"
        >
          <a href="/notifications"
            ><img
              src="@/assets/notifications.webp"
              alt="Notification"
              class="ml-4 w-[21px] h-[21px] object-contain"
          /></a>
        </div> -->
        <!-- Notification -->

        <!-- menu -->
        <div class="hamburger-menu">
          <button
            @click="toggleMenu"
            :class="{ active: isOpen }"
            id="mhm"
            aria-label="hamburger"
          >
            <div class="bar"></div>
            <div class="bar"></div>
            <div class="bar"></div>
          </button>
          <div
            :class="{ show: isOpen }"
            class="drop w-full bg-white h-screen cursor-pointer fixed top-20 z-50"
          >
            <!-- Tabbed Content Section -->
            <div class="w-full">
              <div
                class="flex justify-center gap-5 w-full border-b-2 border-[#0CB1EF]"
              >
                <div
                  @click="setActiveTab('masters')"
                  :class="{
                    'active-tab border-b-4 border-b-[#0CB1EF]':
                      activeTab === 'masters',
                  }"
                  class="w-[150px] shadow-md text-[13px] font-bold border px-8 py-4 rounded-t-md border-gray-300 hover:border-b-4 hover:border-b-[#0CB1EF]"
                >
                  MASTERS
                </div>
                <div
                  @click="setActiveTab('bachelors')"
                  :class="{
                    'active-tab border-b-4 border-b-[#0CB1EF]':
                      activeTab === 'bachelors',
                  }"
                  class="w-[150px] shadow-md text-[13px] font-bold border px-8 py-4 rounded-t-md border-gray-300 hover:border-b-4 hover:border-b-[#0CB1EF]"
                >
                  BACHELORS
                </div>
              </div>
              <div class="w-full mx-auto max-w-5xl pt-1 flex flex-col">
                <div>
                  <div class="flex flex-col justify-center">
                    <div v-if="activeTab === 'bachelors'">
                      <!-- Content for BACHELORS tab -->
                      <div class="flex">
                        <div
                          class="font-normal px-10 py-2"
                          @click="selectedDegree = 'BBA'"
                          :class="[
                            selectedDegree == 'BBA'
                              ? 'bg-[#E5EBF0] text-[#0CB1EF]'
                              : 'bg-white text-black',
                          ]"
                        >
                          BBA
                        </div>
                        <div
                          class="font-normal px-10 py-2"
                          @click="selectedDegree = 'BCA'"
                          :class="[
                            selectedDegree == 'BCA'
                              ? 'bg-[#E5EBF0] text-[#0CB1EF]'
                              : 'bg-white text-black',
                          ]"
                        >
                          BCA
                        </div>
                        <div
                          class="font-normal px-10 py-2"
                          @click="selectedDegree = 'BA'"
                          :class="[
                            selectedDegree == 'BA'
                              ? 'bg-[#E5EBF0] text-[#0CB1EF]'
                              : 'bg-white text-black',
                          ]"
                        >
                          BA(Hons.)
                        </div>
                      </div>
                    </div>
                    <div v-else-if="activeTab === 'masters'">
                      <!-- Content for MASTERS tab -->
                      <div class="flex flex-col justify-start">
                        <div class="flex">
                          <div
                            class="bg-[#E5EBF0] font-normal px-10 py-2 hover:bg-[#E5EBF0] hover:text-[#0CB1EF]"
                            @click="selectedDegree = 'MBA'"
                            :class="[
                              selectedDegree == 'MBA'
                                ? 'bg-[#E5EBF0] text-[#0CB1EF]'
                                : 'bg-white text-black',
                            ]"
                          >
                            MBA
                          </div>
                          <div
                            class="bg-[#E5EBF0] font-normal px-10 py-2 hover:bg-[#E5EBF0] hover:text-[#0CB1EF]"
                            @click="selectedDegree = 'MCA'"
                            :class="[
                              selectedDegree == 'MCA'
                                ? 'bg-[#E5EBF0] text-[#0CB1EF]'
                                : 'bg-white text-black',
                            ]"
                          >
                            MCA
                          </div>
                          <div
                            class="bg-[#E5EBF0] font-normal px-10 py-2 hover:bg-[#E5EBF0] hover:text-[#0CB1EF]"
                            @click="selectedDegree = 'M.Com'"
                            :class="[
                              selectedDegree == 'M.Com'
                                ? 'bg-[#E5EBF0] text-[#0CB1EF]'
                                : 'bg-white text-black',
                            ]"
                          >
                            M.Com
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <!-- Display the selected degree content -->
                  <div
                    v-if="activeTab === 'bachelors' && selectedDegree === 'BBA'"
                  >
                    <div class="text-left p-2 mt-5 w-full">
                      <a
                        href="/bachelors-business-administration-online-degree"
                        class="text-[13px] w-full font-semibold hover:bg-[#0CB1EF] hover:text-white hover:px-2 block"
                        >Online BBA Degree</a
                      >
                    </div>
                    <div class="electives text-left p-0">
                      <ul>
                        <p class="text-[12px]">Electives Offered</p>
                        <a href="/online-bba-program"
                          ><li
                            class="ele hover:bg-[#0CB1EF] hover:text-white hover:px-2 block capitalize"
                          >
                            BBA Program
                          </li></a
                        >
                        <a href="/online-bba/international-finance"
                          ><li
                            class="ele hover:bg-[#0CB1EF] hover:text-white hover:px-2 block capitalize"
                          >
                            International Finance (Integrated by ACCA, UK)
                          </li></a
                        >
                      </ul>
                    </div>
                  </div>
                  <div
                    v-else-if="
                      activeTab === 'bachelors' && selectedDegree === 'BCA'
                    "
                  >
                    <div class="text-left p-2 mt-5 w-full">
                      <a
                        href="/bachelor-computer-application-online-degree"
                        class="text-[13px] w-full font-semibold hover:bg-[#0CB1EF] hover:text-white hover:px-2 block"
                        >Online BCA Degree</a
                      >
                    </div>
                    <div class="electives text-left p-0">
                      <ul>
                        <p class="text-[12px]">Electives Offered</p>
                        <a href="/online-bca/online-bca-program"
                          ><li
                            class="ele hover:bg-[#0CB1EF] hover:text-white hover:px-2 block capitalize"
                          >
                            BCA Program
                          </li></a
                        >
                      </ul>
                    </div>
                  </div>
                  <div
                    v-else-if="
                      activeTab === 'bachelors' && selectedDegree === 'BA'
                    "
                  >
                    <div class="text-left p-2 mt-5 w-full">
                      <a
                        href="/bachelor-of-arts-online-degree"
                        class="text-[13px] w-full font-semibold hover:bg-[#0CB1EF] hover:text-white hover:px-2 block"
                        >Online BA(Hons.) Degree</a
                      >
                    </div>
                    <div class="electives text-left p-0">
                      <ul>
                        <p class="text-[12px]">Electives Offered</p>
                        <a href="/online-ba/political-science"
                          ><li
                            class="ele hover:bg-[#0CB1EF] hover:text-white hover:px-2 block capitalize"
                          >
                            Political Science
                          </li></a
                        >
                      </ul>
                    </div>
                  </div>
                  <div
                    v-else-if="
                      activeTab === 'masters' && selectedDegree === 'MBA'
                    "
                  >
                    <div class="text-left p-2 mt-5 w-full">
                      <a
                        href="/masters-business-administration-online-degree"
                        class="text-[13px] w-full font-semibold hover:bg-[#0CB1EF] hover:text-white hover:px-2 block"
                        >Online MBA Degree</a
                      >
                    </div>
                    <div class="electives text-left p-0">
                      <p class="text-[12px]">Electives Offered</p>
                      <ul class="overflow-y-scroll h-[300px]">
                        <a href="/online-mba/marketing"
                          ><li
                            class="ele hover:bg-[#0CB1EF] hover:text-white hover:px-2 block capitalize"
                          >
                            Marketing
                          </li></a
                        >
                        <a href="/online-mba/finance"
                          ><li
                            class="ele hover:bg-[#0CB1EF] hover:text-white hover:px-2 block capitalize"
                          >
                            Finance
                          </li></a
                        >

                        <a href="/online-mba/human-resource-management"
                          ><li
                            class="ele hover:bg-[#0CB1EF] hover:text-white hover:px-2 block capitalize"
                          >
                            Human resource management
                          </li></a
                        >

                        <a href="/online-mba/data-science-and-analytics"
                          ><li
                            class="ele hover:bg-[#0CB1EF] hover:text-white hover:px-2 block capitalize"
                          >
                            Data Science and Analytics
                          </li></a
                        >

                        <a href="/online-mba/operation-management"
                          ><li
                            class="ele hover:bg-[#0CB1EF] hover:text-white hover:px-2 block capitalize"
                          >
                            Operations Management
                          </li></a
                        >
                        <a href="/online-mba/international-business"
                          ><li
                            class="ele hover:bg-[#0CB1EF] hover:text-white hover:px-2 block capitalize"
                          >
                            International Business
                          </li></a
                        >

                        <a href="/online-mba/project-management"
                          ><li
                            class="ele hover:bg-[#0CB1EF] hover:text-white hover:px-2 block capitalize"
                          >
                            Project Management
                          </li></a
                        >
                        <a
                          href="/online-mba/logistics-and-supply-chain-management"
                          ><li
                            class="ele hover:bg-[#0CB1EF] hover:text-white hover:px-2 block capitalize"
                          >
                            Logistics and supply management
                          </li></a
                        >
                        <a href="/online-mba/digital-marketing-and-ecommerce"
                          ><li
                            class="ele hover:bg-[#0CB1EF] hover:text-white hover:px-2 block capitalize"
                          >
                            Digital Marketing and E-commerceAccredited
                          </li></a
                        >
                        <a
                          href="/online-mba/healthcare-and-hospital-administration"
                          ><li
                            class="ele hover:bg-[#0CB1EF] hover:text-white hover:px-2 block capitalize"
                          >
                            Healthcare and Hospital Administration
                          </li></a
                        >
                        <a href="/online-mba/international-finance"
                          ><li
                            class="ele hover:bg-[#0CB1EF] hover:text-white hover:px-2 block capitalize"
                          >
                            International Finance (Integrated by ACCA, UK)
                          </li></a
                        >
                      </ul>
                    </div>
                  </div>

                  <div
                    v-else-if="
                      activeTab === 'masters' && selectedDegree === 'MCA'
                    "
                  >
                    <div class="text-left p-2 mt-5 w-full">
                      <a
                        href="/masters-computer-applications-online-degree"
                        class="text-[13px] w-full font-semibold hover:bg-[#0CB1EF] hover:text-white hover:px-2 block"
                        >Online MCA Degree</a
                      >
                    </div>
                    <div class="electives text-left p-0">
                      <ul>
                        <p class="text-[12px]">Electives Offered</p>
                        <a href="/online-mca/cloud-computing"
                          ><li
                            class="ele hover:bg-[#0CB1EF] hover:text-white hover:px-2 block capitalize"
                          >
                            Cloud Computing
                          </li></a
                        >
                        <a href="/online-mca/cyber-security"
                          ><li
                            class="ele hover:bg-[#0CB1EF] hover:text-white hover:px-2 block capitalize"
                          >
                            Cyber Security
                          </li></a
                        >
                        <a href="/online-mca/artificial-intelligence"
                          ><li
                            class="ele hover:bg-[#0CB1EF] hover:text-white hover:px-2 block capitalize"
                          >
                            Artificial Intelligence
                          </li></a
                        >
                        <a href="/online-mca/data-science"
                          ><li
                            class="ele hover:bg-[#0CB1EF] hover:text-white hover:px-2 block capitalize"
                          >
                            Data science
                          </li></a
                        >
                        <a href="/online-mca/computer-science-and-it"
                          ><li
                            class="ele hover:bg-[#0CB1EF] hover:text-white hover:px-2 block capitalize"
                          >
                            Computer Science and IT
                          </li></a
                        >
                      </ul>
                    </div>
                  </div>
                  <div
                    v-else-if="
                      activeTab === 'masters' && selectedDegree === 'M.Com'
                    "
                  >
                    <div class="text-left p-2 mt-5 w-full">
                      <a
                        href="/master-of-commerce-online-degree"
                        class="text-[13px] w-full font-semibold hover:bg-[#0CB1EF] hover:text-white hover:px-2 block"
                        >Online M.Com Degree</a
                      >
                    </div>
                    <div class="electives text-left p-0">
                      <ul>
                        <p class="text-[12px]">Electives Offered</p>
                        <a href="/online-mcom/accounting-and-finance"
                          ><li
                            class="ele hover:bg-[#0CB1EF] hover:text-white hover:px-2 block capitalize"
                          >
                            Accounting and Finance
                          </li></a
                        >
                        <a href="/online-mcom/international-finance"
                          ><li
                            class="ele hover:bg-[#0CB1EF] hover:text-white hover:px-2 block capitalize"
                          >
                            International Finance (Integrated by ACCA, UK)
                          </li></a
                        >
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Links Section -->
            <div
              class="bg-[#d8d7d7] text-xs font-medium w-full p-4 flex flex-col gap-4"
            >
              <!-- <a href="https://learn.shardaonline.ac.in/" target="_blank"
                ><div
                  class="border-b border-b-[#ccc] hover:text-[#0CB1EF] text-[13px] font-medium"
                >
                  LMS
                </div></a
              > -->
              <a href="/blogs"
                ><div
                  class="border-b border-b-[#ccc] hover:text-[#0CB1EF] text-[13px] font-medium"
                >
                  Blogs
                </div></a
              >
              <a href="/about-us"
                ><div
                  class="border-b border-b-[#ccc] hover:text-[#0CB1EF] text-[13px] font-medium"
                >
                  About Us
                </div></a
              >
              <a href="https://souls.shardaonline.info/" target="_blank"
                ><div
                  class="border-b border-b-[#ccc] hover:text-[#0CB1EF] text-[13px] font-medium"
                >
                  Student Support
                </div></a
              >

              <a href="/contact-us"
                ><div
                  class="border-b border-b-[#ccc] hover:text-[#0CB1EF] text-[13px] font-medium"
                >
                  Contact Us
                </div></a
              >
              <!-- <div @click="toggledropdown" class="border-b border-b-[#ccc]">Mandatory Disclaimers <font-awesome-icon icon="fa-solid fa-angle-down" size="lg" /></div>
              
              <ul v-if="isdropdown">
                 <li><a
                  href="https://api.shardaonline.ac.in/media/Equivalence-Online-Mode.pdf"
                  target="_blank"
                  ><div class="border-b-[#ccc] hover:text-[#0CB1EF] text-[13px] font-medium">
                    Equivalence of Online Mode Degree
                  </div></a
                ></li>
                <li><a
                  href="https://api.shardaonline.ac.in/media/UGC-DEB-Public-Notice.pdf"
                  target="_blank"
                  ><div class="border-b-[#ccc] hover:text-[#0CB1EF] text-[13px] font-medium">
                    UGC-DEB Public Notice
                  </div></a
                ></li>
                <li><a
                  href="https://api.shardaonline.ac.in/media/DEB_Aug_2023_Approval.pdf"
                  target="_blank"
                  ><div class="border-b-[#ccc] hover:text-[#0CB1EF] text-[13px] font-medium">UGC-DEB Approval</div></a
                ></li>
                <li><a href="/refundpolicy"
                ><div class="border-b-[#ccc] hover:text-[#0CB1EF] text-[13px] font-medium">Refund Policy</div></a
                ></li>
                <li><a href="/PrivacyPolicy"
                target="_blank"><div
                    class="hover:text-[#0CB1EF] text-[13px] font-medium"
                  >
                    Privacy Policy
                  </div></a
                ></li>
            
            </ul> -->
              <!--<a href="https://support.vignanonline.com" target="blank"
                ><div class="border-b border-b-[#ccc]">STUDENT SUPPORT</div></a
              >-->
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Modal -->
    <div class="Emodal" v-if="isModalOpen">
      <div
        class="Emodal-content rounded-md relative bottom-24 md:w-[35%] 2xl:w-[20%]"
      >
        <span class="close" @click="closeModal">&times;</span>
        <EnquireView />
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { useHead } from "@unhead/vue";
import EnquireView from "@/components/enqireforms/EnquireForm.vue";
export default {
  components: {
    EnquireView,
  },

  data() {
    return {
      isOpen: false,
      showDiv: false,
      isdropdown: false,
      isdesktopdropdown: false,
      activeTab: "masters", // Initialize activeTab to 'bachelors'
      selectedDegree: "MBA", // Initialize the selected degree
      isModalOpen: false,
      validatingRoute: true,
      showSearch: false,
      searchitem: "",
      searchResults: [],
      showSuggestions: false,
      clicked: false,
    };
  },

  created() {
    this.validating();
  },
  computed: {
    ShowIcon() {
      return this.$route.path !== "/search";
    },
    electives() {
      return ["BBA", "MBA", "MCA", "Other Electives"];
    },
  },
  async mounted() {
    document.addEventListener("click", this.closeDivOnClickOutside);
    let seoTag = await this.bindMetaTags();
    useHead({
      title: seoTag.title,
      meta: seoTag.meta,
      link: seoTag.link,
    });
  },

  methods: {
    toggleSearch() {
      this.showSearch = !this.showSearch;
    },
    validating() {
      if (this.$route.path == "/") {
        this.validatingRoute = false;
      }
    },
    async bindMetaTags() {
      var metaTagDic = {};
      metaTagDic["meta"] = [];
      metaTagDic["link"] = [];
      metaTagDic["title"] = "";
      await axios
        .get(`${process.env.VUE_APP_API}/seo-list/?url=${this.$route.path}`)
        .then((resp) => {
          if (
            resp.data.status == 200 &&
            resp.data.data &&
            resp.data.data.length > 0
          ) {
            var result = resp.data.data[0];

            metaTagDic["title"] = result.title;

            metaTagDic["meta"] = [
              { name: "description", content: result.description },
              { name: "keywords", content: result.keyword.join(",") },
              { name: "og_title", content: result.og_title },
              { name: "og_img", content: result.og_img },
              { name: "og_description", content: result.og_description },
              { name: "twitter_title", content: result.twitter_title },
              { name: "twitter_img", content: result.twitter_img },
              { name: "twitter_card", content: result.twitter_card },
            ];
            metaTagDic["link"] = [
              {
                rel: "canonical",
                href: "https://shardaonline.ac.in" + this.$route.fullPath,
              },
            ];
          }
        });

      return metaTagDic;
    },
    fetchResults() {
      if (this.searchitem.length > 0) {
        axios
          .get(
            `${process.env.VUE_APP_API}/lp/landingpage-search/?search=${this.searchitem}`
          )
          .then((response) => {
            console.log("data", response.data);
            this.searchResults = response.data.electives;
            this.showSuggestions = true;
          })

          .catch((error) => {
            console.error("Error fetching search results:", error);
          });
      } else {
        this.searchResults = [];
        this.showSuggestions = false;
      }
    },
    selectSuggestion(suggestion) {
      console.log("Selected suggestion:", suggestion);
      this.searchitem = suggestion.title;
      this.showSuggestions = false;
    },
    goToSearchPage() {
      if (this.searchitem && this.searchitem.trim() !== "") {
        window.isProgrammaticNavigation = true;
        localStorage.setItem("searchitem", this.searchitem);
        this.$router.push("/search");
      } else {
        alert("Please enter a search term");
      }
    },

    toggledesktopdropdown() {
      this.isdesktopdropdown = !this.isdesktopdropdown;
    },

    toggledropdown() {
      this.isdropdown = !this.isdropdown;
    },

    toggleMenu() {
      this.isOpen = !this.isOpen;
    },
    toggleDiv() {
      this.showDiv = !this.showDiv;
      this.clicked = !this.clicked;
    },
    closeDiv() {
      this.showDiv = false;
    },

    closeDivOnClickOutside(event) {
      if (this.showDiv && !this.$el.contains(event.target)) {
        this.showDiv = false;
      }
    },

    setActiveTab(tab) {
      this.activeTab = tab;
      // Set the default selected degree based on the active tab
      if (tab === "bachelors") {
        this.selectedDegree = "BBA";
      } else if (tab === "masters") {
        this.selectedDegree = "MBA";
      }
    },

    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
  },

  beforeUnmount() {
    document.removeEventListener("click", this.closeDivOnClickOutside);
  },
};
</script>

<style scoped>
.search-suggestions {
  list-style-type: none;
  margin: 0;
  padding: 0;
  border-top: none;
  max-height: 200px;
  overflow-y: auto;
  color: black;
}

.search-suggestions li {
  padding: 10px;
  cursor: pointer;
  border: none;
}

.search-suggestions li:hover {
  background-color: #f0f0f0;
}
.hamburger-menu {
  position: relative;
}

button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  outline: none;
  position: relative;
}

.bar {
  background-color: #333;
  height: 2px;
  margin: 4px 0;
  transition: 0.4s;
  width: 25px;
}

.active .bar:nth-child(1) {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.active .bar:nth-child(2) {
  opacity: 0;
}

.active .bar:nth-child(3) {
  transform: rotate(45deg) translate(-5px, -6px);
}

.formup {
  list-style-type: none;
  padding: 10px;
  position: absolute;
  top: 66px;
  right: 0;
  background-color: #fff;
  border-top: none;
  display: none;
  min-width: 350px;
  text-align: left;
}

ul.show {
  display: block;
}

ul li {
  padding: 10px;
  border-bottom: #ccc 1px solid;
}

.drop {
  padding: 10px;
  right: 0;
  display: none;
  min-width: 350px;
  text-align: left;
}

.drop.show {
  display: block;
}

#scroll-container {
  overflow: hidden;
}

.scroll-text {
  color: #001c50;
  font-size: 15px;
  font-weight: bolder;
  white-space: nowrap;
  overflow: hidden;
}

@media (max-width: 480px) {
  .scroll-text {
    color: #001c50;
    font-size: small;
    font-weight: bolder;
    white-space: nowrap;
    overflow: hidden;
  }
}

.scroll-text {
  /* animation properties */
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);

  -moz-animation: my-animation 10s linear infinite;
  -webkit-animation: my-animation 10s linear infinite;
  animation: my-animation 30s linear infinite;
}

.scroll-text:hover {
  -webkit-animation-play-state: paused;
  -moz-animation-play-state: paused;
  -o-animation-play-state: paused;
  animation-play-state: paused;
}

/* for Firefox */
@-moz-keyframes my-animation {
  from {
    -moz-transform: translateX(100%);
  }

  to {
    -moz-transform: translateX(-100%);
  }
}

/* for Chrome */
@-webkit-keyframes my-animation {
  from {
    -webkit-transform: translateX(100%);
  }

  to {
    -webkit-transform: translateX(-100%);
  }
}

@keyframes my-animation {
  from {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  to {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.Emodal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.Emodal-content {
  position: relative;
  background-color: white;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 500px;
}

.close {
  position: relative;
  color: #aaa;
  float: right;
  top: -25px;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover {
  color: black;
}

.electives {
  display: flex;
  flex-direction: column;
  background: none;
}

.ele {
  border: none;
  font-size: 13px;
  font-weight: 500;
}
</style>
