// import VueRouter from "vue-router";
import { createRouter, createWebHistory } from "vue-router";
// import Vue from 'vue';
// import App from './App.vue';
import HomeView from "../views/HomeView.vue";
import MbaView from "../views/MbaView.vue";
// import McaView from "../views/McaView.vue";
import BbaView from "../views/BbaView.vue";
// import BbaelectiveView from "../views/BbaelectiveView.vue";
import BcaView from "../views/BcaView.vue";
// import MainBlogs from "../views/MainBlogs.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import PageNotFoundView from "@/views/PageNotFoundView";
// import ThankYouOnlineView from "@/views/thankyou/ThankYouOnlineView.vue";
import RefundView from "@/views/RefundView.vue";
// import McomView from "@/views/McomView.vue";
// import BaView from "@/views/BaView.vue";
import ProgramPage from "@/views/ProgramPage.vue";
import LP_Programs from "@/views/LPDesign/LP_Programs.vue";
import LP_ProgramsThankyou from "@/views/LPDesionThankyou/LP_ProgramsThankyou.vue";
// import AcademicsApproach from "@/views/AcademicsApproach.vue";
import BlogsView from "@/views/BlogsView.vue";
import BlogsDetailView from "@/views/BlogsDetailView.vue";
import DisclosureView from "@/views/DisclosureView.vue";
import ReferralPage from "@/views/ReferralPage.vue";
import AcademicResources from "@/views/AcademicResources.vue";
import SearchView from "@/views/SearchView.vue";
import NotificationView from "@/views/NotificationView.vue";

// Vue.config.productionTip = false;

// Vue.use(VueRouter);

const routes = [
  {
    path: "/:pathMatch(.*)*",
    name: "PageNotFound",
    component: PageNotFoundView,
  },
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/:slug",
    name: "ProgramPage",
    component: ProgramPage,
    props: true,
  },
  // {
  //   path: "/:short_slug/:slug",
  //   name: "BaView",
  //   component: BaView,
  //   props: true,
  //   beforeEnter: (to, from, next) => {
  //     // Check if the path ends with a slash
  //     if (to.path.endsWith("/")) {
  //       // Remove the trailing slash and redirect
  //       const newPath = to.path.slice(0, -1);
  //       next({ path: newPath, replace: true });
  //     } else {
  //       // Continue with the navigation
  //       next();
  //     }
  //   },
  // },
  // {
  //   path: "/:short_slug/:slug",
  //   name: "McomView",
  //   component: McomView,
  //   props: true,
  //   beforeEnter: (to, from, next) => {
  //     // Check if the path ends with a slash
  //     if (to.path.endsWith("/")) {
  //       // Remove the trailing slash and redirect
  //       const newPath = to.path.slice(0, -1);
  //       next({ path: newPath, replace: true });
  //     } else {
  //       // Continue with the navigation
  //       next();
  //     }
  //   },
  // },
  // {
  //   path: "/:short_slug/:slug",
  //   name: "BbaelectiveView",
  //   component: BbaelectiveView,
  //   props: true,
  //   beforeEnter: (to, from, next) => {
  //     // Check if the path ends with a slash
  //     if (to.path.endsWith("/")) {
  //       // Remove the trailing slash and redirect
  //       const newPath = to.path.slice(0, -1);
  //       next({ path: newPath, replace: true });
  //     } else {
  //       // Continue with the navigation
  //       next();
  //     }
  //   },
  // },
  {
    path: "/:short_slug/:slug",
    name: "MbaView",
    component: MbaView,
    props: true,
    beforeEnter: (to, from, next) => {
      // Check if the path ends with a slash
      if (to.path.endsWith("/")) {
        // Remove the trailing slash and redirect
        const newPath = to.path.slice(0, -1);
        next({ path: newPath, replace: true });
      } else {
        // Continue with the navigation
        next();
      }
    },
  },
  // {
  //   path: "/:short_slug/:slug",
  //   name: "McaView",
  //   component: McaView,
  //   props: true,
  //   beforeEnter: (to, from, next) => {
  //     // Check if the path ends with a slash
  //     if (to.path.endsWith("/")) {
  //       // Remove the trailing slash and redirect
  //       const newPath = to.path.slice(0, -1);
  //       next({ path: newPath, replace: true });
  //     } else {
  //       // Continue with the navigation
  //       next();
  //     }
  //   },
  // },
  {
    path: "/online-bba-program",
    name: "BbaView",
    component: BbaView,
    
  },
  {
    path: "/online-bca-program",
    name: "BcaView",
    component: BcaView,
  },
  {
    path: "/online-bba/general-management",
    redirect: "/online-bba-program",
  },
  {
    path: "/online-bca/general",
    redirect: "/online-bca-program",
  },
  {
    path: "/about-us",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
    beforeEnter: (to, from, next) => {
      // Check if the path ends with a slash
      if (to.path.endsWith("/")) {
        // Remove the trailing slash and redirect
        const newPath = to.path.slice(0, -1);
        next({ path: newPath, replace: true });
      } else {
        // Continue with the navigation
        next();
      }
    },
  },
  {
    path: "/contact-us",
    name: "contact",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ContactView.vue"),
    beforeEnter: (to, from, next) => {
      // Check if the path ends with a slash
      if (to.path.endsWith("/")) {
        // Remove the trailing slash and redirect
        const newPath = to.path.slice(0, -1);
        next({ path: newPath, replace: true });
      } else {
        // Continue with the navigation
        next();
      }
    },
  },
  {
    path: "/privacypolicy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/blogs",
    name: "BlogsView",
    component: BlogsView,
  },
  {
    path: "/blogs/:slug",
    name: "BlogsDetailView",
    component: BlogsDetailView,
    props: true,
  },
  {
    path: "/academic-resources",
    name: "AcademicResources",
    component: AcademicResources,
  },
  {
    path: "/disclosure-of-information",
    name: "DisclosureView",
    component: DisclosureView,
  },
  {
    path: "/referral",
    name: "ReferralPage",
    component: ReferralPage,
  },

  // {
  //   path: "/refundpolicy",
  //   name: "RefundPolicy",
  //   component: RefundPolicy,
  // },
  {
    path: "/refundpolicy",
    name: "RefundView",
    component: RefundView,
  },
  {
    path: "/search",
    name: "SearchView",
    component: SearchView,
  },
  {
    path: "/notifications",
    name: "NotificationView",
    component: NotificationView,
  },

  {
    path: "/lp/:slug",
    name: "LP_Programs",
    component: LP_Programs,
    props: true,
  },

  {
    path: "/lp/:slug/thankyou",
    name: "LP_ProgramsThankyou",
    component: LP_ProgramsThankyou,
    props: true,
  },
];

// const router = new VueRouter({
//  mode:'history',
//  base: process.env.BASE_URL,
//  routes
// });

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

window.isProgrammaticNavigation = false;

router.beforeEach((to, from, next) => {
  if (to.path === "/search") {
    if (window.isProgrammaticNavigation) {
      window.isProgrammaticNavigation = false;
      next();
    } else {
      next("/");
    }
  } else {
    next();
  }
});

export default router;
