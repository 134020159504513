<template>
    <section class=" justify-center items-center m-auto">
    <div class="container flex flex-col items-center text-center m-auto justify-center align-middle md:w-4/12 mt-8"> 
        <h1 class="text-3xl mb-3">404 Error!. The page you are looking for does not exist.</h1>
        <router-link class="lg:text-lg font-semibold text-white bg-[#0CB1EF] lg:px-4 py-2 px-1 sm:px-1 sm:text-sm rounded-xl" :to="{name: 'home' }">Go back to Home</router-link>
        </div>
    </section>
</template>

<script>
export default {
    name: 'PageNotFound',
    created() {
        
    },
};
</script>

<style>

</style>