<template>
  <div>
    <div class="hidden lg:block fixed top-[50%] right-5 z-20">
      <div
        v-for="list in lists"
        :key="list.id"
        class="flex flex-col items-end py-[1px]"
      >
        <a
          @mouseenter="active = list.id"
          @mouseleave="active = ''"
          :href="list.link"
          target="_blank"
        >
          <div class="flex items-center group">
            <img
              :src="list.img"
              :alt="list.alt"
              width="44"
              class="group-hover:bg-[#17479d] group-hover:rounded-l-3xl"
            />
            <p
              v-if="active === list.id"
              class="w-40 h-[44px] flex justify-center items-center group-hover:bg-[#17479d] text-center text-white"
            >
              {{ list.title }}
            </p>
          </div>
        </a>
      </div>
    </div>
    <section>
      <div class="fixed bottom-0 w-full">
        <div class="w-full md:hidden bg-[#0CB1EF]">
          <!--<div class="bg-[#0CB1EF] md:hidden z-20 w-full h-8">
          <span
            class="text-white font-bold z-50 text-center"
            style=""
            >Admission closing soon
            <span
              id="countdown1"
              class="text-[#2a2a2a] font-semibold text-xl"
              > {{ countdown1 }}</span
            > 
            </span
          >
        </div>-->
          <div class="flex justify-between px-3 py-3">
            <div class="blinking-bg rounded-lg">
              <div
                @click="openModal"
                class="flex flex-col justify-center items-center gap-3"
              >
                <img
                  src="@/assets/home/sticky/icon-enquire.webp"
                  class="w-[25px] h-[25px]"
                  title="Enquire Now Mobile"
                  alt="Enquire Now Mobile"
                />
                <p class="text-white font-semibold text-[11px] uppercase">
                  Enquire Now
                </p>
              </div>
            </div>
            <div class="border-r border-white"></div>
            <!-- <a
              href="https://www.sharda.ac.in/vtour/tour.html?startscene=0&startactions=lookat(5.99,0.08,120,0,0);"
              target="_blank"
            >
              <div class="flex flex-col justify-center items-center gap-3">
                <img
                  src="@/assets/binoculars.webp"
                  class="w-8"
                  width="41px"
                  height="41px"
                  alt="Virtual tour icon"
                />
                <p class="text-white font-semibold text-[11px] uppercase">
                  Virtual Tour
                </p>
              </div></a
            >
            <div class="border-r border-white"></div> -->
            <a
              href="https://api.whatsapp.com/send/?phone=916361202000&amp;text&amp;app_absent=0"
              target="_blank"
            >
              <div class="flex flex-col justify-center items-center gap-3">
                <img
                  src="@/assets/home/sticky/icon-whatsapp-white.svg"
                  class="w-8"
                  width="41px"
                  height="41px"
                  alt="whatsapp-icon"
                />
                <p class="text-white font-semibold text-[11px] uppercase">
                  Whatsapp
                </p>
              </div></a
            >

            <div class="border-r border-white"></div>
            <a href="tel:06361202000">
              <div class="flex flex-col justify-center items-center gap-3">
                <img
                  src="@/assets/home/sticky/call-icon.webp"
                  class="w-[25px] h-[25px]"
                  title="Call Icon Mobile"
                  alt="Call Icon Mobile"
                />
                <p class="text-white font-semibold text-[11px] pt-1 uppercase">
                  Call Now
                </p>
              </div></a
            >
          </div>
        </div>
      </div>
    </section>
    <!-- Modal -->
    <div class="Emodal" v-if="isModalOpen">
      <div
        class="Emodal-content rounded-md relative bottom-24 md:w-[35%] 2xl:w-[20%]"
      >
        <span class="close" @click="closeModal">&times;</span>
        <EnquireView />
      </div>
    </div>
  </div>
</template>

<script>
import EnquireView from "@/components/enqireforms/EnquireForm.vue";
export default {
  data() {
    return {
      lists: [
        {
          id: 1,
          img: require("@/assets/so-binoculaers.webp"),
          link: "https://www.sharda.ac.in/vtour/tour.html?startscene=0&startactions=lookat(5.99,0.08,120,0,0);",
          title: "Virtual tour",
          alt: "Virtual tour icon",
        },
        {
          id: 2,
          img: require("@/assets/Call-desktop.webp"),
          link: "tel:6361202000",
          title: "Make a call",
          alt: "Call icon mobile",
        },
        {
          id: 3,
          img: require("@/assets/Whatsapp-desktop.webp"),
          link: "https://api.whatsapp.com/send/?phone=916361202000&text&app_absent=0",
          title: "Chat with us",
          alt: "Whatsapp icon",
        },
      ],
      active: null,
      countdown: "Loading...", // Initial value before the timer starts
      isModalOpen: false,
    };
  },
  components: {
    EnquireView,
  },
  methods: {
    getNeverEndingDate() {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      return tomorrow.setHours(0, 0, 0, 0);
    },
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
  },
  mounted() {
    const updateCountdown = () => {
      const now = new Date().getTime();
      const distance = this.countDownDate - now;

      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      this.countdown = `${hours}h ${minutes}m ${seconds}s`;
      this.countdown1 = `${hours}h ${minutes}m ${seconds}s`;

      if (distance < 0) {
        this.countDownDate = this.getNeverEndingDate();
      }
    };

    this.countDownDate = this.getNeverEndingDate();
    updateCountdown();
    setInterval(updateCountdown, 1000);
  },
};
</script>
<style scoped>
.Emodal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.Emodal-content {
  position: relative;
  background-color: white;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  top: 50px;
}

.close {
  position: relative;
  color: #aaa;
  float: right;
  top: -25px;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover {
  color: black;
}

@keyframes blink {
  0% {
    background-color: #18469d;
  }
  50% {
    background-color: transparent; /* or any other color you want during the blink */
  }
  100% {
    background-color: #18469d;
  }
}

.blinking-bg {
  animation: blink 1s infinite;
  background-color: #18469d; /* Set the initial background color */
  padding: 5px;
}
</style>
