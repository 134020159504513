<template lang="">
  <div>
    <section class="px-8">
      <div class="w-full mx-auto max-w-7xl">
        <!-- Desktop -->
        <div class="hidden md:block py-[50px]">
          <div class="flex justify-center items-center">
            <div class="w-1/2 p-4">
              <h2
                class="text-[#18479e] font-extrabold text-left md:text-xl lg:text-3xl"
              >
                Make Your Dreams a
                <br />
                Reality with Us
              </h2>
              <p
                class="font-bold text-lg lg:text-2xl text-left text-black pt-4"
              >
                Degrees from Sharda Online are:
              </p>
              <ul class="text-left ml-4 pt-5 text-black text-[18px]">
                <li class="list-disc text-base lg:text-xl">
                  Equivalent to Sharda University on-campus degree program
                </li>
                <li class="list-disc text-base lg:text-xl mt-4">
                  Globally recognized
                </li>
              </ul>

              <div class="text-left">
                <a href="https://apply.shardaonline.ac.in/" target="blank">
                  <button
                    class="bg-[#0CB1EF] text-white font-semibold text-[16px] px-4 py-2 w-[150px] md:py-2 md:w-36 mt-5 rounded-2xl"
                  >
                    APPLY NOW
                  </button>
                </a>
              </div>
            </div>
            <div class="relative p-2">
              <div
                class="bg-[#001C54] w-[500px] h-[570px] rounded-3xl flex-col justify-center items-center"
              >
                <div class="slick_dreams flex items-center justify-center">
                  <img
                    src="@/assets/home/dreams/GradeCardSample.webp"
                    alt="sample-certificate"
                    class="w-[300px] h-[570px] p-4 rounded-3xl"
                    loading="lazy"
                    decoding="async"
                  />
                  <img
                    src="@/assets/home/dreams/MBA-B-FDegreeSample.webp"
                    alt="sample-certificate"
                    class="w-[300px] h-[570px] p-4 rounded-3xl"
                    loading="lazy"
                    decoding="async"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Desktop -->

        <!-- Mobile -->
        <div class="md:hidden mt-[2rem] mb-[2rem]">
          <div class="w-full mx-auto">
            <h1 class="text-center py-2 text-[#18479e] font-extrabold text-[24px]">
              Make Your Dreams a Reality with Us
            </h1>
            <div class="">
              <div class="h-[370px]">
                <div
                  class="flex slick_dreams items-center h-full justify-center bg-[#001C54] rounded-3xl"
                >
                  <img
                    src="@/assets/home/dreams/GradeCardSample.webp"
                    alt="sample-certificate"
                    class="w-full h-[362px] p-5 rounded-[30px]"
                    loading="lazy"
                    decoding="async"
                  />
                  <img
                    src="@/assets/home/dreams/MBA-B-FDegreeSample.webp"
                    alt="sample-certificate"
                    class="w-full h-[362px] p-5 rounded-[30px]"
                    loading="lazy"
                    decoding="async"
                  />
                </div>
              </div>
            </div>
            <div>
              <p
                class="text-left ml-4 text-black font-semibold text-[16px] mt-2"
              >
                Degrees from Sharda Online are:
              </p>
              <ul class="text-black pt-3 text-left ml-8">
                <li class="list-disc mt-3">
                  Equivalent to Sharda University on-campus degree program
                </li>
                <li class="list-disc mt-3">Globally recognized</li>
              </ul>
            </div>

            <a href="https://apply.shardaonline.ac.in/" target="blank">
              <button
                class="bg-[#0CB1EF] text-white font-semibold text-[16px] px-4 py-2 w-[150px] mt-5 rounded-2xl"
              >
                APPLY NOW
              </button>
            </a>
          </div>
        </div>
        <!-- Mobile -->
      </div>
    </section>
  </div>
</template>
<script>
import $ from "jquery";
import "slick-carousel";
export default {
  name: "DreamsView",
  mounted() {
    this.dreamsCarousel();
  },
  methods: {
    dreamsCarousel() {
      $(".slick_dreams").slick({
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        // nextArrow: $(".next-rank"),
        // prevArrow: $(".prev-rank"),
        nextArrow: false,
        prevArrow: false,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 640,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
    },
  },
};
</script>
<style lang=""></style>
