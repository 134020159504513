<template>
  <div>
    <NavBar />
    <!--banner part-->
    <div
      class="w-full mx-auto relative object-cover bg-center bg-no-repeat"
      v-if="this.Electives"
    >
      <div class="md:px-8">
        <div class="max-w-7xl mx-auto">
          <div
            class="absolute top-24 md:top-1 text-[10px] bg-gray-50 px-2 py-1 md:p-0 md:bg-transparent md:text-xs"
          >
            <p class="text-[#0CB1EF] md:text-black text-sm font-bold">
              <a href="/" class="text-[#0CB1EF]">Home</a> >
              <a
                href="/bachelor-computer-application-online-degree"
                class="text-[#0CB1EF]"
                >BCA</a
              >
              >
              <span class="text-black"> {{ Electives.name }}</span>
            </p>
          </div>
        </div>
      </div>
      <img :src="Electives.banner" class="hidden md:block w-full mt-24" />
      <img :src="Electives.mobile_banner" class="md:hidden w-full pt-20" />
      <div class="px-8">
        <div class="max-w-7xl mx-auto">
          <div
            class="absolute top-[35%] md:top-[16%] lg:top-[20%] 2xl:top-[10%] text-white"
          >
            <h1
              class="text-[24px] md:text-3xl lg:text-4xl bg-[#18479E] bg-opa px-2 mb-2 font-bold w-[15rem] md:w-[20rem] text-start text-white"
            >
              {{ Electives.sub_name }}
            </h1>
            <h1
              class="text-[24px] md:text-3xl lg:text-4xl bg-[#18479E] bg-opa px-2 font-bold w-[19rem] md:w-max text-start text-white"
            >
              {{ Electives.name }}
            </h1>
          </div>
          <div>
            <div
              class="flex flex-col md:flex-row gap-4 absolute top-[58%] md:top-[33%] lg:top-[36%] xl:top-[40%] 2xl:top-[27%] xxl:top-[20%]"
            >
              <div class="flex">
                <span
                  ><img
                    src="@/assets/programInfo/naac.webp"
                    alt="naac"
                    class="w-[40px]"
                /></span>
                <span
                  class="text-sm font-bold bannernaac text-white position-relative top-[10px] md:text-black"
                  >NAAC A+ ACCREDITED</span
                >
              </div>
              <div class="flex">
                <span
                  ><img
                    src="@/assets/programInfo/ugc.webp"
                    alt="ugcweb"
                    class="w-[40px]" /></span
                ><span
                  class="text-sm text-start bannernaac font-bold text-white md:text-black"
                  >UGC ENTITLED</span
                >
              </div>
            </div>
          </div>
          <div>
            <div
              class="absolute text-xl w-full leading-7 text-left font-[700] top-[76%] md:top-[49%] lg:w-[55%] xl:top-[50%] 2xl:top-[40%] xxl:top-[28%] flex flex-col gap-5"
            >
              <div class="flex flex-wrap gap-10">
                <div class="flex gap-2 items-center">
                  <div>
                    <img
                      src="@/assets/elective/Duration.png"
                      alt=""
                      class="w-7 h-7"
                    />
                  </div>
                  <div class="flex flex-col">
                    <h1 class="text-xs text-black">Duration</h1>
                    <p class="text-[#18479E] text-xs">3 YEARS</p>
                  </div>
                </div>
                <div class="flex gap-2 items-center">
                  <div>
                    <img
                      src="@/assets/elective/Program-Credits.png"
                      alt=""
                      class="w-7 h-7"
                    />
                  </div>
                  <div class="flex flex-col">
                    <h1 class="text-xs text-black">Program Credits</h1>
                    <p class="text-[#18479E] text-xs">120</p>
                  </div>
                </div>
              </div>
              <div class="flex flex-wrap gap-6">
                <div class="flex gap-2 items-center">
                  <div>
                    <img
                      src="@/assets/elective/Flexible.png"
                      alt=""
                      class="w-7 h-7"
                    />
                  </div>
                  <div class="flex flex-col">
                    <h1 class="text-xs text-black">Flexible</h1>
                    <p class="text-[#18479E] text-xs">EMI OPTION</p>
                  </div>
                </div>
                <div class="flex gap-2 items-center">
                  <div>
                    <img
                      src="@/assets/elective/Live-Online-Sessions.png"
                      alt=""
                      class="w-7 h-7"
                    />
                  </div>
                  <div class="flex flex-col">
                    <h1 class="text-xs text-black">Live Online Sessions</h1>
                    <p class="text-[#18479E] text-xs">WEEKEND</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="text-black md:text-left absolute bottom-[3%] md:bottom-[2%] lg:bottom-[8%] xl:bottom-[20%] 2xl:bottom-[35%] xxl:bottom-[50%]"
            >
              <div class="flex lg:flex-col flex-row gap-5">
                <a href="https://apply.shardaonline.ac.in/" target="_blank">
                <button
                  class="bg-[#0CB1EF] text-white font-[600] text-[16px] px-4 py-2 w-[150px] md:py-2 md:w-36 mt-5 rounded-2xl"
               
                >
                  APPLY NOW
                </button>
                </a>
              </div>
            </div>
            <div
              class="text-black md:text-left absolute bottom-[3%] md:bottom-[2%] lg:bottom-[8%] xl:bottom-[20%] 2xl:bottom-[35%] xxl:bottom-[50%] left-[50%] md:left-[25%] lg:left-[20%] xl:left-[18%] 2xl:left-[25%] xxl:left-[32%]"
              v-if="this.Electives.brochures"
            >
              <div class="flex lg:flex-col flex-row gap-5">
                <button
                  class="bg-[#0CB1EF] text-white font-[600] text-[16px] px-4 py-2 w-[200px] md:py-2 mt-5 rounded-2xl"
                  @click="openModalTwo"
                >
                  Download Brochure
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--banner part completed-->

    <!--About the elective-->
    <div class="w-full px-8 py-3 md:py-6">
      <section class="w-full max-w-7xl mx-auto">
        <div class="text-left flex flex-col gap-2">
          <h1 class="text-[24px] lg:text-[30px] text-[#18479e] font-bold">
            About {{ Electives.name }}
          </h1>
          <p
            class="text-[14px] lg:text-[14px]"
            v-html="Electives.description"
          ></p>
        </div>
      </section>
    </div>
    <!--About the elective end-->

    <!--Program highlights-->
    <div class="w-full px-8 pb-5">
      <div class="max-w-7xl mx-auto w-full">
        <h2
          class="font-bold text-[#18469D] text-lg md:text-2xl lg:text-[30px] pb-5 text-left"
        >
          Elective <span class="text-[#18469D]">Highlights</span>
        </h2>
        <div
          class="flex flex-col md:flex-row justify-start lg:gap-[80px] w-full"
          v-if="Electives.elective_highlights"
        >
          <div class="flex flex-col justify-center items-start">
            <div
              class="flex justify-center gap-4 md:gap-8 items-center px-3 py-3"
            >
              <!-- <img src="@/assets/Program/icon-bulb.svg" /> -->
              <span
                class="text-left text-[14px] highlights px-8 md:px-2 elctive"
                v-html="Electives.elective_highlights"
              ></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Program highlights end-->

    <div class="w-full py-5 bg-[#f7f9fb]">
      <!--syllabus Fees structure tab-->
      <Syllabus v-if="this.Electives" :elective="Electives" />
      <!--syllabus Fees structure tab-->

      <!--learning methodology-->
      <LearningView />
      <!--learning methodology-->

      <!--Enroll-->
      <EnrollView />
      <!---->
      <!--carrerAdvancement-->
      <CareerView />
      <!--carrerAdvancement-->

      <!--our Technology-->
      <OurTechnology />
      <!--our Technology-->

      <!-- <div class="w-full py-5 bg-[#f7f9fb]">
        <div class="max-w-7xl mx-auto w-full">
          <h2
            class="font-bold text-black text-lg pt-5 md:text-2xl pb-5 text-left md:px-5"
          >
            HIRING <span class="text-primary">COMPANIES</span>
          </h2>
        </div>
      </div> -->
      <HiringComp />

      <div class="w-full px-8">
        <!-- FAQ -->
        <div id="faq-content">
          <div class="max-w-screen-2xl mx-auto w-full">
            <div class="flex max-w-7xl mx-auto">
              <h2
                class="text-[24px] lg:text-[30px] text-[#18479e] font-bold text-left capitalize"
              >
                FAQs
              </h2>
            </div>
            <div class="accordion bg-trasparent max-w-6xl mx-auto" id="faqs">
              <div
                v-for="(el, index) in this.ElecFaq.slice(this.start, this.end)"
                :key="el"
                class="accordion-item border-t-0 border-x-0 text-left"
              >
                <h2 class="accordion-header mb-0">
                  <button
                    @click="faqCollapse"
                    class="accordion-button font-semibold relative flex items-center justify-between w-full py-4 px-5 text-[15px] text-[#18469D] hover:text-[#0a58ca] text-left border-0 rounded-none transition focus:outline-none"
                    type="button"
                    :data-target="'#text-' + index"
                  >
                    {{ el.title }}
                    <font-awesome-icon
                      :id="'text-' + index + '-img'"
                      icon="fa-solid fa-circle-chevron-down"
                      alt="down-arrow"
                      class="down-arrow text-[#18469d] text-xl"
                    />
                    <!-- <img
                    src="../assets/home/Untitled.png"
                    alt="down-arrow"
                    class="down-arrow"
                    />  -->
                  </button>
                </h2>
                <div
                  :id="'text-' + index"
                  class="accordion-collapse border-0 hidden text-sm md:text-base text-black"
                >
                  <div class="accordion-body pb-4 px-10 flex flex-col gap-3">
                    <!-- <p v-html="el.answer_text"></p> -->
                    <pre
                      style="white-space: pre-line"
                      class="text-[14px]"
                      v-html="el.content"
                    ></pre>
                  </div>
                </div>
              </div>
            </div>
            <!-- <button class="font-medium">Load more >></button> -->
            <button
              v-if="this.ElecFaq.length >= this.end"
              @click="this.end = this.end + 3"
              class="bg-[#0CB1EF] text-white font-semibold px-8 py-3 rounded-[50px] mt-7"
            >
              View More
            </button>
            <button
              v-if="this.ElecFaq.length <= this.end"
              @click="this.end = 4"
              class="bg-[#0CB1EF] text-white font-semibold px-8 py-3 rounded-[50px] mt-5"
            >
              View Less
            </button>
          </div>
        </div>
      </div>
    </div>
    <FooterView />
    <StickyView />
    <!--modal-->
    <div class="Bannermodal1" v-if="isModalOpen">
      <div class="Bannermodal-content1 rounded-md">
        <span class="close" @click="closeModal">&times;</span>
        <EnquireView />
      </div>
    </div>
    <!--modal-->
    <!--downloadmodal-->
    <div class="Bannermodal2" v-if="isModalOpenTwo">
      <div
        class="Bannermodal-content2 rounded-md md:w-[50%] lg:w-[40%] xl:w-[30%] xxl:w-[20%]"
      >
        <span class="close" @click="closeModalTwo">&times;</span>
        <DownloadEnquireElectiveForm :electiveInfo="this.Electives" />
      </div>
    </div>
    <!--downloadmodal-->
  </div>
</template>
<script>
import $ from "jquery";
import "slick-carousel";
import axios from "axios";
import HiringComp from "@/components/HiringComp.vue";
import FooterView from "@/components/FooterView.vue";
import NavBar from "@/components/NavBar.vue";
import StickyView from "@/components/StickyView.vue";
import EnquireView from "@/components/enqireforms/EnquireForm.vue";
import LearningView from "@/components/McaDetail/LearningView.vue";
import EnrollView from "@/components/McaDetail/EnrollView.vue";
import CareerView from "../components/ElectiveDetail/CareerView.vue";
import OurTechnology from "../components/ElectiveDetail/OurTechnology.vue";
import Syllabus from "@/components/Bachelor/Syllabus.vue";
import DownloadEnquireElectiveForm from "@/components/enqireforms/DownloadEnquireElectiveForm.vue";

export default {
  name: "BbaView",
  components: {
    FooterView,
    HiringComp,
    NavBar,
    StickyView,
    EnquireView,
    Syllabus,
    LearningView,
    EnrollView,
    CareerView,
    OurTechnology,
    DownloadEnquireElectiveForm,
  },
  data() {
    return {
      selectedTab: 0,
      Electives: [],
      start: 0,
      end: 4,
      ElecFaq: [],
      isModalOpen: false,
      isModalOpenTwo: false,
    };
  },

  //   props: ["elective"],
  props: ["short_slug", "slug"],

  created() {
    this.electiveCall();
    this.electiveFaq();
  },

  methods: {
    faqCollapse(e) {
      var accordionItem = $(e.target).parents(".accordion-item");
      var dataTarget = accordionItem
        .find(".accordion-button")
        .attr("data-target");

      var icon = accordionItem.find(".accordion-button .down-arrow");

      if (icon.hasClass("rotate-180")) {
        icon.removeClass("rotate-180");
        $(dataTarget).hide("slow");
      } else {
        $(".accordion-item .accordion-button .down-arrow").removeClass(
          "rotate-180"
        );
        $(".accordion-item .accordion-collapse").hide("slow");
        icon.addClass("rotate-180");
        $(dataTarget).show("slow");
      }
    },

    async electiveCall() {
      const slug = "online-bca-program"; // Get the slug from the route
      const apiUrl = `${process.env.VUE_APP_API}/course/elective-list/?program__short_slug=${this.short_slug}&&slug=${slug}`;

      try {
        const resp = await axios.get(apiUrl);
        if (resp.data.status === 200) {
          this.Electives = resp.data.data[0];
        } else {
          this.$router.push({
            name: "PageNotFound",
            params: { pathMatch: "page-not-found" },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },

    async electiveFaq() {
      const electiveSlug = "online-bca-program";
      const apiUrl = `${process.env.VUE_APP_API}/faq/elective-faq-list/?elective__slug=${electiveSlug}`;

      if (!electiveSlug) {
        console.error("elective Slug is not defined.");
        return;
      }

      try {
        const response = await axios.get(apiUrl);

        if (response.data) {
          this.ElecFaq = response.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },

    selectTab(tabId) {
      this.selectedTab = tabId;
    },

    openModel() {
      this.isModalOpen = true;
    },
    openModalTwo() {
      this.isModalOpenTwo = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    closeModalTwo() {
      this.isModalOpenTwo = false;
    },
  },
};
</script>

<style scoped>
@media (max-width: 425px) {
  .gradbox {
    width: 180px;
  }
}
@media (max-width: 375px) {
  .gradbox {
    width: 158px;
  }
}
@media (max-width: 320px) {
  .gradbox {
    width: 133px;
  }
}
.grad {
  background-color: #364c70;
  background-image: linear-gradient(to right, #0cb1ef, #bed630);
  /* padding: 45px 30px; */
}

.elctive li {
  list-style-image: url("https://ik.imagekit.io/usdcglobalweb/sharda/Star.webp?updatedAt=1714671608122");
}

.elctive li > span {
  position: relative;
  top: -8px;
}

ul li::before {
  content: "\2022";
  color: #18469d;
  font-weight: bold;
  font-size: 20px;
  display: inline-block;
  width: 1em;
  margin-left: 1em;
}

@media (min-width: 768px) {
  .Arrow::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #18469d;
    bottom: -10px;
    left: 50%;
    transform: rotate(45deg);
  }
}

.bannernaac {
  position: relative;
  top: 10px;
  text-indent: 8px;
}

.bg-opa {
  opacity: 0.8;
  /* text-transform: uppercase; */
}

.Bannermodal1 {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.Bannermodal-content1 {
  position: relative;
  background-color: white;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 30%;
  bottom: 7%;
}
.Bannermodal-content2 {
  position: relative;
  background-color: white;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
}

@media (max-width: 767.98px) {
  .Bannermodal-content1 {
    position: relative;
    background-color: white;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 95%;
    top: 20%;
  }
  .Bannermodal-content2 {
    position: relative;
    background-color: white;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 95%;
    top: 20%;
  }
}

.close {
  position: relative;
  color: #aaa;
  float: right;
  top: -25px;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover {
  color: black;
}
</style>
