<template>
  <div class="w-full bg-white py-3 md:py-6 lg:px-8 ">
    <section class="w-full max-w-7xl md:mx-auto px-5 lg:px-0">
      <h1
        class="text-[24px] lg:text-[30px] text-[#18479e] font-bold text-center capitalize"
      >
        Why Us
      </h1>
      <div>
        <div class="hidden lg:block pt-5">
          <div class="flex justify-center gap-[80px]">
            <button
              v-for="el in whyUs"
              :key="el"
              @click="selectedTab = el"
              :class="{
                ' border border-[#0CB1EF] text-blue-400 ': selectedTab === el,
                ' border bg-[#F6F8FB]': selectedTab !== el,
              }"
              class="text-center px-8 py-3 text-[14px] rounded-md 2xl:text-[18px] shadow-2xl text-black font-semibold uppercase"
            >
              {{ el }}
            </button>
          </div>
          <!-- desktop cards -->
          <div class="py-4 h-auto w-full">
            <div
              class="bg-white py-8"
              v-if="selectedTab === 'Academic Approach'"
            >
              <!-- <p class="text-[14px] lg:text-[16px] text-center">
                Crafting futures, Inspiring success SU's online learning
                programs are unique for several reasons,
                <br class="hidden md:block" />
                including its state-of-the-art and vibrant community
              </p> -->
              <div class="flex justify-between flex-wrap gap-5 py-4">
                <div v-for="academic in academics" :key="academic" class="">
                  <div
                    class="lg:w-[300px] xl:w-[350px] flex-wrap flex flex-col gap-2 bg-[#17479e] text-white rounded-3xl h-[249px] p-4"
                  >
                    <div class="mx-auto">
                      <img
                        :src="academic.image"
                        :alt="academic.titile"
                        class="w-[50px] h-[50px] object-contain"
                      />
                    </div>
                    <p class="text-[14px] lg:text-[18px] pb-2">
                      {{ academic.titile }}
                    </p>
                    <div
                      class="w-[200px] mx-auto border-b-2 border-white"
                    ></div>
                    <p
                      class="text-[12px] lg:text-[14px] text-center py-2"
                      v-html="academic.description"
                    ></p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="bg-white py-8"
              v-if="selectedTab === 'Learning Methodology'"
            >
              <!-- <p class="text-[14px] lg:text-[16px] text-center">
                Crafting futures, Inspiring success SU's online learning
                programs are unique for several reasons,
                <br class="hidden md:block" />
                including its state-of-the-art and vibrant community
              </p> -->
              <div class="flex justify-between flex-wrap gap-5 py-4">
                <div v-for="learning in learnings" :key="learning" class="">
                  <div
                    class="lg:w-[300px] xl:w-[350px] flex-wrap flex flex-col gap-2 bg-[#17479e] text-white rounded-3xl h-full p-4"
                  >
                    <div class="mx-auto">
                      <img
                        :src="learning.image"
                        :alt="learning.titile"
                        class="w-[50px] h-[50px] object-contain"
                      />
                    </div>
                    <p class="text-[14px] lg:text-[18px] pb-2">
                      {{ learning.titile }}
                    </p>
                    <div
                      class="w-[200px] mx-auto border-b-2 border-white"
                    ></div>
                    <p
                      class="text-[12px] lg:text-[14px] text-center py-2"
                      v-html="learning.description"
                    ></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="lg:hidden py-2 mt-4">
          <div class="flex justify-center gap-[10px]">
            <button
              v-for="el in whyUs"
              :key="el"
              @click="selectedTab = el"
              :class="{
                ' border border-[#0CB1EF] text-blue-400 ': selectedTab === el,
                ' border bg-[#F6F8FB]': selectedTab !== el,
              }"
              class="text-center px-5 py-2 text-[14px] rounded-md 2xl:text-[18px] shadow-2xl text-black font-semibold"
            >
              {{ el }}
            </button>
          </div>
          <!-- mobile cards -->
          <div class="py-4 h-auto mt-5 w-full">
            <div>
              <div
                class="bg-white p-2"
                v-if="selectedTab === 'Academic Approach'"
              >
                <div
                  class="slick_whyus flex justify-center flex-wrap gap-5 py-4"
                >
                  <div v-for="academic in academics" :key="academic" class="">
                    <div
                      class="w-full flex flex-col gap-2 bg-[#17479e] text-white rounded-3xl h-[207px] p-4"
                    >
                      <div class="mx-auto">
                        <img
                          :src="academic.image"
                          :alt="academic.titile"
                          class="w-[50px] h-[50px] object-contain"
                        />
                      </div>
                      <p class="text-[14px] pb-2">
                        {{ academic.titile }}
                      </p>
                      <div
                        class="w-[100px] mx-auto border-b-2 border-white"
                      ></div>
                      <p
                        class="text-[12px] text-center py-2"
                        v-html="academic.description"
                      ></p>
                    </div>
                  </div>
                </div>
                <div class="flex justify-center items-center gap-3 pt-3">
                  <!-- carousel prev button -->
                  <div
                    class="prevWhy cursor-pointer w-10 h-10 rounded-md bg-white flex justify-center items-center"
                  >
                    <img
                      src="@/assets/home/Testimonial/arrow-left.svg"
                      alt=""
                      class="md:w-6 lg:w-8 text-black"
                    />
                  </div>
                  <!--  carosel next button -->
                  <div
                    class="nextWhy cursor-pointer w-10 h-10 flex justify-center items-center rounded-md bg-white"
                  >
                    <img
                      src="@/assets/home/Testimonial/arrow-right.svg"
                      alt=""
                      class="md:w-6 lg:w-8"
                    />
                  </div>
                </div>
              </div>
              <div
                class="bg-white p-2"
                v-if="selectedTab === 'Learning Methodology'"
              >
                <div
                  class="slick_whyus flex justify-center flex-wrap gap-5 py-4"
                >
                  <div v-for="learning in learnings" :key="learning" class="">
                    <div
                      class="w-full flex flex-col gap-2 bg-[#17479e] text-white rounded-3xl h-full p-4"
                    >
                      <div class="mx-auto">
                        <img
                          :src="learning.image"
                          :alt="learning.titile"
                          class="w-[50px] h-[50px] object-contain"
                        />
                      </div>
                      <p class="text-[14px] pb-2">
                        {{ learning.titile }}
                      </p>
                      <div
                        class="w-[100px] mx-auto border-b-2 border-white"
                      ></div>
                      <p
                        class="text-[12px] text-center py-2"
                        v-html="learning.description"
                      ></p>
                    </div>
                  </div>
                </div>
                <div class="flex justify-center items-center gap-3 pt-3">
                  <!-- carousel prev button -->
                  <div
                    class="prevWhy cursor-pointer w-10 h-10 rounded-md bg-white flex justify-center items-center"
                  >
                    <img
                      src="@/assets/home/Testimonial/arrow-left.svg"
                      alt=""
                      class="md:w-6 lg:w-8 text-black"
                    />
                  </div>
                  <!--  carosel next button -->
                  <div
                    class="nextWhy cursor-pointer w-10 h-10 flex justify-center items-center rounded-md bg-white"
                  >
                    <img
                      src="@/assets/home/Testimonial/arrow-right.svg"
                      alt=""
                      class="md:w-6 lg:w-8"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import $ from "jquery";
import "slick-carousel";
export default {
  name: "WhyUsViewe",
  data() {
    return {
      whyUs: ["Academic Approach", "Learning Methodology"],
      selectedTab: "Academic Approach",
      academics: [
        {
          id: 1,
          titile: "Learn Anytime Anywhere",
          description:
            "<p>Round-the-clock access to live and recorded lectures to enrich your knowledge.</p>",
          image: require("@/assets/electivepage/WhyUs/Learn-Anytime-Anywhere.webp"),
        },
        {
          id: 2,
          titile: "Access to Exclusive Resources",
          description:
            "<p>Our vast repository of online scholarly videos will fulfill your academic requirements.</p>",
          image: require("@/assets/electivepage/WhyUs/Access-to-Exclusive-resources.webp"),
        },
        {
          id: 3,
          titile: "Industry-Aligned Programs",
          description:
            "<p>Industry-approved curriculum to enhance relevant competencies for a better future.</p>",
          image: require("@/assets/electivepage/WhyUs/Industry-Aligned-Programs.webp"),
        },
        {
          id: 4,
          titile: "Eminent Educators",
          description:
            "<p>Learn from experts to get the latest Industry Insights leading to Professional Enhancement.</p>",
          image: require("@/assets/electivepage/WhyUs/Eminent-Educators.webp"),
        },
        {
          id: 5,
          titile: "Practical Learning",
          description: "<p>Case Studies, Simulations, and Activities</p>",
          image: require("@/assets/electivepage/WhyUs/Practical-Learning.webp"),
        },
        {
          id: 6,
          titile: "Career Support",
          description:
            "<p>Career Development & Competence Building with Placement Assistance</p>",
          image: require("@/assets/electivepage/WhyUs/Career-Support.webp"),
        },
      ],
      learnings: [
        {
          id: 1,
          titile: "Curriculum Design and Content Delivery",
          description:
            "<ul><li>Modularization</li><li>Multimedia Content</li><li>Learning Management System (LMS)</li></ul>",
          image: require("@/assets/electivepage/WhyUs/Curriculum-Design-and-Content-Delivery.webp"),
        },
        {
          id: 2,
          titile: "Engagement and Interaction",
          description:
            "<ul><li>Discussion Forums</li><li>Live Sessions</li><li>Group Work</li></ul>",
          image: require("@/assets/electivepage/WhyUs/Engagement-and-Interaction.webp"),
        },
        {
          id: 3,
          titile: " Assessment and Feedback",
          description:
            "<ul><li>Formative Assessments</li><li> Summative Assessments</li><li>Peer Review</li></ul>",
          image: require("@/assets/electivepage/WhyUs/Engagement-Feedback.webp"),
        },

        {
          id: 4,
          titile: "Personalization and Support",
          description:
            "<ul><li>Adaptive Learning Tools</li><li>Tutoring and Mentoring</li><li>Accessibility and Inclusivity</li></ul>",
          image: require("@/assets/electivepage/WhyUs/Personalization-and-Support.webp"),
        },
        {
          id: 5,
          titile: "Technology and Infrastructure",
          description:
            "<ul><li>Reliable Tech Tools</li><li>Cybersecurity Measures</li><li>Tech Support</li></ul>",
          image: require("@/assets/electivepage/WhyUs/Technology-and-Infrastructure.webp"),
        },
        {
          id: 6,
          titile: "Evaluation and Continuous Improvement",
          description:
            "<ul><li>Student Feedback</li><li>Analytics</li><li>Professional Development</li></ul>",
          image: require("@/assets/electivepage/WhyUs/Evaluation-and-Continuous-Improvement.webp"),
        },
      ],
    };
  },
  methods: {
    initializeCarousel() {
      $(`.slick_whyus`).slick({
        infinite: true,
        autoplay: true,
        prevArrow: $(".prevWhy"),
        nextArrow: $(".nextWhy"),
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,

        responsive: [
          {
            breakpoint: 950,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 650,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 420,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
      this.carouselInitialized = true;
    },
    destroyCarousel() {
      if (this.carouselInitialized) {
        $(".slick_career").slick("unslick");
        this.carouselInitialized = false;
      }
    },
  },

  watch: {
    selectedTab() {
      this.destroyCarousel();
      this.$nextTick(() => {
        this.initializeCarousel();
      });
    },
  },
  mounted() {
    this.initializeCarousel();
  },
};
</script>

<style></style>
