<template>
  <div class="w-full px-8 bg-[#f7f9fb] py-10">
    <div class="max-w-7xl mx-auto w-full">
      <div class="flex lg:flex-row flex-col text-left">
        <h1
          v-if="this.electiveTitle"
          class="text-[24px] lg:text-[30px] text-[#18479e] font-bold capitalize"
        >
          {{ electiveTitle }}
        </h1>
      </div>
      <div class="py-3">
        <div class="slick_elective py-3 lg:py-5 w-full">
          <div
            v-for="item in filteredElectiveData"
            :key="item"
            :class="{
              'single-slide': filteredElectiveData.length <= 2,
              'multi-slide': filteredElectiveData.length > 2,
            }"
            class="w-full"
          >
            <div
              class="flex flex-col !justify-center gap-5 xl:w-[360px] h-[440px] border-2 rounded-lg"
            >
              <img
                :src="item.image"
                :alt="item.name"
                class="xl:w-[360px] h-[240px] rounded-t-lg"
              />

              <div class="flex flex-col gap-2 p-4 bg-white">
                <p class="text-[16px] h-[60px] font-bold text-left">
                  {{ item.sub_name }} {{ item.name }}
                  <span class="text-primary">{{ item.subtitle }}</span>
                </p>
                <div class="flex gap-4">
                  <div class="flex items-center gap-2">
                    <img
                      src="@/assets/elective/calender.webp"
                      alt=""
                      class="h-[24px] w-[24px]"
                    />
                    <p class="text-[#4D4D4D] text-[15px] text-left">
                      Live Online Classes
                    </p>
                  </div>
                  <div class="flex items-center gap-2">
                    <img
                      src="@/assets/elective/watch.webp"
                      alt=""
                      class="h-[23px] w-[23px]"
                    />
                    <p class="text-[#4D4D4D] text-[15px]">
                      {{ item.durations }} years
                    </p>
                  </div>
                </div>
                <a
                  v-if="item.short_slug"
                  :href="`/${item.short_slug}/${item.slug}`"
                  class="pt-2"
                >
                  <div
                    class="bg-[#0CB1EF] text-white text-[13px] hover:text-[#18469d] py-2 rounded-[50px] w-40 hover:bg-white hover:border-2 hover:border-[#0CB1EF] flex justify-center items-center"
                  >
                    <p class="uppercase font-semibold">GET STARTED</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <!-- End of provided code -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import "slick-carousel";
import axios from "axios";
export default {
  name: "DegreeElective",
  data() {
    return {
      filteredElectiveData: [],
    };
  },
  async created() {
    let path = this.$route.path;
    path = path.substring(1);
    console.log("path :", path);
    await this.allProgramInfo(path);
  },
  computed: {
    electiveTitle() {
      if (this.filteredElectiveData.length > 1) {
        return this.filteredElectiveData[0].program.name + " Program Electives";
      } else if (
        this.filteredElectiveData.length > 0 &&
        this.filteredElectiveData.length < 2
      ) {
        return this.filteredElectiveData[0].program.name + " Program Elective";
      }
      return "";
    },
  },
  beforeMount() {
    const elementToRemoveDes = document.querySelector(".slick_elective");
    if (elementToRemoveDes) {
      elementToRemoveDes.remove();
    }
  },
  methods: {
    async allProgramInfo(path) {
      await axios
        .get(
          `${process.env.VUE_APP_API}/course/elective-list/?program__slug=${path}`
        )
        .then((resp) => {
          this.filteredElectiveData = resp.data.data;
          setTimeout(() => {
            this.initializeSlider();
          }, 100);
          console.log(
            "filteredElectiveData length :",
            this.filteredElectiveData.length
          );
        })
        .catch((error) => {
          console.log(error);
        });
    },
    initializeSlider() {
      $(".slick_elective").slick({
        autoplay: true,
        infinite: false,
        prevArrow: false,
        nextArrow: false,
        slidesToShow:
          this.filteredElectiveData.length < 3
            ? this.filteredElectiveData.length
            : 3,
        slidesToScroll: 1,
        dots: true,
        dotsClass: "slick_elective_filter_dot",
        // variableWidth: this.filteredElectiveData.length > 3,
        responsive: [
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 650,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 420,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
    },
  },
};
</script>

<style>
.single-slide {
  display: flex;
  justify-content: flex-start;
}
.multi-slide {
  display: flex !important;
  justify-content: center !important;
}
.slick_elective_filter_dot {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 1rem 0;
  list-style-type: none;
}
.slick_elective_filter_dot li {
  margin: 0 0.25rem;
}
.slick_elective_filter_dot button {
  display: block;
  width: 0.75rem;
  height: 0.75rem;
  padding: 0;
  border: none;
  border-radius: 15px;
  background-color: silver;
  text-indent: -9999px;
}
.slick_elective_filter_dot li.slick-active button {
  background-color: #f8c300;
  width: 1.5rem;
  height: 0.75rem;
  border-radius: none;
}
</style>
