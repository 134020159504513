<template lang="">
  <div>
    <section>
      <div>
        <div class="w-full mx-auto md:mt-20 pb-6rem">
          <div class="banner object-cover bg-center bg-no-repeat relative">
            <div
              class="next-banner absolute z-10 top-[40%] right-5 cursor-pointer desktop"
            >
              <img
                src="@/assets/icon-slider-next.svg"
                width="40px"
                height="40px"
                alt
              />
            </div>
            <div
              class="prev-banner absolute z-10 top-[40%] left-5 cursor-pointer desktop"
            >
              <img
                src="@/assets/icon-slider-prev.svg"
                width="40px"
                height="40px"
                alt
              />
            </div>
            <div class="slick-banner">
              <!-- <div>
                <img
                  @click="openModal"
                  src="@/assets/home/banners/VO-Website-Desktop-Banner-Mar_25.gif"
                  width="1337px"
                  height="535px"
                  alt="Admissions"
                  title="Admissions"
                  class="desktop cursor-pointer w-[100%] h-[100%]"
                />
                <div class="relative mobile top-16">
                  <img
                    @click="openModal"
                    src="@/assets/home/banners/mobile/VO-Website-Mobile-Banner-Mar_25.gif"
                    alt="Admissions"
                    title="Admissions"
                    class="mobile w-[490px] h-[450px]"
                  />
                  <div
                    class="bg-[#0CB1EF] text-white font-semibold rounded-3xl w-32 py-2 top-[34%] left-[30%] m-auto absolute"
                  >
                    <button @click="openModal" id="an" aria-label="applynow">Apply Now</button>
                  </div>
                </div>
              </div> -->

              <div>
                <div class="hidden md:block ">
                  <a href="https://apply.shardaonline.ac.in/" target="_blank">
                    <img
                      src="https://api.shardaonline.ac.in/media/homebanner_76587.webp"
                      width="1337"
                      height="535"
                      rel="preload"
                      alt="Online Degree Programs"
                      title="Online Degree Programs"
                      class="cursor-pointer w-[100%] h-[100%] md:w-[100%] hidden"
                    />
                  </a>
                </div>
                <div
                  class="relative bottom-[-60px] mobile md:hidden min-h-[400px] pb-10"
                >
                  <a href="https://apply.shardaonline.ac.in/" target="_blank">
                    <img
                      src="@/assets/homebanner/Online-degree.webp"
                      width="490"
                      height="712"
                      rel="preload"
                      alt="Online Degree Programs"
                      title="Online Degree Programs"
                      class="mobile cursor-pointer w-full h-[512px]"
                    />
                  </a>
                  <!--<div
                    class="bg-[#0CB1EF] text-white font-semibold rounded-3xl w-32 py-2 bottom-2 left-5 absolute"
                  >
                    <button @click="openModal" id="an" aria-label="applynow">Apply Now</button>
                  </div>-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Modal -->
    <div class="Bannermodal" v-if="isModalOpen">
      <div class="Bannermodal-content rounded-md">
        <span class="close" @click="closeModal">&times;</span>
        <EnquireView />
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import "slick-carousel";
import EnquireView from "@/components/enqireforms/EnquireForm.vue";
export default {
  name: "BannerView",
  components: {
    EnquireView,
  },

  data() {
    return {
      isModalOpen: false,
    };
  },

  mounted() {
    $(".slick-banner").slick({
      infinite: true,
      autoplay: true,
      speed: 200,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: $(".prev-banner"),
      nextArrow: $(".next-banner"),
      autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
  },

  methods: {
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
  },
};
</script>
<style scoped>
.Bannermodal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.Bannermodal-content {
  position: relative;
  background-color: white;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 30%;
  bottom: 7%;
}

@media (max-width: 767.98px) {
  .Bannermodal-content {
    position: relative;
    background-color: white;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 95%;
    top: 20%;
  }
}

.close {
  position: relative;
  color: #aaa;
  float: right;
  top: -25px;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover {
  color: black;
}
</style>
