import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./index.css";
import "slick-carousel/slick/slick.css";
import "jquery";
import { createHead } from "@unhead/vue";
import VueGtag from "vue-gtag";

/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/* import specific icons */
import {
  faInstagram,
  faYoutube,
  faSquareFacebook,
  faLinkedin,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import {
  faCircleChevronDown,
  faCircleChevronUp,
  faAngleUp,
  faAngleDown,
  faChevronUp,
  faChevronDown,
  faAngleLeft,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";

/* add icons to the library */
library.add(
  faInstagram,
  faYoutube,
  faSquareFacebook,
  faLinkedin,
  faXTwitter,
  faCircleChevronDown,
  faCircleChevronUp,
  faAngleUp,
  faAngleDown,
  faChevronUp,
  faChevronDown,
  faAngleLeft,
  faAngleRight
);
const app = createApp(App);
const head = createHead();
// app.use(VueGtag, {
//   config: { id: "GTM-N5X98VM" },
// });
let globe = [
  "/lp/online-degree-course-india/",
  "/lp/online-degree-course-india",
  "/lp/online-degree-course-india/thankyou/",
  "/lp/online-degree-course-india/thankyou",
];
if (!globe.includes(window.location.pathname)) {
  app.use(VueGtag, {
    config: { id: "GTM-TP9R4T3V" },
  });
}
// config: { id: "AW-11303512183" }, old Gtag
let learningshalaLocation = [
  "/lp/online-degree-course-india/",
  "/lp/online-degree-course-india",
];
if (learningshalaLocation.includes(window.location.pathname)) {
  app.use(VueGtag, {
    includes: [{ id: "AW-16612943417" }],
    config: { id: "AW-16612943417" },
  });
}
// Vue Lazyload Configuration (if needed)
// Vue.use(VueLazyload);
// Vue.use(VueLazyload, {
//   preLoad: 1.3,
//   attempt: 1
// });

app
  .component("font-awesome-icon", FontAwesomeIcon)
  .use(store)
  .use(router)
  .use(head)
  .mount("#app");
