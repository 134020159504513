<template>
  <div>
    <!-- desversion-->
    <div class="hidden lg:block">
      <div class="bg-[#F6F8FB] h-full rounded-b-xl xl:rounded-b-2xl">
        <div class="px-8">
          <div class="max-w-7xl mx-auto lg:mt-[80px]">
            <!-- <div class="pl-10">
            <ul class="breadcrumb">
              <li
                v-for="(item, index) in b_value"
                :key="index"
                :class="{
                  'breadcrumb-item': !isActive(index),
                  'active-breadcrumb-item': isActive(index),
                }"
              >
                <span :class="{ 'breadcrumb-text': !isActive(index) }">
                  <a :href="item.link">{{ item.text }}</a>
                </span>
                <span
                  v-if="index < b_value.length - 1"
                  class="breadcrumb-arrow"
                >
                  >
                </span>
              </li>
            </ul>
          </div> -->

            <div class="flex justify-between gap-x-4 md:pt-10 lg:pt-16">
              <!--carousel-->
              <div class="w-[700px] xl:w-[882px]">
                <div>
                  <h1
                    class="text-[24px] lg:text-[34px] text-start font-bold text-[#18479E]"
                  >
                    Featured Articles
                  </h1>
                </div>
                <div class="slick_blog_des" v-if="this.blogsData">
                  <div v-for="blog in blogsData" :key="blog.id">
                    <div class="flex flex-col gap-2">
                      <div class="w-full h-[320px]">
                        <img
                          :src="blog.image"
                          alt="desImage"
                          class="rounded-md h-full"
                        />
                      </div>
                      <div class="flex gap-7 pt-3">
                        <p class="text-black text-xs text-start">
                          {{ formatDate(blog.publish_at) }}
                        </p>
                        <ul class="text-black text-xs text-start font-bold">
                          <li class="list-disc">{{ blog.category.title }}</li>
                        </ul>
                      </div>
                      <h1
                        class="text-black text-[18px] lg:text-[24px] w-[650px] font-semibold text-start"
                      >
                        {{ blog.title }}
                      </h1>
                      <a :href="`/blogs/${blog.slug}`">
                        <div class="flex gap-2 items-center cursor-pointer">
                          <p class="text-[14px] lg:text-[16px] text-[#0CB1EF]">
                            Read More
                          </p>
                          <img
                            src="@/assets/BlogsLanding/right-up.png"
                            alt=""
                            srcset=""
                            class="w-5 h-5"
                          />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <!--carousel-->
              <!--trending-->
              <div class="w-[290px] xl:w-[320px]">
                <div>
                  <h1
                    class="text-[24px] lg:text-[34px] text-start font-bold text-[#18479E]"
                  >
                    Trending Now
                  </h1>
                </div>
                <div class="flex flex-col gap-8">
                  <div class="" v-for="blog in trendingData" :key="blog.id">
                    <div class="bg-white shadow-2xl rounded-lg">
                      <div class="p-4 text-start h-[150px]">
                        <h1
                          class="w-full lg:text-[13px] xl:text-[14px] font-semibold flex-wrap text-[#1F1F1F]"
                        >
                          {{ blog.title }}
                        </h1>
                        <div class="flex gap-7 pt-3">
                          <p
                            class="text-[#454545] text-xs text-start font-semibold"
                          >
                            {{ formatDate(blog.publish_at) }}
                          </p>
                          <ul
                            class="text-[#454545] text-xs text-start font-bold"
                          >
                            <li class="list-disc">{{ blog.category.title }}</li>
                          </ul>
                        </div>
                        <a :href="`/blogs/${blog.slug}`">
                          <div
                            class="flex gap-2 py-3 items-center cursor-pointer"
                          >
                            <p
                              class="text-[14px] lg:text-[14px] text-[#0CB1EF]"
                            >
                              Read More
                            </p>
                            <img
                              src="@/assets/BlogsLanding/right-up.png"
                              alt=""
                              srcset=""
                              class="w-5 h-5"
                            />
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--trending-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--desversion -->

    <!--mob version-->
    <div class="lg:hidden bg-[#F6F8FB] pt-[120px] md:pt-[50px]">
      <div class="rounded-b-xl flex flex-col gap-7 px-5">
        <!--breadcrumbs-->
        <!-- <div class="pl-8">
          <ul class="breadcrumb">
            <li
              v-for="(item, index) in b_value"
              :key="index"
              :class="{
                'breadcrumb-item': !isActive(index),
                'active-breadcrumb-item': isActive(index),
              }"
            >
              <span :class="{ 'breadcrumb-text': !isActive(index) }">
                <a :href="item.link">{{ item.text }}</a>
              </span>
              <span v-if="index < b_value.length - 1" class="breadcrumb-arrow">
                >
              </span>
            </li>
          </ul>
        </div> -->
        <!--breadcrumbs-->
        <div>
          <h1 class="text-[24px] text-left font-bold text-[#18479E]">
            Featured Articles
          </h1>
          <!--courosal-->
          <div class="slick_blog_mob">
            <div v-for="blog in blogsData" :key="blog.id">
              <div class="flex flex-col gap-3">
                <div class="">
                  <img
                    :src="blog.image"
                    alt="mobimage"
                    class="h-[280px] rounded-2xl w-full"
                  />
                </div>
                <div class="flex gap-7 pt-3">
                  <p class="text-black text-xs text-start">
                    {{ formatDate(blog.publish_at) }}
                  </p>
                  <ul class="text-black text-xs text-start font-bold">
                    <li class="list-disc">{{ blog.category.title }}</li>
                  </ul>
                </div>
                <h1 class="text-black text-[18px] font-semibold text-start">
                  {{ blog.title }}
                </h1>
                <a :href="`/blogs/${blog.slug}`">
                  <div class="flex gap-2 items-center cursor-pointer">
                    <p class="text-[14px] lg:text-[16px] text-[#0CB1EF]">
                      Read More
                    </p>
                    <img
                      src="@/assets/BlogsLanding/right-up.png"
                      alt=""
                      srcset=""
                      class="w-5 h-5"
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>

        <!--courosal-->
        <div class="py-5">
          <h2 class="text-[24px] font-bold text-[#18479E] text-left">
            Trending Now
          </h2>

          <!--newsContent-->
          <div class="flex flex-col gap-y-4 justify-center items-center">
            <div class="w-full" v-for="blog in trendingData" :key="blog.id">
              <div class="bg-white shadow-2xl rounded-lg">
                <div class="p-6 text-start">
                  <h1 class="w-full font-semibold text-[#1F1F1F] text-[16px]">
                    {{ blog.title }}
                  </h1>
                  <div class="flex gap-7 pt-3">
                    <p class="text-[#454545] text-xs text-start">
                      {{ formatDate(blog.publish_at) }}
                    </p>
                    <ul class="text-[#454545] text-xs text-start font-bold">
                      <li class="list-disc">{{ blog.category.title }}</li>
                    </ul>
                  </div>
                  <a :href="`/blogs/${blog.slug}`">
                    <div class="flex gap-2 pt-3 items-center cursor-pointer">
                      <p class="text-[14px] lg:text-[16px] text-[#0CB1EF]">
                        Read More
                      </p>
                      <img
                        src="@/assets/BlogsLanding/right-up.png"
                        alt=""
                        srcset=""
                        class="w-5 h-5"
                      />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--mobversion-->
  </div>
</template>

<script>
import $ from "jquery";
import "slick-carousel";
import axios from "axios";
export default {
  name: "BannerView",
  data() {
    return {
      blogsData: [],
      trendingData: [],
    };
  },
  created() {
    this.blogInfo();
    this.trending();
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);
      const options = { month: "long", day: "2-digit", year: "numeric" };
      return date.toLocaleDateString("en-US", options);
    },
    async blogInfo() {
      await axios
        .get(`${process.env.VUE_APP_API}/blog/blog-list/`)
        .then((resp) => {
          if (resp.data.status == 200) {
            this.blogsData = resp.data.data;
          }
          this.slickDes();
          this.slickMob();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async trending() {
      await axios
        .get(`${process.env.VUE_APP_API}/blog/blog-list/?trending=true`)
        .then((resp) => {
          if (resp.data.status == 200) {
            this.trendingData = resp.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    slickDes() {
      setTimeout(() => {
        $(`.slick_blog_des`).slick({
          autoplay: true,
          prevArrow: false,
          nextArrow: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          dotsClass: "slick_learnings_dot",

          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 950,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 650,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 420,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              },
            },
          ],
        });
      }, 300);
    },
    slickMob() {
      setTimeout(() => {
        $(`.slick_blog_mob`).slick({
          infinite: true,
          autoplay: false,
          prevArrow: false,
          nextArrow: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          dotsClass: "slick_learnings_dot",
        });
      }, 300);
    },
  },
};
</script>

<style>
.slick_learnings_dot {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 1rem 0;
  list-style-type: none;
}
.slick_learnings_dot li {
  margin: 0 0.25rem;
}
.slick_learnings_dot button {
  display: block;
  width: 0.75rem;
  height: 0.75rem;
  padding: 0;
  border: none;
  border-radius: 15px;
  background-color: silver;
  text-indent: -9999px;
}
.slick_learnings_dot li.slick-active button {
  background-color: #f8c300;
  width: 1.5rem;
  height: 0.5rem;
  border-radius: none;
}
</style>
