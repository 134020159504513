<template lang="">
  <div>
    <section class="bg-[#f7f9fb]">
      <!-- FAQ -->
      <div id="faq-content" class="px-4 py-10">
        <div class="max-w-screen-2xl mx-auto">
          <div class="flex flex-wrap justify-center text-center">
            <h2
              class="text-[24px] lg:text-[30px] text-[#18479e] font-bold text-left capitalize"
            >
              FAQs
            </h2>
          </div>
          <div
            class="accordion mt-10 bg-trasparent max-w-6xl mx-auto"
            id="faqs"
          >
            <div
              v-for="(el, index) in this.HomeFaq.slice(this.start, this.end)"
              :key="el"
              class="accordion-item border-t-0 border-x-0 text-left"
            >
              <h3 class="accordion-header mb-0">
                <button
                  @click="faqCollapse"
                  class="accordion-button font-semibold relative flex items-center justify-between w-full py-4 px-5 text-[15px] text-[#18469D] hover:text-[#0a58ca] text-left border-0 rounded-none transition focus:outline-none"
                  type="button"
                  :data-target="'#text-' + index"
                >
                  {{ el.title }}
                  <font-awesome-icon
                    :id="'text-' + index + '-img'"
                    icon="fa-solid fa-circle-chevron-down"
                    alt="down-arrow"
                    class="down-arrow text-[#18469d] text-xl"
                  />
                  <!-- <img
                    src="../assets/home/Untitled.png"
                    alt="down-arrow"
                    class="down-arrow"
                  />  -->
                </button>
              </h3>
              <div
                :id="'text-' + index"
                class="accordion-collapse border-0 hidden text-black"
              >
                <div class="accordion-body pb-4 px-5 flex flex-col gap-3">
                  <!-- <p v-html="el.answer_text"></p> -->
                  <div
                    class="font-montserrat text-[15px]"
                    v-html="el.content"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <!-- <button class="font-medium">Load more >></button> -->
          <button
            v-if="this.HomeFaq.length >= this.end"
            @click="this.end = this.end + 3"
            class="bg-[#0CB1EF] text-white font-semibold px-8 py-3 rounded-[50px] mt-5"
          >
            View More
          </button>
          <button
            v-if="this.HomeFaq.length <= this.end"
            @click="this.end = 3"
            class="bg-[#0CB1EF] text-white font-semibold px-8 py-3 rounded-[50px] mt-5"
          >
            View Less
          </button>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import $ from "jquery";
import axios from "axios";
export default {
  name: "FAQview",

  data() {
    return {
      start: 0,
      end: 3,
      HomeFaq: [],
    };
  },
  created() {
    this.getForumPost();
  },
  methods: {
    faqCollapse(e) {
      var accordionItem = $(e.target).parents(".accordion-item");
      var dataTarget = accordionItem
        .find(".accordion-button")
        .attr("data-target");

      var icon = accordionItem.find(".accordion-button .down-arrow");

      if (icon.hasClass("rotate-180")) {
        icon.removeClass("rotate-180");
        $(dataTarget).hide("slow");
      } else {
        $(".accordion-item .accordion-button .down-arrow").removeClass(
          "rotate-180"
        );
        $(".accordion-item .accordion-collapse").hide("slow");
        icon.addClass("rotate-180");
        $(dataTarget).show("slow");
      }
    },

    getForumPost() {
      // let url = `${process.env.VUE_APP_API}/faq/faq-list/`;
      axios
        .get(`${process.env.VUE_APP_API}/faq/faq-list/?category__id=1`)
        .then((response) => {
          if (response.data) {
            this.HomeFaq = response.data.data;
          }
        });
    },
  },
};
</script>
<style lang=""></style>
