<template>
  <div class="w-full py-3 px-8 lg:py-6">
    <section class="w-full max-w-7xl mx-auto">
      <h1 class="text-[24px] lg:text-[30px] text-[#18479e] font-bold text-left">
        Learning Methodology
      </h1>
      <div>
        <div class="hidden md:block">
          <div class="gap-2 md:flex md:p-[1rem]">
            <div v-for="el in learnings" :key="el">
              <div
                class="h-[180px] w-[170px] flex flex-col gap-3 items-center justify-center p-2 rounded-md"
              >
                <div>
                  <img
                    :src="el.img"
                    :alt="el.alt"
                    loading="lazy"
                    decoding="async"
                    class="w-[50px] h-[50px] object-contain"
                  />
                </div>
                <h2 class="text-xs text" v-html="el.title"></h2>
              </div>
            </div>
          </div>
        </div>

        <div class="md:hidden pt-5">
          <div class="flex justify-center">
            <!-- 1st  -->
            <div class="!w-[295px] flex flex-col justify-between items-center h-[400px]">
              <div class="flex gap-2 justify-center items-center">
                <div
                  class="w-[140px] h-[130px] flex flex-col gap-2 items-center justify-center"
                >
                  <div>
                    <img
                      src="@/assets/electivepage/Learnings/l1.png"
                      alt="learning icon"
                      loading="lazy"
                      decoding="async"
                      class="w-[50px] h-[50px] object-contain"
                    />
                  </div>
                  <h2 class="text-[14px] line-clamp-2">
                    120 study hours in each course
                  </h2>
                </div>

                <div
                  class="w-[140px] h-[130px] flex flex-col gap-2 items-center justify-center"
                >
                  <div>
                    <img
                      src="@/assets/electivepage/Learnings/l2.png"
                      alt="learning icon"
                      loading="lazy"
                      decoding="async"
                      class="w-[50px] h-[50px] object-contain"
                    />
                  </div>
                  <h2 class="text-[14px] line-clamp-2">
                    Interactive audio-video lectures
                  </h2>
                </div>
              </div>
              <div class="flex gap-2 justify-center items-center">
                <div
                  class="w-[140px] h-[130px] flex flex-col gap-2 items-center justify-center"
                >
                  <div>
                    <img
                      src="@/assets/electivepage/Learnings/l3.png"
                      alt="learning icon"
                      loading="lazy"
                      decoding="async"
                      class="w-[50px] h-[50px] object-contain"
                    />
                  </div>
                  <h2 class="text-[14px] line-clamp-2">
                    Pre-recorded video lectures
                  </h2>
                </div>

                <div
                  class="w-[140px] h-[130px] flex flex-col gap-2 items-center justify-center"
                >
                  <div>
                    <img
                      src="@/assets/electivepage/Learnings/l4.png"
                      alt="learning icon"
                      loading="lazy"
                      decoding="async"
                      class="w-[50px] h-[50px] object-contain"
                    />
                  </div>
                  <h2 class="text-[14px] line-clamp-2">Discussion forum</h2>
                </div>
              </div>
              <div class="flex gap-2 justify-center items-center">
                <div
                  class="w-[140px] h-[130px] flex flex-col gap-2 items-center justify-center"
                >
                  <div>
                    <img
                      src="@/assets/electivepage/Learnings/l5.png"
                      alt="learning icon"
                      loading="lazy"
                      decoding="async"
                      class="w-[50px] h-[50px] object-contain"
                    />
                  </div>
                  <h2 class="text-[14px] line-clamp-2">
                    Self e-learning material
                  </h2>
                </div>
                <div
                  class="w-[150px] h-[130px] flex flex-col gap-2 items-center justify-center"
                >
                  <div>
                    <img
                      src="@/assets/electivepage/Learnings/l6.png"
                      alt="learning icon"
                      loading="lazy"
                      decoding="async"
                      class="w-[50px] h-[50px] object-contain"
                    />
                  </div>
                  <h2 class="text-[14px] line-clamp-2">
                    Assignments, quizzes, MCQ, etc. for reinforcement
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import $ from "jquery";
import "slick-carousel";
export default {
  name: "LearningView",
  data() {
    return {
      learnings: [
        {
          id: 1,
          img: require("@/assets/electivepage/Learnings/l1Desk.png"),
          title: "120 study hours<br> in each course",
          alt: "clock-icon",
        },
        {
          id: 2,
          img: require("@/assets/electivepage/Learnings/l2Desk.png"),
          title: "Interactive<br> audio-video lectures",
          alt: "Interactive icon",
        },
        {
          id: 3,
          img: require("@/assets/electivepage/Learnings/l3Desk.png"),
          title: "Pre-recorded<br> video lectures",
          alt: "recorded icon",
        },
        {
          id: 4,
          img: require("@/assets/electivepage/Learnings/l4Desk.png"),
          title: "Discussion<br> forum",
          alt: "discussion icon",
        },
        {
          id: 5,
          img: require("@/assets/electivepage/Learnings/l5Desk.png"),
          title: "Self e-learning<br> material",
          alt: "e=learning icon",
        },
        {
          id: 6,
          img: require("@/assets/electivepage/Learnings/l6Desk.png"),
          title: "Assignments, quizzes,<br> MCQ, etc. for reinforcement",
          alt: "assignment icon",
        },
      ],
    };
  },
  beforeMount() {
    const elementToRemoveDes = document.querySelector(".slick_learnings");
    const elementToRemoveMOb = document.querySelector(
      ".slick_learnings_mobile"
    );

    if (elementToRemoveDes) {
      elementToRemoveDes.remove();
    }
    if (elementToRemoveMOb) {
      elementToRemoveMOb.remove();
    }
  },
  mounted() {
    $(`.slick_learnings_mobile`).slick({
      autoplay: true,
      prevArrow: false,
      nextArrow: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      dotsClass: "slick_learnings_dot",
    });

    $(`.slick_learnings`).slick({
      autoplay: true,
      prevArrow: false,
      nextArrow: false,
      slidesToShow: 6,
      slidesToScroll: 1,
      dots: true,
      dotsClass: "slick_learnings_dot",

      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 6,
          },
        },
        {
          breakpoint: 950,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
          },
        },
        {
          breakpoint: 650,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 420,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
      ],
    });
  },
};
</script>

<style>
.slick_learnings_dot {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 1rem 0;
  list-style-type: none;
}
.slick_learnings_dot li {
  margin: 0 0.25rem;
}
.slick_learnings_dot button {
  display: block;
  width: 0.75rem;
  height: 0.75rem;
  padding: 0;
  border: none;
  border-radius: 15px;
  background-color: silver;
  text-indent: -9999px;
}
.slick_learnings_dot li.slick-active button {
  background-color: #f8c300;
  width: 1.5rem;
  height: 0.5rem;
  border-radius: none;
}
</style>
