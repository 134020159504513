<template>
  <div class="w-full p-4 px-8 mt-8">
    <section class="w-full max-w-7xl md:mx-auto">
      <h2 class="text-[24px] lg:text-[30px] text-[#18479e] font-bold text-left capitalize"> Our Technology</h2>
      <div
        class="text-left flex flex-col gap-5 md:flex-row pt-3 lg:pt-5 min-h-[400px]"
      >
        <div class="w-full lg:w-1/2 flex flex-col gap-5">
          <!-- <p class="text-left text-[18px] font-semibold">
            Get the added advantage of kickstarting your career
          </p> -->
          <div class="flex flex-col gap-5 w-full lg:w-[80%]">
            <div class="flex gap-3">
              <img
                src="@/assets/electivepage/Technology/t1.png"
                alt="Lms image"
                class="md:hidden w-[38px] h-[38px] object-contain"
              />
              <img
                src="@/assets/electivepage/Technology/t1Desk.png"
                alt="Lms image"
                class="hidden lg:block w-[38px] h-[38px] object-contain"
              />
              <p>
                State-of-the-art Learning Management System (LMS) for a near
                face-to-face learning experience
              </p>
            </div>
            <div class="flex gap-3">
              <img
                src="@/assets/electivepage/Technology/t2.png"
                alt="message image"
                class="md:hidden w-[38px] h-[38px] object-contain"
              />
              <img
                src="@/assets/electivepage/Technology/t2Desk.png"
                alt="Lms image"
                class="hidden lg:block w-[38px] h-[38px] object-contain"
              />
              <p>
                Clarify all your queries with ease using our chat and message
                feature
              </p>
            </div>
            <div class="flex gap-3">
              <img
                src="@/assets/electivepage/Technology/t3.png"
                alt="discussion image"
                class="md:hidden w-[38px] h-[38px] object-contain"
              />
              <img
                src="@/assets/electivepage/Technology/t3Desk.png"
                alt="Lms image"
                class="hidden lg:block w-[38px] h-[38px] object-contain"
              />
              <p>
                Join online forums and peer groups to engage in discussions on a
                variety of topics
              </p>
            </div>
            <div class="flex gap-3">
              <img
                src="@/assets/electivepage/Technology/t4.png"
                alt="learning access image"
                class="md:hidden w-[38px] h-[38px] object-contain"
              />
              <img
                src="@/assets/electivepage/Technology/t4Desk.png"
                alt="Lms image"
                class="hidden lg:block w-[38px] h-[38px] object-contain"
              />
              <p>Instant and anytime access to the learning material</p>
            </div>
            <div class="flex gap-3">
              <img
                src="@/assets/electivepage/Technology/t5.png"
                alt="monitoring image"
                class="md:hidden w-[38px] h-[38px] object-contain"
              />
              <img
                src="@/assets/electivepage/Technology/t5Desk.png"
                alt="Lms image"
                class="hidden lg:block w-[38px] h-[38px] object-contain"
              />
              <p>Continuous feedback and analytics to monitor progress</p>
            </div>
          </div>
        </div>
        <div class="w-full lg:w-1/2 flex flex-col gap-5">
          <!--<p class="text-left text-[18px] font-semibold">Know Our LMS</p>-->
          <!--<div class="w-full">
           
            <div class="md:hidden">
              <img
                src="@/assets/electivepage/Technology/lms.png"
                alt="lms image"
                class="w-full h-[180px] object-cover"
              />
            </div>
            <div class="hidden md:block">
              <img
                src="@/assets/electivepage/Technology/lmsDesk.png"
                alt="lms image"
                class="w-full h-[320px] object-cover"
              />
            </div>
            
          </div>-->
          <div class="bg-white p-5">
                <!--<h2 class="font-semibold text-center pb-4">
                  Know Our LMS
                </h2>-->
                <iframe
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen=""
                  src="https://www.sharda.ac.in/attachments/common_files/home-banner-vid3.mp4"
                  title="video player"
                  width="100%"
                  height="300"
                  frameborder="0"
                ></iframe>
              </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "OurTechnology",
};
</script>

<style></style>
