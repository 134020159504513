<template>
  <div>
    <NavBar />
    <div class="px-8">
      <div class="w-full max-w-7xl mx-auto">
        <div class="pt-[90px] md:pt-[40px] lg:pt-[80px]">
          <div class="py-5">
            <div class="flex flex-col gap-3">
              <h1
                class="text-[24px] text-[#18479E] font-bold lg:text-[34px] lg:w-[600px] text-left"
              >
                Refer and Unlock 100+ Vouchers at the Exclusive Rewards
                Marketplace!
              </h1>
              <p class="text-[14px] lg:text-[16px] text-left lg:w-[900px]">
                Refer someone to Sharda Online Degree Programs and receive 4,000
                points [1 point = 1 INR] for each successful referral. Redeem
                them across a range of categories, including mobile recharge,
                OTT subscriptions, movie tickets, fashion, electronics, travel &
                more! The more you refer, the more you earn.
              </p>
            </div>
            <div class="max-w-6xl mx-auto py-10">
              <div class="flex flex-col lg:flex-row gap-6">
                <img
                  src="@/assets/referral/MaskGroup.png"
                  alt=""
                  class="lg:w-[390px] lg:h-[390px] hidden lg:block"
                />
                <div class="text-left flex flex-col gap-3">
                  <h1
                    class="text-[24px] text-[#18479E] lg:text-[34px] font-bold"
                  >
                    Referrer of the month
                  </h1>
                  <h2 class="text-[18px] lg:text-[20px] text-black font-bold">
                    Points Earner - 12000
                  </h2>
                  <img
                    src="@/assets/referral/MaskGroup.png"
                    alt=""
                    class="w-[172px] h-[172px] block lg:hidden"
                  />
                  <h3 class="text-[16px] lg:text-[18px] text-black font-bold">
                    LIJO PJ MBA (HR)
                  </h3>
                  <p class="text-[14px] lg:text-[16px]">
                    Hi, I’m Lijo PJ, currently pursuing my MBA in HR at Sharda
                    Online. They just launched the Sharda Online Marketplace – a
                    game-changer for students like us! This is one of the best
                    places to study and make use of the resources provided. The
                    sessions are very interactive, and it never makes us feel
                    bored. And let me unleash what’s the best part about Sharda
                    Online: I just referred 3 friends and got 12000 points in my
                    pocket. Those points came in clutch – I redeemed 4,000 for
                    some amazing deals on Amazon!
                  </p>
                  <p class="text-[16px] lg:text-[16px]">
                    Now, you have the opportunity to earn these rewards and many
                    more.
                  </p>
                  <button
                    class="h-[50px] w-[173px] text-white bg-[#0CB1EF] rounded-md"
                  >
                    REFER NOW
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <EnrollView />
    <FaqView />
    <FooterView />
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import EnrollView from "@/components/Referral/EnrollView.vue";
import FaqView from "@/components/Referral/FaqView.vue";
import FooterView from "@/components/FooterView.vue";

export default {
  name: "ReferralPage",
  components: {
    NavBar,
    EnrollView,
    FaqView,
    FooterView,
  },
};
</script>

<style></style>
