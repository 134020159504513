<template>
  <div v-if="this.Programs.length > 0">
    <NavBar />
    <!--banner part-->
    <div v-if="this.Programs" class="w-full mx-auto relative">
      <div class="md:px-8">
        <div class="max-w-7xl mx-auto">
          <div
            class="absolute top-24 md:top-1 text-[10px] bg-gray-50 px-2 py-1 md:p-0 md:bg-transparent md:text-xs"
          >
            <p class="text-[#0CB1EF] md:text-black font-bold text-sm">
              <router-link to="/" class="text-[#0CB1EF]">Home</router-link>

              <span class="text-black"> > {{ Programs[0].name }}</span>
            </p>
          </div>
        </div>
      </div>
      <div class="w-full">
        <img
          :src="Programs[0].banner"
          class="hidden md:block w-full mt-24"
          alt="desktop-banner"
        />
        <img
          :src="Programs[0].mobile_banner"
          class="md:hidden pt-20 w-full"
          alt="mobile-banner"
        />
      </div>
      <div class="px-5 md:px-8">
        <div class="max-w-7xl mx-auto">
          <!-- <div class="absolute md:top-2 top-24 left-3">
          <p><a href="/">Home</a> > {{ Programs[0].name }}</p>
        </div> -->
          <div
            class="absolute bannerprog w-[20rem] md:w-[45rem] xl:w-[30rem] bg-opa lg:leading-10 leading-7 text-left font-[700] px-2 top-[30%] md:top-[15%] 2xl:top-[7%]"
          >
            <h1
              class="text-[24px] md:text-2xl lg:text-3xl xl:text-4xl"
              v-html="Programs[0].focus"
            ></h1>
          </div>
          <!-- <div
          class="text-black md:text-left absolute left-3 bottom-2 lg:bottom-[12%] xl:bottom-[12%] md:left-[5%] lg:left-[8%] xl:left-[12%]"
        >
          <div class="flex lg:flex-col flex-row gap-5">
            <button
              class="bg-[#0CB1EF] text-white font-[600] text-[16px] px-3 py-2 w-36 mt-5 rounded-2xl"
              @click="openModal"
            >
              Enroll Now
            </button>
          </div>
        </div> -->

          <div>
            <div
              class="flex flex-col md:flex-row gap-4 absolute top-[48%] md:top-[40%] xl:top-[48%] 2xl:top-[35%] xxl:top-[22%]"
            >
              <div class="flex">
                <span
                  ><img
                    src="@/assets/programInfo/naac.webp"
                    alt="naac"
                    class="w-[40px]"
                /></span>
                <span
                  class="text-sm font-bold bannernaac text-white position-relative top-[10px] md:text-black"
                  >NAAC A+ ACCREDITED</span
                >
              </div>
              <div class="flex">
                <span
                  ><img
                    src="@/assets/programInfo/ugc.webp"
                    alt="ugcweb"
                    class="w-[40px]" /></span
                ><span
                  class="text-sm text-start bannernaac font-bold text-white md:text-black"
                  >UGC ENTITLED</span
                >
              </div>
            </div>
          </div>
          <div>
            <div
              class="absolute text-xl w-full leading-7 text-left font-[700] top-[76%] md:top-[55%] xl:top-[58%] 2xl:top-[45%] xxl:top-[30%] flex flex-col gap-5"
            >
              <div class="flex flex-wrap gap-10">
                <div class="flex gap-2 items-center">
                  <div>
                    <img
                      src="@/assets/elective/Duration.png"
                      alt=""
                      class="w-7 h-7"
                    />
                  </div>
                  <div class="flex flex-col">
                    <h1 class="text-xs text-black">Duration</h1>
                    <p class="text-[#18479E] text-xs">
                      {{ Programs[0].durations }} YEARS
                    </p>
                  </div>
                </div>
                <div class="flex gap-2 items-center">
                  <div>
                    <img
                      src="@/assets/elective/Program-Credits.png"
                      alt=""
                      class="w-7 h-7"
                    />
                  </div>
                  <div class="flex flex-col">
                    <h1 class="text-xs text-black">Program Credits</h1>
                    <p class="text-[#18479E] text-xs">
                      {{ Programs[0].credits }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="flex flex-wrap gap-6">
                <div class="flex gap-2 items-center">
                  <div>
                    <img
                      src="@/assets/elective/Flexible.png"
                      alt=""
                      class="w-7 h-7"
                    />
                  </div>
                  <div class="flex flex-col">
                    <h1 class="text-xs text-black">Flexible</h1>
                    <p class="text-[#18479E] text-xs">EMI OPTION</p>
                  </div>
                </div>
                <div class="flex gap-2 items-center">
                  <div>
                    <img
                      src="@/assets/elective/Live-Online-Sessions.png"
                      alt=""
                      class="w-7 h-7"
                    />
                  </div>
                  <div class="flex flex-col">
                    <h1 class="text-xs text-black">Live Online Sessions</h1>
                    <p class="text-[#18479E] text-xs">WEEKEND</p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div
                class="text-black md:text-left absolute bottom-[3%] md:bottom-[2%] lg:bottom-[1%] xl:bottom-[10%] 2xl:bottom-[30%] xxl:bottom-[50%]"
              >
                <div class="flex lg:flex-col flex-row gap-5">
                  <a href="https://apply.shardaonline.ac.in/" target="_blank">
                    <button
                      class="bg-[#0CB1EF] text-white font-[600] text-[16px] px-3 py-1 w-[150px] md:py-2 md:w-36 mt-5 rounded-2xl"
                    >
                      APPLY NOW
                    </button>
                  </a>
                </div>
              </div>
              <div
                class="text-black md:text-left absolute bottom-[3%] md:bottom-[2%] lg:bottom-[1%] xl:bottom-[10%] 2xl:bottom-[30%] xxl:bottom-[50%] right-[15%] md:left-[25%] lg:left-[20%] xl:left-[18%] 2xl:left-[25%] xxl:left-[32%]"
              >
                <div class="flex flex-row gap-5">
                  <button
                    class="bg-[#0CB1EF] text-white font-[600] text-[16px] px-3 py-1 w-[11rem] md:py-2 mt-5 rounded-2xl"
                    @click="openModalTwo"
                  >
                    Download Brochure
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--banner part completed-->

    <!--About the elective-->
    <div class="w-full px-8 py-3 md:py-6">
      <section class="w-full max-w-7xl mx-auto">
        <div class="text-left flex flex-col gap-2">
          <h1 class="text-[24px] lg:text-[30px] text-[#18479e] font-bold">
            About Online {{ Programs[0].name }}
          </h1>
          <p
            class="text-[14px] lg:text-[14px]"
            v-html="Programs[0].description"
          ></p>
        </div>
      </section>
    </div>
    <!--About the elective end-->

    <!--Program highlights-->
    <div class="w-full px-8 py-5">
      <div class="max-w-7xl mx-auto w-full" v-if="this.Programs">
        <h1
          class="text-[24px] lg:text-[30px] text-[#18479e] font-bold text-left"
        >
          Elective Highlights
        </h1>
        <div
          class="flex flex-col md:flex-row justify-start lg:gap-[80px] w-full lg:ml-5"
        >
          <div
            class="text-[14px] text-left red-dot-list electhigh px-8 md:px-2"
            v-html="Programs[0].Highlights"
          ></div>
        </div>
      </div>
    </div>
    <!--Program highlights end-->

    <!-- electives list -->
    <DegreeElective />
    <!-- electives list end -->

    <!-- Eligibility start -->
    <div
      v-if="this.Programs"
      class="flex flex-col gap-3 w-full px-12 lg:px-10 xl:px-0 py-5 lg:max-w-7xl mx-auto"
    >
      <h1
        class="text-[24px] lg:text-[30px] text-[#18479e] font-bold text-left w-max"
      >
        Eligibility
      </h1>
      <div
        class="flex flex-col gap-5 lg:flex-row w-full lg:max-w-6xl lg:mx-auto lg:justify-around"
      >
        <div class="w-full lg:w-1/2">
          <img src="@/assets/electivepage/eligibility.webp" alt="eligibility" />
        </div>
        <div class="w-full lg:w-1/2 text-left flex justify-center items-center">
          <p class="text-[14px]">
            {{ Programs[0].eligibility }}
          </p>
        </div>
      </div>
    </div>
    <!-- Eligibility end -->
    <LearningView />
    <EnrollView />
    <CareerView />
    <OurTechnology />
    <HiringComp />

    <div class="w-full px-8">
      <!-- FAQ -->
      <div id="faq-content">
        <div class="max-w-screen-2xl mx-auto w-full">
          <div class="flex max-w-7xl mx-auto">
            <h2
              class="text-[24px] lg:text-[30px] text-[#18479e] font-bold text-left capitalize"
            >
              FAQs
            </h2>
          </div>
          <div class="accordion bg-trasparent max-w-6xl mx-auto" id="faqs">
            <div
              v-for="(el, index) in this.ProFaq.slice(this.start, this.end)"
              :key="el"
              class="accordion-item border-t-0 border-x-0 text-left"
            >
              <h2 class="accordion-header mb-0">
                <button
                  @click="faqCollapse"
                  class="accordion-button font-semibold relative flex items-center justify-between w-full py-4 px-5 text-[15px] text-[#18469D] hover:text-[#0a58ca] text-left border-0 rounded-none transition focus:outline-none"
                  type="button"
                  :data-target="'#text-' + index"
                >
                  {{ el.title }}
                  <font-awesome-icon
                    :id="'text-' + index + '-img'"
                    icon="fa-solid fa-circle-chevron-down"
                    alt="down-arrow"
                    class="down-arrow text-[#18469d] text-xl"
                  />
                  <!-- <img
                    src="../assets/home/Untitled.png"
                    alt="down-arrow"
                    class="down-arrow"
                    />  -->
                </button>
              </h2>
              <div
                :id="'text-' + index"
                class="accordion-collapse border-0 hidden text-sm md:text-base text-black"
              >
                <div class="accordion-body pb-4 px-10 flex flex-col gap-3">
                  <!-- <p v-html="el.answer_text"></p> -->
                  <pre
                    style="white-space: pre-line"
                    class="text-[14px]"
                    v-html="el.content"
                  ></pre>
                </div>
              </div>
            </div>
          </div>
          <!-- <button class="font-medium">Load more >></button> -->
          <button
            v-if="this.ProFaq.length > this.end"
            @click="this.end = this.end + 3"
            class="bg-[#0CB1EF] text-white font-semibold px-8 py-2 rounded-[50px] mt-7"
          >
            View More
          </button>
          <button
            v-if="this.ProFaq.length <= this.end"
            @click="this.end = 4"
            class="bg-[#0CB1EF] text-white font-semibold px-8 py-2 rounded-[50px] mt-5"
          >
            View Less
          </button>
        </div>
      </div>
    </div>
    <FooterView />
    <StickyView />
    <!-- Modal -->
    <div class="Bannermodal1" v-if="isModalOpen">
      <div class="Bannermodal-content1 rounded-md">
        <span class="close" @click="closeModal">&times;</span>
        <EnquireView />
      </div>
    </div>
    <!-- Modal -->
    <!--downloadBrouche-->
    <div class="Bannermodal2" v-if="isModalOpenTwo">
      <div
        class="Bannermodal-content2 md:w-[50%] lg:w-[40%] xl:w-[30%] xxl:w-[20%] md:bottom-[-1%] lg:bottom-[4%] xl:bottom-[7%] rounded-md"
      >
        <span class="close" @click="closeModalTwo">&times;</span>
        <DownloadEnquireForm :program="this.Programs" />
      </div>
    </div>
    <!--downloadBrouche-->
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
import NavBar from "@/components/NavBar.vue";
import HiringComp from "@/components/HiringComp.vue";
import CareerView from "@/components/ElectiveDetail/CareerView.vue";
import EnrollView from "../components/ElectiveDetail/EnrollView.vue";
import FooterView from "@/components/FooterView.vue";
import StickyView from "@/components/StickyView.vue";
import OurTechnology from "../components/ElectiveDetail/OurTechnology.vue";
import LearningView from "../components/ElectiveDetail/LearningView.vue";
import EnquireView from "@/components/enqireforms/EnquireForm.vue";
import DownloadEnquireForm from "@/components/enqireforms/DownloadEnquireForm.vue";
import DegreeElective from "../components/ElectiveDetail/DegreeElective.vue";

export default {
  name: "programPage",
  components: {
    NavBar,
    LearningView,
    EnrollView,
    CareerView,
    OurTechnology,
    HiringComp,
    FooterView,
    StickyView,
    EnquireView,
    DownloadEnquireForm,
    DegreeElective,
  },
  data() {
    return {
      start: 0,
      end: 3,
      ProFaq: [],
      Programs: [],
      isModalOpen: false,
      isModalOpenTwo: false,
      imageToggle: false,
    };
  },
  async created() {
    await this.programCall();
  },
  props: ["slug"],
  methods: {
    async programCall() {
      const apiUrl = `${process.env.VUE_APP_API}/course/program-list/?slug=${this.slug}`;
      //const eligibilitySlug = `${process.env.VUE_APP_API}/course/category-list/?slug=masters`;

      try {
        const resp = await axios.get(apiUrl);

        if (resp.data.status === 200) {
          this.Programs = resp.data.data;
          this.programFaq(this.Programs[0].name);
        } else {
          this.$router.push({
            name: "PageNotFound",
            params: { pathMatch: "page-not-found" },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async programFaq(name) {
      await axios
        .get(
          `${process.env.VUE_APP_API}/faq/program-faq-list/?program__name=${name}`
        )
        .then((resp) => {
          if (resp.data.status == 200) {
            this.ProFaq = resp.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    openModal() {
      this.isModalOpen = true;
    },
    openModalTwo() {
      this.isModalOpenTwo = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    closeModalTwo() {
      this.isModalOpenTwo = false;
    },
    faqCollapse(e) {
      var accordionItem = $(e.target).parents(".accordion-item");
      var dataTarget = accordionItem
        .find(".accordion-button")
        .attr("data-target");

      var icon = accordionItem.find(".accordion-button .down-arrow");

      if (icon.hasClass("rotate-180")) {
        icon.removeClass("rotate-180");
        $(dataTarget).hide("slow");
      } else {
        $(".accordion-item .accordion-button .down-arrow").removeClass(
          "rotate-180"
        );
        $(".accordion-item .accordion-collapse").hide("slow");
        icon.addClass("rotate-180");
        $(dataTarget).show("slow");
      }
    },
  },
};
</script>

<style>
.Bannermodal1 {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.Bannermodal2 {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.bannernaac {
  position: relative;
  top: 10px;
  text-indent: 8px;
}

.bg-opa {
  opacity: 0.8;
  /* text-transform: uppercase; */
}

.electhigh ul {
  list-style-image: url("https://api.shardaonline.ac.in/media/Star_57347.webp") !important;
}

.electhigh ul li span {
  position: relative;
  top: -5px;
}

.bannerprog {
  background-color: #18479e;
  color: #ffffff;
}

.Bannermodal-content1 {
  position: relative;
  background-color: white;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 30%;
  bottom: 7%;
}
.Bannermodal-content2 {
  position: relative;
  background-color: white;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
}

@media (max-width: 767.98px) {
  .Bannermodal-content1 {
    position: relative;
    background-color: white;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 95%;
    top: 20%;
  }
  .Bannermodal-content2 {
    position: relative;
    background-color: white;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 95%;
    top: 20%;
  }

  .bannerprog {
    background-color: #18479e;
    color: #ffffff;
    width: 230px;
  }
}

.close {
  position: relative;
  color: #aaa;
  float: right;
  top: -25px;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover {
  color: black;
}
</style>
