<template>
  <section>
    <main class="md:py-18 py-5 px-5 lg:px-0">
      <!-- Most Popular Faq -->
      <div class="pt-4 py-5 lg:py-4">
        <h1
          class="text-[24px] lg:text-[30px] text-[#18479e] font-bold text-center capitalize py-4"
        >
          Frequently Asked Questions
        </h1>
        <div
          class="w-full md:[500px] lg:w-[1000px] mx-auto flex flex-col gap-2 py-[10px] md:py-6"
        >
          <div
            v-for="(item, index) in accordinItem.slice(this.start, this.end)"
            :key="index"
            class="flex flex-col text-left  py-[11px] md:px-[25px] md:py-[20px] gap-2 bg-white rounded-lg shadow-sm"
          >
            <div
              class="flex justify-between items-start cursor-pointer text-[#001c54]"
              @click="toggleAccordin(index)"
            >
              <h3
                class="text-[16px] md:text-md lg:text-[20px] text-[#18479E] Gilroy-SemiBold"
              >
                {{ item.title }}
              </h3>
              <div>
                <span
                  v-if="this.activeAccordin !== index"
                  class="pt-[3px] pl-[4px]"
                >
                  <font-awesome-icon icon="fa-angle-down" class="" />
                </span>
                <span v-else class="pt-[3px] pl-[4px]">
                  <font-awesome-icon icon="fa-angle-up" class="" />
                </span>
              </div>
            </div>

            <p
              class="text-[14px] lg:text-[16px] w-full px-5  text-[#1f1f1f]"
              v-show="activeAccordin === index"
              v-html="item.content"
            ></p>
          </div>
        </div>
        <div
          v-if="this.end < this.accordinItem.length"
          class="w-full md:flex md:justify-center md:items-center py-2"
        >
          <button
            @click="viewMore"
            class="md:w-[320px] w-full flex items-center justify-center  border border-[#0CB1EF] text-[#0CB1EF] rounded-xl md:rounded-lg h-[50px] font-semibold"
          >
           VIEW MORE <img src="@/assets/referral/Icon feather-arrow-right.png" alt="" srcset="" class="w-[16px] h-[16px]">
          </button>
        </div>
        <div v-else>
          <button
            v-if="this.end > 3"
            @click="viewLess"
            class="md:w-[320px] w-full  border border-[#0CB1EF] text-[#0CB1EF] rounded-xl md:rounded-lg h-[50px] font-semibold"
          >
           VIEW LESS
          </button>
        </div>
      </div>
    </main>
  </section>
</template>

<script>
export default {
  name: "FaqView",
  data() {
    return {
      faqToShow: 6,
      accordinItem: [
        {
          id: 1,
          title: "1. Is Sharda Online accredited?",
          content:
            "Yes. The University is NAAC A+ Accredited, and the online degree programs are entitled by University Grants Commission (UGC). Additionally, MBA and MCA programs are approved by the All India Council for Technical Education (AICTE) ",
        },
        {
          id: 2,
          title:
            "2. Is the online degree acquired equivalent to the on-campus learning mode?",
          content:
            "Pursuing an online degree from Sharda University Noida, is regarded as one of the best choices an aspiring learner can make. These are some of the reasons why: <br> 1. Flexible learning schedules make it easy to learn while you earn <br>2. Affordable fee when compared to a regular program <br>3. Our Online degree is equivalent to an on-campus one",
        },
        {
          id: 3,
          title:
            "3. How does Sharda Online help learners by providing online education?",
          content:
            "Sharda Online Degree is a 100% online learning mode program.",
        },
        {
          id: 4,
          title: "4.Will the recording of live sessions be available?",
          content:
            "Yes, all the Sharda Online courses/sessions will be recorded and available on the LMS.",
        },
        
      ],
      activeAccordin: null,
      error: null,
      start: 0,
      end: 3,
    };
  },
  methods: {
    toggleAccordin(index) {
      this.activeAccordin = this.activeAccordin === index ? null : index;
    },
    viewMore() {
      this.end += 2;
    },
    viewLess() {
      this.end = 3;
    },
  },
  computed: {
    showMore() {
      return this.faqToShow < this.accordinItem.length;
    },
    showLess() {
      return this.faqToShow > 4 && !this.showMore;
    },
  },
};
</script>

<style></style>
