<template>
  <div class="w-full px-8 py-3 md:py-6">
    <div class="max-w-7xl mx-auto w-full p-2 relative">
      <!-- <div
          class="prev-rank absolute top-[35%] -left-4 xl:-left-4 cursor-pointer"
        >
          <img src="../assets/icon-slider-prev.svg"  width="40px" height="40px" alt="slider-previous" />
        </div> -->
      <div :class="hiring_compos">
        <div
          v-for="company in companies"
          :key="company.id"
          class="flex justify-center items-center"
        >
          <div class="flex justify-center items-center  hidden md:block">
            <img
              :src="company.imgDes"
              alt="company logo"
              class="md:w-[190px] md:h-[100px] rounded-xl mx-auto"
            />
            
          </div>
          <div class="flex justify-center items-center  md:hidden block">
            <img
              :src="company.imgMob"
              alt="company logo"
              class="w-[150px] h-[70px] rounded-xl"
            />
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  name: "HiringComp",
  data() {
    return {
      hiring_compos: "hiring_components",
      companies: [
        {
          id: 1,
          imgDes: require("@/assets/home/hiring/Adani-wilmar-desktop.webp"),
          imgMob: require("@/assets/home/hiring/Adani-wilmar-mobile.webp"),
        },
        {
          id: 2,
          imgDes: require("@/assets/home/hiring/Amar-wilmar-desktop.webp"),
          imgMob: require("@/assets/home/hiring/Amar-Ujala-mobile.webp"),
        },
        {
          id: 3,
          imgDes: require("@/assets/home/hiring/amazon-desktop.webp"),
          imgMob: require("@/assets/home/hiring/Amazon-mobile.webp"),
        },
        {
          id: 4,
          imgDes: require("@/assets/home/hiring/cmc-wilmar-desktop.webp"),
          imgMob: require("@/assets/home/hiring/CMC-Limited-mobile.webp"),
        },
        {
          id: 5,
          imgDes: require("@/assets/home/hiring/distv-destop.webp"),
          imgMob: require("@/assets/home/hiring/DishTV-mobile.webp"),
        },
        {
          id: 6,
          imgDes: require("@/assets/home/hiring/future-desktop.webp"),
          imgMob: require("@/assets/home/hiring/Future-Group-mobile.webp"),
        },
        {
          id: 7,
          imgDes: require("@/assets/home/hiring/genpact-desktop.webp"),
          imgMob: require("@/assets/home/hiring/Genpact-mobile.webp"),
        },
        {
          id: 8,
          imgDes: require("@/assets/home/hiring/HCL-desktop.webp"),
          imgMob: require("@/assets/home/hiring/HCL-mobile.webp"),
        },
        {
          id: 9,
          imgDes: require("@/assets/home/hiring/ICICI-desktop.webp"),
          imgMob: require("@/assets/home/hiring/ICICI-mobile.webp"),
        },
        {
          id: 10,
          imgDes: require("@/assets/home/hiring/NIIT-desktop.webp"),
          imgMob: require("@/assets/home/hiring/NIIT-mobile.webp"),
        },
        {
          id: 11,
          imgDes: require("@/assets/home/hiring/pepsi-desktop.webp"),
          imgMob: require("@/assets/home/hiring/Pepsi-mobile.webp"),
        },
        {
          id: 12,
          imgDes: require("@/assets/home/hiring/sleepwell-desktop.webp"),
          imgMob: require("@/assets/home/hiring/Sleepwell-mobile.webp"),
        },
        {
          id: 13,
          imgDes: require("@/assets/home/hiring/syntel-desktop.webp"),
          imgMob: require("@/assets/home/hiring/Syntel-mobile.webp"),
        },
        {
          id: 14,
          imgDes: require("@/assets/home/hiring/tech-mahindra-desktop.webp"),
          imgMob: require("@/assets/home/hiring/Tech-Mahindra-mobile.webp"),
        },
        {
          id: 15,
          imgDes: require("@/assets/home/hiring/voda-desktop.webp"),
          imgMob: require("@/assets/home/hiring/Vodafone-mobile.webp"),
        },
        {
          id: 16,
          imgDes: require("@/assets/home/hiring/zeetv-desktop.webp"),
          imgMob: require("@/assets/home/hiring/Zee-TV-mobile.webp"),
        },
      ],
    };
  },

  async mounted() {
    await this.hiringCompo();
  },

  methods: {
    hiringCompo() {
      $(`.${this.hiring_compos}`).slick({
        dots: false,
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        // nextArrow: $(".next-rank"),
        // prevArrow: $(".prev-rank"),
        nextArrow: false,
        prevArrow: false,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 640,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
        ],
      });
    },
  },
};
</script>

<style>
.slick-slider .slick-track {
  display: flex;
  gap: 12px;
}
</style>
