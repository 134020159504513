<template>
  <div class="w-full bg-[#F6F8FB] h-full">
    <NavBar />
    <RefundScope />
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import RefundScope from '@/components/Refund/RefundScope.vue'
export default {
  name: 'RefundView',
  components: {
    NavBar,
    RefundScope,
  },
}
</script>

<style></style>
