<template>
  <div>
    <div
      class="w-full bg-gradient-to-b from-[#174397] to-[#020f26] min-h-[350px] py-3 md:py-6"
    >
      <div class="max-w-7xl mx-auto w-full px-5 py-5">
        <div
          class="flex flex-col gap-2 md:gap-3 text-center justify-center text-white"
        >
          <h1
            class="lg:text-[22px] text-xl font-bold capitalize text-left md:text-center"
          >
          How To Enroll?
          </h1>
        </div>
        <div
          class="flex flex-col md:flex-row md:justify-center md:items-center w-full gap-5 md:h-[250px] pt-5 md:pt-0"
        >
          <div class="w-full md:w-[240px] md:mx-auto flex items-center md:flex-col">
            <div
              class="w-[72px] h-[72px] md:w-[120px] md:h-[120px] rounded-full mx-auto flex flex-col justify-center items-center py-3 bg-white"
            >
              <p class="uppercase">step</p>
              <h2 class="text-[#0CB1EF] md:text-5xl text-2xl font-bold">1</h2>
            </div>
            <div class="py-3 w-[190px] md:w-full text-white text-center">
              <h2 class="text-[14px] text-left md:text-center">
                <button @click="openModal">Click here</button> to visit the Sharda Online enrollment form
              </h2>
            </div>
          </div>
          <div class="md:mx-auto w-full md:w-[240px] flex items-center md:flex-col">
            <div
              class="w-[72px] h-[72px] md:w-[120px] md:h-[120px] rounded-full mx-auto flex flex-col justify-center items-center py-3 bg-white"
            >
              <p class="uppercase">step</p>
              <h2 class="text-[#0CB1EF] md:text-5xl text-2xl font-bold">2</h2>
            </div>
            <div class="py-3 w-[190px] md:w-full text-white text-center">
              <h2 class="text-[14px] text-left md:text-center">
                Sign up and complete the four essential steps to enroll in the program of your choice
              </h2>
            </div>
          </div>
          <div class="md:mx-auto w-full md:w-[240px] flex items-center md:flex-col">
            <div
              class="w-[72px] h-[72px] md:w-[120px] md:h-[120px] rounded-full mx-auto flex flex-col justify-center items-center py-3 bg-white"
            >
              <p class="uppercase">step</p>
              <h2 class="text-[#0CB1EF] md:text-5xl text-2xl font-bold">3</h2>
            </div>
            <div class="py-3 w-[180px] md:w-full text-white text-center">
              <h2 class="text-[14px] text-left md:text-center">
                Pay the fee through Net Banking/Debit card/Credit card or using easy EMI with 0% interest option
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal -->
  <div class="Bannermodal1" v-if="isModalOpen">
      <div class="Bannermodal-content1 rounded-md">
        <span class="close" @click="closeModal">&times;</span>
        <EnquireView />
      </div>
    </div>
</template>

<script>
import EnquireView from "@/components/enqireforms/EnquireForm.vue";
export default {
  name: "EnrollView",
  components: {
    EnquireView,
  },
  data() {
    return {
      isModalOpen: false,
    };
  },

  methods: {

    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
  },

};
</script>

<style></style>
