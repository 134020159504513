<template>
  <div>
    <NavBar />
    <BannerView />
    <CategoriesView />
    <FooterView />
    <StickyView />
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import BannerView from "../components/BlogsLanding/BannerView.vue";
import CategoriesView from "../components/BlogsLanding/CategoriesView.vue";
import FooterView from "@/components/FooterView.vue";
import StickyView from "@/components/StickyView.vue";

export default {
  name: "BlogsView",
  components: {
    NavBar,
    BannerView,
    CategoriesView,
    FooterView,
    StickyView,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style></style>
